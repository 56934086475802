import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/MenuContext";

const WeOffersSectionII = () => {
    const [, , , , mainMenu, setMainMenu] = useContext(Context);
    return (
        <>
            <section className="ftco-section bg-light ftco-faqs">
                <div className="container">
                <div className="row">
                <div className="col-lg-6">
                    <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-sm-0 air-delivery">
                    </div>
                    <div className="d-flex mt-3">
                    <div className="img img-2 mr-md-2 w-100 warehouse"></div>
                    <div className="img img-2 ml-md-2 w-100 inspect"></div>
                </div>
                </div>
                <div className="col-lg-6">
                <div id="accordion" className="myaccordion w-100 pt-5" aria-multiselectable="true">
                    <Link to="/aboutUs" onClick={() => setMainMenu('aboutUs')}>
                        <div className="card">
                            <div className="card-header p-0" id="headingOne">
                            <h2 className="mb-0 w-100">
                                <button href="#StrategicDigital_1" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link bg-warning" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne">
                                <p className="mb-0">Air delivery – Express air delivery</p>
                                <i className="fa" aria-hidden="true"></i>
                                </button>
                            </h2>
                            </div>
                        </div>
                    </Link>
                    <Link to="/aboutUs" onClick={() => setMainMenu('aboutUs')}>
                        <div className="card">
                            <div className="card-header p-0" id="headingTwo_2" role="tab">
                                <h2 className="mb-0 w-100">
                                <button href="#StrategicDigital_2" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link bg-warning" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseTwo">
                                    <p className="mb-0">Own warehouse</p>
                                    <i className="fa" aria-hidden="true"></i>
                                </button>
                                </h2>
                            </div>
                        </div>
                    </Link>
                    <Link to="/aboutUs" onClick={() => setMainMenu('aboutUs')}>
                        <div className="card">
                            <div className="card-header p-0" id="headingThree_2" role="tab">
                                <h2 className="mb-0 w-100">
                                <button href="#StrategicDigital_3" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link bg-warning" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseThree">
                                    <p className="mb-0">Consolidation</p>
                                    <i className="fa" aria-hidden="true"></i>
                                </button>
                                </h2>
                            </div>
                        </div>
                    </Link>
                    <Link to="/aboutUs" onClick={() => setMainMenu('aboutUs')}>
                        <div className="card">
                            <div className="card-header p-0" id="headingFour_2" role="tab">
                                <h2 className="mb-0 w-100">
                                <button href="#StrategicDigital_4" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link bg-warning" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseFour">
                                    <p className="mb-0">Cargo inspection</p>
                                    <i className="fa" aria-hidden="true"></i>
                                </button>
                                </h2>
                            </div>
                        </div>
                    </Link>
                    <Link to="/aboutUs" onClick={() => setMainMenu('aboutUs')}>
                        <div className="card">
                            <div className="card-header p-0" id="headingFour_2" role="tab">
                                <h2 className="mb-0 w-100">
                                <button href="#StrategicDigital_4" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link bg-warning" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseFour">
                                <p className="mb-0">Photo report</p>
                                <i className="fa" aria-hidden="true"></i>
                                </button>
                            </h2>
                            </div>
                        </div>
                    </Link>
                </div>
                </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default WeOffersSectionII;