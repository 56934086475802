import React, { useRef } from "react";
import Header from "../components/Home/Header";
import Menu from "../components/Home/Menu";
import WelcomeCard from "../components/Home/WelcomeCard";
import TrackCard from "../components/Home/TrackCard";
import Facilities from "../components/Home/Facilities";
import WeOffersSection from "../components/Home/WeOffersSection";
import WeOffersSectionII from "../components/Home/WeOffersSectionII";
import Footer from "../components/Home/Footer";
import GoToTopButton from "../components/Home/GoToTopButton";


const Home = () => {

    const servicesRef = useRef(null);

    const handleScrollToSection = () => {
        servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
       <Header />
        {/* <button onclick="topFunction()" id="myBtn" title="Go to top">
        <i id="topArrow" class="fa fa-angle-up"></i>
        </button> */}
        {/* <GoToTopButton /> */}
        <Menu scrollToSection={handleScrollToSection} />
        <WelcomeCard />
        <TrackCard />

        <Facilities ref={servicesRef} />
        <WeOffersSection />
        <WeOffersSectionII />
        <Footer />
        </>
    );
}

export default Home;