import React, { useContext } from "react";
import Input from "../Common/Input";

import { Context } from "../../context/RateContext";

const AddressPage = (props) =>{
    const { 
        title, 
        addressType, 
        bookingDetail, 
        address, 
        handleChange,
        validation } = props;

    const [measurement, setMeasurement] =  useContext(Context);
    return(
        <section className="lh-0">
            <h4>{title}</h4>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Firm Name</div>
                <div className="col-md-8">
                    <Input 
                        name="firmName"
                        required
                        type="text" 
                        placeholder="Enter Firm Name"
                        // value={bookingDetail[addressType+"FirmName"]}
                        value={address?.firmName}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Address</div>
                <div className="col-md-8">
                        <Input 
                            name="address"
                            required
                            type="text" 
                            placeholder="Enter Address"
                            // value={bookingDetail[addressType+"Address"]}
                            onChange={handleChange}
                            value={address?.address}
                        />
                    </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Address Line</div>
                <div className="col-md-8">
                        <Input 
                            name="addressLine"
                            required
                            type="text" 
                            placeholder="Enter Address Line"
                            // value={bookingDetail[addressType+"AddressLine"]}
                            value={address?.addressLine}
                            onChange={handleChange}
                        />
                    </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">City</div>
                <div className="col-md-8">
                        <Input 
                            name="city"
                            required
                            type="text" 
                            placeholder="Enter City"
                            // value={bookingDetail[addressType+"City"]}
                            value={address?.city}
                            onChange={handleChange}
                        />
                    </div>
            </div>
            <div className="row p-10px mb-5">
                <div className="col-md-4 mt-4">PinCode</div>
                <div className="col-md-8">
                        <Input 
                            name="pinCode"
                            required
                            type="text" 
                            placeholder="Enter PinCode"
                            // value={bookingDetail[addressType+"PinCode"]}
                            value={address?.pinCode}
                            onChange={handleChange}
                        />
                    </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Country</div>
                <div className="col-md-8">
                       <span className="form-control">
                        {/* {bookingDetail[addressType+"Country"]} */}
                            {address?.country}
                        </span> 
                </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Contact</div>
                <div className="col-md-8">
                        <Input 
                            name="contactNo"
                            required
                            type="text" 
                            placeholder="Enter Contact No."
                            // value={bookingDetail[addressType+"ContactNo"]}
                            value={address?.contactNo}
                            onChange={handleChange}
                            errorText={validation?.contactNo}
                            // onBlur={handleValidation}
                        />
                    </div>
            </div>
            <div className="row p-10px mb-31">
                <div className="col-md-4 mt-4">Email Id</div>
                <div className="col-md-8">
                        <Input 
                            name="emailId"
                            required
                            type="text" 
                            placeholder="Enter EmailId"
                            // value={bookingDetail[addressType+"EmailId"]}
                            value={address?.emailId}
                            onChange={handleChange}
                            errorText={validation?.emailId}
                            // onBlur={handleValidation}
                        />
                    </div>
            </div>
        </section>
    )
}
export default AddressPage;