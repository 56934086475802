export const dateFormatter = (date) => {
    const yyyy = new Date(date).getFullYear();
    let mm = new Date(date).getMonth() + 1; // Months start at 0!
    let dd = new Date(date).getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${dd}-${mm}-${yyyy}`;
  };

export const convertNumberToWords = (amount) => {
  var words = new Array();
  words[0] = '';
  words[1] = 'One';
  words[2] = 'Two';
  words[3] = 'Three';
  words[4] = 'Four';
  words[5] = 'Five';
  words[6] = 'Six';
  words[7] = 'Seven';
  words[8] = 'Eight';
  words[9] = 'Nine';
  words[10] = 'Ten';
  words[11] = 'Eleven';
  words[12] = 'Twelve';
  words[13] = 'Thirteen';
  words[14] = 'Fourteen';
  words[15] = 'Fifteen';
  words[16] = 'Sixteen';
  words[17] = 'Seventeen';
  words[18] = 'Eighteen';
  words[19] = 'Nineteen';
  words[20] = 'Twenty';
  words[30] = 'Thirty';
  words[40] = 'Forty';
  words[50] = 'Fifty';
  words[60] = 'Sixty';
  words[70] = 'Seventy';
  words[80] = 'Eighty';
  words[90] = 'Ninety';
  amount = amount.toString();
  var atemp = amount.split(".");
  var number = atemp[0].split(",").join("");
  var n_length = number.length;
  var words_string = "";
  if (n_length <= 9) {
      // eslint-disable-next-line no-array-constructor
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
          received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
          n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
          if (i === 0 || i === 2 || i === 4 || i === 7) {
              if (n_array[i] === 1) {
                  n_array[j] = 10 + parseInt(n_array[j]);
                  n_array[i] = 0;
              }
          }
      }
      let value = "";
      for (var i = 0; i < 9; i++) {
          if (i === 0 || i === 2 || i === 4 || i === 7) {
              value = n_array[i] * 10;
          } else {
              value = n_array[i];
          }
          if (value !== 0) {
              words_string += words[value] + " ";
          }
          if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && n_array[i + 1] === 0)) {
              words_string += "Crores ";
          }
          if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && n_array[i + 1] === 0)) {
              words_string += "Lakhs ";
          }
          if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && n_array[i + 1] === 0)) {
              words_string += "Thousand ";
          }
          if (i === 6 && value !== 0 && (n_array[i + 1] !== 0 && n_array[i + 2] !== 0)) {
              words_string += "Hundred and ";
          } else if (i === 6 && value !== 0) {
              words_string += "Hundred ";
          }
      }
      words_string = words_string.split("  ").join(" ");
  }
  return words_string;
};  

export const Rs = (amount) => {
    var words = new Array();
    words[0] = 'Zero';words[1] = 'One';words[2] = 'Two';words[3] = 'Three';words[4] = 'Four';words[5] = 'Five';words[6] = 'Six';words[7] = 'Seven';words[8] = 'Eight';words[9] = 'Nine';words[10] = 'Ten';words[11] = 'Eleven';words[12] = 'Twelve';words[13] = 'Thirteen';words[14] = 'Fourteen';words[15] = 'Fifteen';words[16] = 'Sixteen';words[17] = 'Seventeen';words[18] = 'Eighteen';words[19] = 'Nineteen';words[20] = 'Twenty';words[30] = 'Thirty';words[40] = 'Forty';words[50] = 'Fifty';words[60] = 'Sixty';words[70] = 'Seventy';words[80] = 'Eighty';words[90] = 'Ninety';var op;
    amount = amount.toString();
    var atemp = amount.split('.');
    var number = atemp[0].split(',').join('');
    var n_length = number.length;
    var words_string = '';
    if(n_length <= 9){
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++){
    received_n_array[i] = number.substr(i, 1);}
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++){
    n_array[i] = received_n_array[j];}
    for (var i = 0, j = 1; i < 9; i++, j++){
    if(i == 0 || i == 2 || i == 4 || i == 7){
    if(n_array[i] == 1){
    n_array[j] = 10 + parseInt(n_array[j]);
    n_array[i] = 0;}}}
    var value = '';
    for (var i = 0; i < 9; i++){
    if(i == 0 || i == 2 || i == 4 || i == 7){
    value = n_array[i] * 10;} else {
    value = n_array[i];}
    if(value != 0){
    words_string += words[value] + ' ';}
    if((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Crores ';}
    if((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Lakhs ';}
    if((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Thousand ';}
    if(i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)){
    words_string += 'Hundred and ';} else if(i == 6 && value != 0){
    words_string += 'Hundred ';}}
    words_string = words_string.split(' ').join(' ');}
    return words_string;
};

export const ConvertToWords = (amount) => {
    var words = new Array();
    words[0] = 'Zero';words[1] = 'One';words[2] = 'Two';words[3] = 'Three';words[4] = 'Four';words[5] = 'Five';words[6] = 'Six';words[7] = 'Seven';words[8] = 'Eight';words[9] = 'Nine';words[10] = 'Ten';words[11] = 'Eleven';words[12] = 'Twelve';words[13] = 'Thirteen';words[14] = 'Fourteen';words[15] = 'Fifteen';words[16] = 'Sixteen';words[17] = 'Seventeen';words[18] = 'Eighteen';words[19] = 'Nineteen';words[20] = 'Twenty';words[30] = 'Thirty';words[40] = 'Forty';words[50] = 'Fifty';words[60] = 'Sixty';words[70] = 'Seventy';words[80] = 'Eighty';words[90] = 'Ninety';var op;
    amount = amount.toString();
    var atemp = amount.split('.');
    var number = atemp[0].split(',').join('');
    var n_length = number.length;
    var words_string = '';
    if(n_length <= 9){
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++){
    received_n_array[i] = number.substr(i, 1);}
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++){
    n_array[i] = received_n_array[j];}
    for (var i = 0, j = 1; i < 9; i++, j++){
    if(i == 0 || i == 2 || i == 4 || i == 7){
    if(n_array[i] == 1){
    n_array[j] = 10 + parseInt(n_array[j]);
    n_array[i] = 0;}}}
    var value = '';
    for (var i = 0; i < 9; i++){
    if(i == 0 || i == 2 || i == 4 || i == 7){
    value = n_array[i] * 10;} else {
    value = n_array[i];}
    if(value != 0){
    words_string += words[value] + ' ';}
    if((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Crores ';}
    if((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Lakhs ';}
    if((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)){
    words_string += 'Thousand ';}
    if(i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)){
    words_string += 'Hundred and ';} else if(i == 6 && value != 0){
    words_string += 'Hundred ';}}
    words_string = words_string.split(' ').join(' ');}
    return words_string;
};

function convert(n, curr, subCurr){
    var op = 'only';
    var nums = n.toString().split('.')
    var whole = Rs(nums[0])
    if(nums[1]==null)nums[1]=0;
    if(nums[1].length == 1 )nums[1]=nums[1]+'0';
    if(nums[1].length> 2){nums[1]=nums[1].substring(2,nums[1].length - 1)}
    if(nums[0]<=9){nums[0]=nums[0]*10} else {nums[0]=nums[0]};
    var fraction = Rs(nums[1])
    if(whole=='' && fraction==''){op= 'Zero';}
    if(whole=='' && fraction!=''){op= subCurr + ' ' + fraction ;}
    if(whole!='' && fraction==''){op= whole + curr ;} 
    if(whole!='' && fraction!=''){op= whole + curr+ ' and ' + fraction + subCurr;}
    if(n > 999999999.99){op='Oops!!! The amount is too big to convert';}
    if(isNaN(n) == true ){op='Error : Amount in number appears to be incorrect. Please Check.';}
    return op;    
};

export const RsPaise = (n) => {
    return convert(n,'Rupees','Paise')
}

export const USDCents = (n) => {
   return convert(n,'Dollors','Cents')
}

export const EUROCents = (n) => {
    return convert(n,'Euros','Cents')
 }

export const FYFormat = (month) => {
    const currYear = month === undefined ?  new Date().getFullYear() : month.getFullYear();
    const currMonth = month === undefined ? new Date().getMonth() + 1 : month.getMonth() + 1;
    const today = new Date().getDay();
    const startYear = currMonth >= 4 ? currYear : currYear - 1;
    const endYear = currMonth >= 4 ? (currYear + 1).toString().substring(2,4) : currYear.toString().substring(2,4);
    const fy = `${startYear.toString()}-${endYear}`;
    return fy;
} 

const convertToIndianNumberSystem = (num = 0) => {
    // Step 1: Convert the number to a string and extract the decimal part (if present)
    const inputStr = num.toString();
    const [numStr, decimal] = inputStr.split(".");
  
    // Step 2: Handle the decimal part, keeping only two decimal places
    const formattedDecimal = decimal ? `.${decimal.substring(0, 2)}` : "";
  
    // Step 3: Define regular expressions for matching number patterns in the Indian digit system
    const croreRegex = /^(\d+)(\d{2})(\d{2})(\d{3})$/;
    const lakhRegex = /^(\d{1,2})(\d{2})(\d{3})$/;
    const thousandRegex = /^(\d{1,2})(\d{3})$/;
  
    let match;
  
    // Step 4: Try matching the number with the crore pattern first
    if (croreRegex.test(numStr)) {
      match = numStr.match(croreRegex);
      match.shift(); // Remove the first element (entire matched string)
      return `${match.join(",")}${formattedDecimal} Crores`;
    }
  
    // Step 5: If not matched with the crore pattern, try matching with the lakh pattern
    if (lakhRegex.test(numStr)) {
      match = numStr.match(lakhRegex);
      match.shift(); // Remove the first element (entire matched string)
      return `${match.join(",")}${formattedDecimal} Lakhs`;
    }
  
    // Step 6: If not matched with the lakh pattern, try matching with the thousand pattern
    if (thousandRegex.test(numStr)) {
      match = numStr.match(thousandRegex);
      match.shift(); // Remove the first element (entire matched string)
      return `${match.join(",")}${formattedDecimal} Thousands`;
    }
  
    // Step 7: If no pattern matches, return the original number with decimal (if present)
    return `${numStr}${formattedDecimal}`;
  };