import React from "react";

const ConfirmationPopup =({setConfirmModalPopup, invoiceNumber, handleRemove})=>{
  
    return (
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer update-tds">
                <div className="titleCloseBtn">
                    <button onClick={() => {
                        setConfirmModalPopup(false);
                    }}>
                        X
                    </button>
                </div>
                <div className="invoice-box">
                    <div>Are you sure you want to delete this Item <strong>({invoiceNumber}) ?</strong> </div>
                </div>
                <div className="footer h-50">
                    <button onClick={() => {
                        setConfirmModalPopup(false);
                    }}>
                        No
                    </button>
                    <button type="submit" onClick={handleRemove}>Yes</button>
                </div>
            </div>
        </div>
    )
}
export default ConfirmationPopup;