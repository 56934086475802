import React, { useState, useEffect } from "react";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RadioButtons from "../../Common/RadioButtons";
import ApiTypeAhead from '../../Common/ApiTypeAhead';
import Input from "../../Common/Input";
import Loader from '../../Common/Loader';
import AddressModal from "../AddressModal";
import PaymentInvoiceItem from '../Item/PaymentInvoiceItem';
import FreightInvModalPopup from "./FreightInvModalPopup";

const FreightInvoiceForm = () =>{
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    const [freightInvoice, setFreightInvoice] = useState('');
    const itemList = [
        {
            title: 'Particulars',
            name: 'itemName',
            type: 'text'
        },
        {
            title: 'Details',
            name: 'details',
            type: 'text'
        },
        {
            title: `Rate in ${freightInvoice.currency}`,
            name: 'usdRate',
            type: 'number'
        },
        {
            title: `Amount(${freightInvoice.currency})`,
            name: 'amountUSD',
            type: 'label'
        },
        {
            title: 'Delete',
            name: 'delete',
            type: 'deleteButton'
        },
    ];

    let { id } = useParams();

    
    const [itemColumns, setItemColumns] = useState('');
    const [loader, setLoader] = useState(false);
    const [fiNumber, setFINumber] = useState(id);
    const [items, setItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [addressType, setAddressType] = useState('');
    const [action, setAction] = useState('Save');
    const [states, setStates] = useState(null);


    function transFromJsonToClass(){
        var defaultClassModel = {};
        for( var item in itemList){
            defaultClassModel[itemList[item].name]='';            
        }
        return defaultClassModel;
    }

    const reload = () => {
        if (fiNumber !== 'new') {
            setLoader(true);
            setAction('Update');
          axios
            .get(`/api/Lookup/GetFreightInvoice/${fiNumber}`)
            .then((response) => {
                setFreightInvoice(response.data);
                setItems(response?.data?.freightInvItems)
                setLoader(false);
                getStates();
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
                setLoader(false);
            });
        } 
      };

      const getStates = () =>{
        axios
            .get('/api/Lookup/GetStates')
            .then((response) => {
                setStates(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }
      useEffect(() => {
        if(id !== 'new'){
            reload();
        }
        const jsonData =  transFromJsonToClass(itemList);
        setItems([jsonData]);
      }, []);

      useEffect(() => {
        if(states != null){
            setFreightInvoice({
                ...freightInvoice,
                billingStateName: states[freightInvoice.billingState],
                shippingStateName: states[freightInvoice.shippingState],
            });
        }
     }, [states]);

      useEffect(() => {
        if(id === 'new' && fiNumber !== ''){
            reload();
        }
      }, [fiNumber]);

    useEffect(() => {
        if(freightInvoice.currency){
            setItemColumns(itemList);
        }
    }, [freightInvoice.currency]);

    const handleChange = (e) => {
        const { target } = e;
        const {
            name, value
        } = target; 

        if(addressType === 'billing' || addressType === 'shipping' )
        {
            if(name === 'State'){
                const label = target.options[value].label;
                setFreightInvoice((ps) => {
                    return {
                        ...ps,
                        [addressType+name]: parseValue(name, value),
                        [addressType+name+'Name']: label,
                    };
                })
            }
            else{
                setFreightInvoice((ps) => {
                    return {
                        ...ps,
                        [addressType+name]: parseValue(name, value),
                    };
                })
            }
        }else {
             setFreightInvoice((ps) => {
                return {
                    ...ps,
                    [name]: value,
                };
            })
        }
       

        if (name === "clientName") {
            setFreightInvoice({
                ...freightInvoice,
                [name]: value,
              });
        }
    }

    const parseValue = (name, value) => {
        switch (name) {
            case 'usdRate':
                return parseFloat(value === '' ? 0 : value);
            case 'quantity':
                return parseInt(value === '' ? 0 : value);
            default:
                return value;
        }
    }

    const handleItemChange = (e, idx) => {
        const { target } = e;
        const {
            name, type, value,
        } = target; 
        const old = items[idx];
        const updated = { ...old, [name]: parseValue(name, value) }
        const clone = [...items];
        clone[idx] = updated;
        setItems(clone);
    }

    const calculateAmount = (e, idx) => {
        if(items[idx].itemDetail !== '' || items[idx].usdRate !== '') {
           
            const total = items[idx].usdRate;
            const old = items[idx];
            const updated = { 
                ...old, 
                amountUSD: total,
            }
            const clone = [...items];
            clone[idx] = updated;
            const emptyItem = items.filter((e)=>e.itemDetail === '').length;
            if(e.target.name === 'itemDetail' && emptyItem === 0){    

                setItems(clone.concat(transFromJsonToClass(itemList)));
            }
            else{
                setItems(clone);
            }
        }
    }

    const handleValidation = (e) => {
        const { target } = e;
        const {
          name, required, value,
        } = target;

        if (name === "clientName") {
            setFreightInvoice({
                ...freightInvoice,
                [name]: value,
              });
        }

        if(name === 'fiNumber'){
            setFINumber(value);
        }
    }

    const handleSubmit = () => {
        setLoader(true);
        setPreviewModal(false);
        var url = '/api/GenerateInvoice/SaveAndUpdateFreightInvoice';
        const method = 'post';
        const payLoad = { 
            ...freightInvoice, 
            action: action,
            billingState: parseInt(freightInvoice.billingState),
        }
        axios({
        method,
        url: url,
        data: JSON.stringify(payLoad),
        headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if(response.data === 'success'){
                setLoader(false);
                notify('success',`Successfully ${action}d`);
            }
            else{
                setLoader(false);
                notify('error', response.data);
            }
        })
        .catch((e) => {
        if (e.name !== 'AbortError') {
            console.log('fail:', e.message)
            setLoader(false);
            notify('error', e.message);
        }
        });
      }
      
    const handlePreview =()=>{
        setFreightInvoice({
            ...freightInvoice,
            freightInvItems: items.filter((a) => a.itemName !== ''),
          });
        setPreviewModal(true);
    }  
    const removeItem = (id) => {
        
        axios
            .get(`/api/Lookup/DeleteItem/${id}/FreightInvItems`)
            .then((response) => {
                if(response.data === "success"){
                    notify('success', 'Successfully Removed');
                }
                else{
                    notify('error', "Something Went Wrong");
                }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                }
            });
    }
    const handleRemove = (itemId, idx) => {
        const data = items.filter((a)=> a.itemId !== itemId );
        setItems(data)
        if(itemId !== 0){
            removeItem(itemId);
        }
      };
    return (
        <>
        {loader ? <Loader /> : ''}
        {modalOpen ? <AddressModal 
                        setModalOpen={setModalOpen} 
                        invoiceDetails={freightInvoice} 
                        handleChange={handleChange} 
                        addressType={addressType} 
                        setAddressType={setAddressType} 
                        gstInNeed={true}
                    /> : ''}
        {previewModal ? <FreightInvModalPopup 
                            invoiceDetails={freightInvoice}
                            setInvoiceDetails={setFreightInvoice}
                            setPreviewModal={setPreviewModal}
                            action={action}
                            handleSubmit={handleSubmit}
                        /> : ''}
       <div className='custom-container'>				
            <div className='card mt-5'>
                <div className='card-header'>
                    <div className='card-title m-0'>
                        <div className='d-flex flex-column'>
                            <h2 className='card-h2'>{id === 'new' ? 'New' : 'Update'} Freight Invoice</h2>
                        </div>
                    </div>
                </div>
				<div className='card-custom-body'>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4">Currency Type</div>
                        <div className="col-md-8">
                            <RadioButtons
                                name="currency"
                                value={freightInvoice.currency}
                                inline
                                options={{ Rs: 'RUPEES', USD: 'DOLLOR', EURO: 'EURO' }}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4">Client Name</div>
                        <div className="col-md-8">
                            {
                                freightInvoice !== '' || id === 'new' ? 
                                <ApiTypeAhead
                                    name="clientName"
                                    title=''
                                    defaultId={freightInvoice.clientName}
                                    displayKey="clientName"
                                    query={freightInvoice.clientName}
                                    placeholder="Start typing to search"
                                    url="/api/Lookup/GetClientName"
                                    onChange={handleChange}
                                    onBlur={handleValidation}
                                    required
                                />
                                 : <div>Loading....</div>
                            }
                        </div>
                    </div>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4"></div>
                        <div className='col-md-3' >
                            <span className="cursor-p m-1 text-primary" onClick={() => {setModalOpen(true); setAddressType('billing')}}>
                                Debit To Address
                            </span>
                        </div> 
                    </div>
                    {
                        freightInvoice.billingAddress && (
                            <div className="row p-10px mt-4">
                                <div className="col-md-3 mt-4"></div>
                                <div className="col-md-3">
                                    <span>{freightInvoice.billingAddress}</span><br />
                                    <span>{freightInvoice.billingAddressLine}</span><br />
                                    <span>{freightInvoice.billingCity}</span><br />
                                    <span>{freightInvoice.billingStateName}</span><br />
                                </div>
                            </div>     
                        )
                    }
                    <div className="row p-10px mt-4">
                        <div className="col-md-3">Shipment Details / <br />Container Type</div>
                        <div className="col-md-8">
                                <Input 
                                    name="shipmentDetails"
                                    title=""
                                    required
                                    type="text" 
                                    placeholder="Enter Shipment Details / Container Type"
                                    value={freightInvoice.shipmentDetails}
                                    onChange={handleChange}
                                />                      
                        </div>
                    </div>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4">EXPORTER INVOICE NO</div>
                        <div className="col-md-4">
                            <Input 
                                name="expInvoiceNo"
                                title=""
                                required
                                type="text" 
                                placeholder="EXPORTER INVOICE NO"
                                value={freightInvoice.expInvoiceNo}
                                onChange={handleChange}
                            />                       
                        </div>
                        <div className="col-md-2 mt-10">EXP Date</div>
                        <div className="col-md-2">
                                <DatePicker 
                                    name='expDate'
                                    title=''
                                    selected={freightInvoice.expDate ? new Date(freightInvoice.expDate) : new Date()}  
                                    onChange={(d) => 
                                        setFreightInvoice({
                                            ...freightInvoice, 
                                            expDate : new Date(d).toLocaleDateString() 
                                            })
                                        }
                                    placeholderText='EXP Date'
                                    className='form-control'
                                    dateFormat="dd/MM/yyyy" 
                                />
                        </div>
                    </div>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4">Description of goods</div>
                        <div className="col-md-8">
                                <Input 
                                    name="description"
                                    title=""
                                    required
                                    type="text" 
                                    placeholder="Description of goods"
                                    value={freightInvoice.description}
                                    onChange={handleChange}
                                />                      
                        </div>
                    </div>
                    <div className="row p-10px item-list invoice-box mt-4">
                            {
                                itemColumns !== '' ?
                                    <PaymentInvoiceItem 
                                        data={itemColumns} 
                                        onChange={handleItemChange} 
                                        items={items}
                                        onAddItem={(e) => setItems(items.concat(transFromJsonToClass(itemList)))}
                                        onBlur={calculateAmount}    
                                        handleRemove={handleRemove}        
                                    />
                                : ''
                            }
                        </div>
                        <div className="invalid-feedback"></div>
                        <div className="row p-10px mb-3 pt-4">
                            <div className="col-md-2">
                                <button 
                                    type="button" 
                                    className="form-control btn btn-sm btn-primary-custom" 
                                    onClick={handlePreview}
                                >
                                Save</button>
                            </div>
                            <div className="col-md-2">
                                <Link to={'/admin/freightInvoice'} className='btn btn-sm btn-primary-custom'>
                                    Cancel
                                </Link>
                            </div>
                        </div>
                        </div>
                    </div> 
			</div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>

    );
}

export default FreightInvoiceForm;