import React from "react";
import { Link } from "react-router-dom";
const Footer = () =>{
    return (
        <>
        <footer class="ftco-footer ftco-footer-2 ftco-section">
            <div class="container">
                <div class="row mb-5">
                <div class="col-md">
                    <div class="ftco-footer-widget">
                    <h2 class="ftco-footer-logo"><span>TGL EXPRESS INDIA</span></h2>
                    <p class="text-justify">International Courier and Freight Forwarding Company, We Provides Fast And Safe Air and Ocean Freight Specialized Logistics Management.</p>
                    <ul class="ftco-footer-social list-unstyled mt-2">
                        <li class="ftco-animate"><a href="#"><span class="fa fa-linkedin"></span></a></li>
                        <li class="ftco-animate"><a href="https://www.facebook.com/p/TGL-Express-India-Private-Limited-100084868929711/?locale=en_GB" target="_blank"><span class="fa fa-facebook"></span></a></li>
                        <li class="ftco-animate"><a href="#"><span class="fa fa-twitter"></span></a></li>
                        <li class="ftco-animate"><a href="https://api.whatsapp.com/send?phone=919663034113" target="_blank"><span class="fa fa-whatsapp"></span></a></li>
                    </ul>
                    </div>
                </div>
                <div class="col-md">
                    <div class="ftco-footer-widget mb-4 ml-md-5">
                    <h2 class="ftco-heading-2">Explore</h2>
                    <ul class="list-unstyled">
                        <li><Link to="/aboutUs" class="py-2 d-block">About</Link></li>
                        <li><Link to="/contactUs" class="py-2 d-block">Contact</Link></li>
                    </ul>
                    </div>
                </div>
                <div class="col-md">
                <div class="ftco-footer-widget">
                <h2 class="ftco-heading-2">Have a Questions?</h2>
                <div class="block-23 mb-3">
                    <ul>
                    <li><span class="icon fa fa-map marker"></span><span class="text">NO 79, GROUND FLOOR, M.S RAMAIAH ENCLAVE,  NAGASANDRA POST, 2ND MAIN 8TH MILE,  Bengaluru, Karnataka, INDIA,  560073</span></li>
                    <li><a href="#"><span class="icon fa fa-phone"></span><span class="text">(+91)9663034113</span></a></li>
                    <li><a href="mailto:info@tglexpressindia.com"><span class="icon fa fa-paper-plane pr-4"></span><span class="text">info@tglexpressindia.com</span></a></li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
            <div class="row">
            <div class="col-md-12 text-center">

                <p>
                Copyright &copy;
                {/* <script>document.write(new Date().getFullYear());</script>  */}
                All rights reserved
                </p>
                </div>
            </div>
            </div>
        </footer>
        </>
    )
}
export default Footer;