import DashboardIcon from '../../images/Icons/dashboard.svg';
import FormIcon from '../../images/Icons/form.svg';
import BlankIcon from '../../images/Icons/blank.svg';
import MenusIcon from '../../images/Icons/menus.svg';
import DotIcon from '../../images/Icons/dot.svg';
// import Sale from '../../images/Icons/sale.svg';

export const SidebarData = [
    {
        id: 1,
        title: 'Dashboard',
        path: '/admin/home',
        icon: DashboardIcon,
        menu: 'dashboard'
    },
    {
        id: 2,
        title: 'Invoice',
        path: '/admin/invoice',
        icon: FormIcon,
        menu: 'invoice',
    },
    {
        id: 3,
        title: 'Items',
        path: '/admin/item',
        icon: BlankIcon,
        menu: 'item',
    },
    {
        id: 4,
        title: 'Sales',
        path: '#',
        icon: MenusIcon,
        menu: 'sales',
        subNav: [
            {
                title: 'InvoiceTDS',
                path: '/admin/invoiceTDS',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'invoiceTDS',
            },
            {
                title: 'Invoice Statement',
                path: '/admin/invoiceStatement',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'invoiceStatement',
            },
            {
                title: 'Credit Debit Notes',
                path: '/admin/debitAndCreditNote',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'debitAndCreditNote',
            },
            {
                title: 'Credit Notes',
                path: '/admin/creditNote',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'creditNote',
            },
            {
                title: 'Debit Notes',
                path: '/admin/debitNote',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'debitNote',
            },
            {
                title: 'Freight Invoice',
                path: '/admin/freightInvoice',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'freightInvoice',
            }
        ]
    },
    {
        id: 5,
        title: 'Rate',
        path: '#',
        icon: MenusIcon,
        menu: 'rate',
        subNav: [
            // {
            //     title: 'Get Rates',
            //     path: '/admin/getRates',
            //     icon: DotIcon,
            //     cName: 'sub-nav',
            //     subMenu: 'getRate',
            // },
            {
                title: 'Rate File Upload',
                path: '/admin/rateFileUpload',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'rateFileUpload',
            },
            {
                title: 'Add Fuel Surcharge',
                path: '/admin/addFuelSurcharge',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'addFuelSurcharge',
            },
            {
                title: 'Add Margin',
                path: '/admin/addMargin',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'addMargin',
            }
        ]
    },
    {
        id: 6,
        title: 'Booking',
        path: '#',
        icon: MenusIcon,
        menu: 'booking',
        subNav: [
            {
                title: 'Booking Report',
                path: '/admin/bookingReport',
                icon: DotIcon,
                cName: 'sub-nav',
                subMenu: 'bookingReport',
            }
        ]
    }
  
];
