import React, { useState, useEffect, useContext } from 'react';
import { FaMinusCircle } from 'react-icons/fa';
import { Context } from '../../../context/InvoiceContext';
import Input from '../../Common/Input';
import Datepicker from '../../Common/Datepicker';

const PaymentInvoiceItem = (props) => {
    const { data, 
            onChange,  
            items,
            onAddItem,
            onBlur,
            onTaxChange,
            handleRemove
        } = props;
    const [, , , , paymentInvoiceItem, setPaymentInvoiceItem] =  useContext(Context);
    function transFormJsonToClass(data){
        var defaultClassModel = {};
        for( var item in data){
            defaultClassModel[data[item].name]='';            
        }
        return defaultClassModel;
    }
    useEffect(() =>{
       const jsonData =  transFormJsonToClass(data);
       setPaymentInvoiceItem([jsonData]);
    },[])

    const getTaxRates =()=>{
        const taxRates = [{label:'0%', value: 0},{label:'5%', value: 5},{label:'18%', value: 18}];
        return taxRates;
    }
    
    const ItemField =(attr, item, idx)=> {
        switch (attr.type) {
            case 'text':
            case 'number':    
              return (
                <Input 
                    name={attr.name}
                    title=''
                    required
                    type={attr.type}
                    placeholder={attr.title}   
                    value={item[attr.name]}
                    onChange={(e) => onChange(e, idx)}
                    onBlur={(e) => onBlur(e, idx)}
                />
              );
            case 'select':
            return (
                <select
                    name={attr.name} 
                    value={item[attr.name]} 
                    onChange={(e) => onTaxChange(e, idx)} 
                    className="form-control" 
                    >
                        {getTaxRates().map((option) => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                </select>
            );
            case 'success':
              return (
                <Datepicker
                    name={attr.name}
                    title=''
                    selected={new Date()} 
                    placeholderText={attr.title}
                    className='form-control'
                    dateFormat="dd/MM/yyyy"   
                />
              );
            case 'deleteButton':
            return (
                <button
                    type="button"
                    className="btn btn-link m-0 p-0 color-r"
                    title=""
                    onClick={(e) => handleRemove(item.itemId, idx)}
                >
                    <FaMinusCircle />
                </button>
            );
            case 'label':
            return (
                <span name={attr.name}>{item[attr.name]}</span>
            );
            default:
                return null;
            }
    }
	return (
        <>
        { paymentInvoiceItem.length > 0 ? 
            (
            <>
            <table className='table'>
                <thead>
                    <tr>
                        {
                            data.map((header) => (
                                <td>{header.title}</td>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        items?.map((item, idx)=>{
                           return <tr key={idx}>
                                    {data.map((attr) => {
                                       return <td>
                                            {ItemField(attr, item, idx)}
                                        </td>
                                    })}
                            </tr> 
                        })                      
                    }
                </tbody>
            </table>
            <div className="col-md-6">
                <button 
                    type="button" 
                    className="btn btn-sm btn-primary-custom" 
                    onClick={onAddItem}
                >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z"></path></svg>
                    Add Item
                </button>
            </div>
        </>) : ''
        }
	</>
	)
}

export default PaymentInvoiceItem;