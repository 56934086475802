import React, { useState, useContext } from 'react';
import axios from 'axios';
import Input from '../Common/Input';
import './signIn.css';
// import { navigate } from '@reach/router';
import { useNavigate } from 'react-router-dom';
import Loader from '../Common/Loader';

import { AuthContext } from '../../context/AuthContext';

const SignIn = () => {
    const [loader, setLoader] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState('');

    const { login } = useContext(AuthContext);

    const navigate = useNavigate();

    const getLogin = () => {
        setLoader(true);
        axios
        .get(`/api/Login/GetLogin?userName=${userName}&password=${password}`)
        .then((response) => {
            if(response.data === "success"){
                localStorage.setItem('login', 'success');
                setLoader(false);
                navigate('/admin/home');
                login(response.data);
                // loginSuccess('success')
            }
            else if (response.data === "failure"){
                localStorage.setItem('login', 'failure');
                setLoader(false);
            }
            setLoginStatus(response.data);
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
            }
        });
    } 
    return (
        <>
        {loader ? <Loader /> : ''}
        <div className="custom-container login-bg">
            <div className='card card-custom-bgi mt-5 login-card'>
                <div className='card-header login-card-head'>
                    <div className='d-flex align-items-center m-auto'>
                        <h2 className="section-title">User Login</h2>
                    </div>
                </div>
            <div className='card-custom-body'>
                <div className=''>
                    <div className='m-2'>
                        <Input 
                            name="userName"
                            required
                            type="text" 
                            placeholder="UserName"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />

                    </div>
                    <div className='m-2'>
                        <Input 
                            name="password"
                            required
                            type="password" 
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <div className="d-flex m-2 login-button">
                        <button type="button" className="form-control btn btn-sm btn-primary-custom" onClick={getLogin}>Login</button>
                    </div>
                    {loginStatus === 'failure' &&
                        <div className="warning" ><span>Invalid Credential</span></div>
                    }
                </div>
            </div>
            </div>
        </div> 
    </>
    );
}

export default SignIn;
