import React,{ useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import FormIcon from '../images/Icons/form.svg';
import Loader from '../components/Common/Loader';
import { dateFormatter } from '../components/CommonJsFunc/CommonJsFunc';

import { Context } from '../context/MenuContext';
 
const DebitAndCreditNote = () => {

    const [loader, setLoader] = useState(false);
    const [invoices, setInvoices] = useState([]);

    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(Context);

	const getInvoicReports = () => {
        axios
        .get('/api/Lookup/GetInvoiceReports')
        .then((response) => {
            const result = response.data;
            const data = result.map((item) => {
                return {
                    ...item,
                    totalAmount: parseFloat(item.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                }
            }) 
            setInvoices(data);
            setLoader(false);
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
            }
            setLoader(false);
        });
    } 
    useEffect(() => {
        setLoader(true);
        getInvoicReports();
        setSelectedMenu('sales');
		setSelectedSubMenu('debitAndCreditNote');
    }, []);

	return (
		<>
        {loader ? <Loader /> : '' }
			<div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
							<div className='d-flex flex-column'>
								<h2 className='card-h2'>Generate Debit And Credit Note By Invoice</h2>
							</div>
						</div>
					</div>
					<div className='card-body'>
                    <div className='row pb-3'>
                            <div className='col-md-12 text-right'>
                                <Link to='/admin/creditNote' className='btn btn-sm btn-primary-custom m-2'>
                                    Goto Credit Note
                                </Link>
                                <Link to='/admin/debitNote' className='btn btn-sm btn-primary-custom'>
                                    Goto Debit Note
                                </Link>
                            </div>
                        </div> 
                        <div className="row p-10px item-list respnsive-custom-table">
                            <table className='table'>
                                <thead className='sticky-header'>
                                    <tr className="heading">
                                        <th className='bg-body-secondary'>S.NO.</th>
                                        <th className='bg-body-secondary'>Client Name</th>
                                        <th className='bg-body-secondary'>Invoice Number</th>
                                        <th className='bg-body-secondary'>Date</th>
                                        <th className='bg-body-secondary'>Total Amount</th>
                                        <th className='bg-body-secondary'>Credit Note</th>
                                        <th className='bg-body-secondary'>Debit Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { invoices?.map((item, idx) => (
                                        <tr className="p-10px item" key={idx}>
                                            <td>{idx + 1}</td>
                                            <td><strong>{item.clientName}</strong></td>
                                            <td><strong>{item.invoiceNumber}</strong></td>
                                            <td><span>{dateFormatter(item.invoiceDate)}</span></td>
                                            <td><span>{item.totalAmount}</span></td>
                                            <td>
                                                <Link to='/admin/creditForm/new' className='color-b'>
                                                    <img src={FormIcon} className='' alt='' />{" "}
                                                    Credit Note
                                                </Link>                                                
                                            </td>
                                            <td>
                                                <Link to='/admin/debitNoteForm/new' className='color-b'>
                                                    <img src={FormIcon} className='' alt='' />{" "}
                                                    Debit Note
                                                </Link>
                                            </td>
                                    </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
					</div>
				</div>
				
			</div>
		</>
	)
}

export default DebitAndCreditNote