import React, { useState } from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

const RadioButtons = (props) => {
  const {
    name,
    title,
    onChange,
    options,
    value,
    tabIndexStart,
    errorText,
    required,
    inline,
    disabled,
  } = props;
  const [id] = useState(nextId());

  let className = 'form-check-input';
  if (errorText) {
    className += ' is-invalid';
  }

  const defaultOpts = {
    className,
    type: 'radio',
    name,
  };
  if (onChange && onChange instanceof Function) {
    defaultOpts.onChange = onChange;
  }

  const keys = Object.keys(options || {});
  const lastIndex = keys.length - 1;

  const markup = keys.map((option, index) => {
    const label = options[option];
    const opts = {
      id: `${id}-${index}`,
      value: option,
      checked: String(value) === option,
    };

    if (tabIndexStart) {
      opts.tabIndex = tabIndexStart + index;
    }

    if (required === true) {
      opts.required = true;
    }

    if (disabled) {
      opts.disabled = disabled;
    }

    const inErr = errorText && lastIndex === index;
    const className = inline === true ? 'form-check form-check-inline' : 'form-check';
    return (
      <>
        <div className={className} key={option}>
          <input {...opts} {...defaultOpts} />
          <label htmlFor={opts.id} className="form-check-label">
            {label}
          </label>
        </div>
        {inErr && (
          <div style={{ display: 'block' }} className="invalid-feedback">
            {errorText}
          </div>
        )}
      </>
    );
  });

  return (
    <div>
      <label className="form-label">{title}</label>
      <div className="form-group">{markup}</div>
    </div>
  );
};
/* eslint-disable */
RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  tabIndexStart: PropTypes.number,
  errorText: PropTypes.string,
  required: PropTypes.bool,
  inline: PropTypes.bool,
};

export default RadioButtons;
