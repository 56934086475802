import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/home.css';
import Loader from '../components/Common/Loader';
import BarChart from '../components/Common/BarChart';
import { Context } from '../context/MenuContext';

const Dashboard = () => {

	const notify = (msg) => toast.error(msg);

	const [key, setKey] = useState('dashboard');
	const [invoices, setInvoices] = useState([]);
	const [loader, setLoader] = useState([]);
	const [currYearTotalInvoice, setCurrYearTotalInvoice] = useState(0);
	const [currMonthTotalInvoice, setCurrMonthTotalInvoice] = useState(0);
	const [totalTaxCurrMonth, setTotalTaxCurrMonth] = useState(0);
	const [totalTaxCurrYear, setTotalTaxCurrYear] = useState(0);
	const [totalInvAmtCurrMonth, setTotalInvAmtCurrMonth] = useState(0);
	const [totalInvAmtCurrYear, setTotalInvAmtCurrYear] = useState(0);
	const [saleSeries, setSaleSeries] = useState([])
	const [saleCategories, setSaleCategories] = useState([])
	const [taxSeries, setTaxSeries] = useState([])
	const [taxCategories, setTaxCategories] = useState([])
	const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(Context);

	const toIndianCurrency = (num) => {
        const curr = num.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        });
        return curr;
    };

	const getInvoicReports = () => {
        axios
        .get('/api/Lookup/GetInvoiceReports')
        .then((response) => {
            setInvoices(response.data);
            setLoader(false);
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
				notify(e.message);
            }
            setLoader(false);
        });
    } 
    useEffect(() => {
		setSelectedMenu('dashboard');
		setSelectedSubMenu('');
        setLoader(true);
        getInvoicReports();
    }, []);

	function barGraphBind(){
		
		const getYears = [...new Set(invoices.map((e) => new Date(e.invoiceDate).getFullYear()))]; 
		const yearlySaleAmt = []
		const yearlyTaxAmt = []
		for (let index = 0; index < getYears.length; index++) {
			const yearlySale = invoices?.filter((i) => new Date(i.invoiceDate).getFullYear() == getYears[index])?.map((invoice) => invoice?.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0)).reduce((prev, curr) => prev + curr, 0);			
			const yearlyTax = invoices?.filter((i) => new Date(i.invoiceDate).getFullYear() == getYears[index])?.map((invoice) => invoice?.items?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).reduce((prev, curr) => prev + curr, 0);			
			yearlySaleAmt.push(yearlySale.toFixed(2));
			yearlyTaxAmt.push(yearlyTax.toFixed(2));
		}
		const saleSeries = [{
			name: 'Sales',
			data: yearlySaleAmt
		  }];
		const taxSeries = [{
			name: 'Tax',
			data: yearlyTaxAmt
		  }];  
		setSaleSeries(saleSeries);
		setSaleCategories(getYears);
		setTaxSeries(taxSeries);
		setTaxCategories(getYears);
	}
	useEffect(() => {
		const currYearInvoices = invoices?.filter((i) => new Date(i.invoiceDate).getFullYear() == new Date().getFullYear()).length;
		const currMonthInvoices = invoices?.filter((i) => new Date(i.invoiceDate).getMonth() == new Date().getMonth()).length;;
		const currYearTotalTax = invoices?.filter((i) => new Date(i.invoiceDate).getFullYear() == new Date().getFullYear())?.map((invoice) => invoice?.items?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).reduce((prev, curr) => prev + curr, 0);
		const currMonthTotalTax = invoices?.filter((i) => new Date(i.invoiceDate).getMonth() == new Date().getMonth())?.map((invoice) => invoice?.items?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).reduce((prev, curr) => prev + curr, 0);
		const currYearTotalInvoiceAmount = invoices?.filter((i) => new Date(i.invoiceDate).getFullYear() == new Date().getFullYear())?.map((invoice) => invoice?.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0)).reduce((prev, curr) => prev + curr, 0);
		const currMonthTotalInvoiceAmount = invoices?.filter((i) => new Date(i.invoiceDate).getMonth() == new Date().getMonth())?.map((invoice) => invoice?.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0)).reduce((prev, curr) => prev + curr, 0);
		setCurrYearTotalInvoice(currYearInvoices);
		setCurrMonthTotalInvoice(currMonthInvoices);
		setTotalTaxCurrMonth(currMonthTotalTax);
		setTotalTaxCurrYear(currYearTotalTax);
		setTotalInvAmtCurrMonth(currMonthTotalInvoiceAmount);
		setTotalInvAmtCurrYear(currYearTotalInvoiceAmount);
		barGraphBind();	

      }, [invoices]); 

	return (
		<>
			{loader ? <Loader /> :''}
			<div className='custom-container'>
				<div className='card-custom card-custom-bgi mt-5'>
					<div className='card-custom-header i-mb-4'>
						<div className='d-flex align-items-center'>
							<div className='symbol symbol-circle me-4'>
								<div className='symbol-label bg-transparent text-primary'>
									<svg stroke="#0c98fc" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1.9em" width="1.9em" xmlns="http://www.w3.org/2000/svg"><rect width="7" height="9" x="3" y="3"></rect><rect width="7" height="5" x="14" y="3"></rect><rect width="7" height="9" x="14" y="12"></rect><rect width="7" height="5" x="3" y="16"></rect></svg>
								</div>
							</div>
							<div className='d-flex flex-column'>
								<h2 className='card-h2'>Dashboard</h2>
								<div className="text-muted text-link-info">
									<Link to='/admin/invoice'>Go to Invoice Page</Link> 
									<span className="mx-3">|</span> 
									<Link to='/admin/home'>Your Top Expenses</Link> 
									<span className="mx-3">|</span>
									<Link to='/admin/home'>Account Watchlist </Link> 
									<span className="mx-3">|</span> 
									758 items
								</div>
							</div>
						</div>
					</div>
					<div className='card-custom-body'>
						<Tabs
							id="controlled-tab-example"
							activeKey={key}
							onSelect={(k) => setKey(k)}
							className="mb-3"
							>
							<Tab eventKey="dashboard" title="Dashboard">
								<div className='row'>
									<div className='col-md-6'>
										<div className='dashboard-card'>
											<BarChart series={saleSeries} categories={saleCategories} title="Sales Graph" />
										</div>										
									</div>
									<div className='col-md-6'>
										<div className='dashboard-card'>
									 		<BarChart series={taxSeries} categories={taxCategories} title="Tax Graph" />
										</div>
									</div>
								</div>
							</Tab>
							<Tab eventKey="matrix" title="Sale & Tax Matrix">
								<div className='p-3'>
									<div className='row'>

										<div className='col'>
											<div className='dashboard-card'>
												<div className='dashboard-card-icon'>
													<span className='symbol-label'>
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1.8em" width="1.8em" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-13z"></path><path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path></svg>
													</span>
												</div>
												<div className='dashboard-card-content'>
													<span className='text-gray-700 mb-1 d-block'>{currMonthTotalInvoice}</span>
													<span className='text-gray-500'>Current Month Total Invoice</span>
												</div>
											</div>
										</div>

										<div className='col'>
											<div className='dashboard-card'>
												<div className='dashboard-card-icon'>
													<span className='symbol-label'>
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1.8em" width="1.8em" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-13z"></path><path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path></svg>
													</span>
												</div>
												<div className='dashboard-card-content'>
													<span className='text-gray-700 mb-1 d-block'>{currYearTotalInvoice}</span>
													<span className='text-gray-500'>Current Year Total Invoice</span>
												</div>
											</div>
										</div>

										<div className='col'>
											<div className='dashboard-card'>
												<div className='dashboard-card-icon'>
													<span className='symbol-label'>
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1.8em" width="1.8em" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-13z"></path><path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path></svg>
													</span>
												</div>
												<div className='dashboard-card-content'>
													<span className='text-gray-700 mb-1 d-block'>{toIndianCurrency(totalTaxCurrMonth)}</span>
													<span className='text-gray-500'>Current Month Total Tax</span>
												</div>
											</div>
										</div>

										<div className='col'>
											<div className='dashboard-card'>
												<div className='dashboard-card-icon'>
													<span className='symbol-label'>
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1.8em" width="1.8em" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-13z"></path><path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path></svg>
													</span>
												</div>
												<div className='dashboard-card-content'>
													<span className='text-gray-700 mb-1 d-block'>{toIndianCurrency(totalTaxCurrYear)}</span>
													<span className='text-gray-500'>Current Year Total Tax</span>
												</div>
											</div>
										</div>
										<div className='col'>
											<div className='dashboard-card'>
												<div className='dashboard-card-icon'>
													<span className='symbol-label'>
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1.8em" width="1.8em" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-13z"></path><path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path></svg>
													</span>
												</div>
												<div className='dashboard-card-content'>
													<span className='text-gray-700 mb-1 d-block'>{toIndianCurrency(totalInvAmtCurrMonth)}</span>
													<span className='text-gray-500'>Current Month Total Invoice Amount</span>
												</div>
											</div>
										</div>
										<div className='col'>
											<div className='dashboard-card'>
												<div className='dashboard-card-icon'>
													<span className='symbol-label'>
														<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1.8em" width="1.8em" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v2A1.5 1.5 0 0 1 14.5 5h-13A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-13z"></path><path d="M2 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm10.823.323-.396-.396A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"></path></svg>
													</span>
												</div>
												<div className='dashboard-card-content'>
													<span className='text-gray-700 mb-1 d-block'>{toIndianCurrency(totalInvAmtCurrYear)}</span>
													<span className='text-gray-500'>Current Year Total Invoice Amount</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Tab>
						</Tabs>
					</div>
				</div>
				
			</div>
			<ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
		</>
	)
}

export default Dashboard