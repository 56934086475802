import Header from "../Home/Header";
import Menu from "../Home/Menu";
import Footer from "../Home/Footer";
import AboutUsCard from "./AboutUsCard";

const AboutUs = ({handleScrollToSection}) => {

    return (
        <>
       <Header />
        <Menu  scrollToSection={handleScrollToSection}  />
        <AboutUsCard />
        <Footer />
        </>
    );
}

export default AboutUs;