import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import '../../styles/tracking.css';
import Input from "../Common/Input";
import { Button } from "react-bootstrap";
import Loader from "../Common/Loader";

const Tracking  = () => {

    const [loader, setLoader] = useState(false);
    const [trackingData, setTrackingData] = useState(null);
    const [openTracking, setOpenTracking] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState('');
    const [found, setFound] = useState(false);
    const [message, setMessage] = useState('');
    
    const mailLink = () => {
        return <p>Please Contact with <a href="mailto:mail2tglexpress@gmail.com" className="cursor-p link-primary">TGL Express</a> for status this parcel. Soory for the inconvenience!</p>
    }

    const trackingMessage = (value) => {
        return <p>Please Enter this <strong>{value}</strong> tracking number in current new tab open and get the status!</p>
    }
    const getTracking = () =>{
       
        setLoader(true);
        axios
            .get(`/api/Booking/GetTracking/${trackingNumber}`)
            .then((response) => {
                console.log(response.data);
                const result = response.data;
                if(result.actualBookingId != null){
                    setMessage(trackingMessage(result.actualBookingId));
                    openTrackingSite(result.courierCompany);    
                    setOpenTracking(true); 
                }
                else{
                    setMessage("This BookingId not found. Try again or wait for some time to start process.");
                }
                setFound(true);                           
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            })
            .finally(() =>{
                setLoader(false);
            });
        // axios
        //     .get(`/api/Booking/GetTracking/${trackingNumber}`)
        //     .then((response) => {
        //         console.log(response.data);
        //         if(response.data?.company == null)
        //         {
        //             setFound(true);
        //             setOpenTracking(false);
        //             setMessage('Tracking Not Found');
        //         }
        //         else if (response.data?.company == 'UPS'){
        //             setFound(true);
        //             setOpenTracking(false);
        //             setMessage(mailLink);
        //         }
        //         else{
        //             setTrackingData(response.data);
        //             setOpenTracking(true);
        //             setFound(false);
        //         }
                
        //     })
        //     .catch((e) => {
        //         if (e.name !== 'AbortError') {
        //             console.log('fail:', e.message)
        //         }
        //     })
        //     .finally(() =>{
        //         setLoader(false);
        //     });
    }
   
    const openTrackingSite = (company) => {
        switch (company) {
            case 'DHL':
                window.open('https://www.dhl.com/in-en/home.html', '_blank', 'noopener')
            case 'FedEx':
                window.open('https://www.fedex.com/en-us/home.html', '_blank', 'noopener')
            case 'UPS':
                window.open('https://www.ups.com/in/en/Home.page', '_blank', 'noopener')
            default:
            // code block
        }
    }

    // function getDHLData(){
    //     setTrackingData((ps) =>{
    //         return { 
    //          ...ps,
    //          trackingId: data.shipments[0]?.id,
    //          origin: data.shipments[0]?.origin?.address?.addressLocality,
    //          destination: data.shipments[0]?.destination?.address?.addressLocality, 
 
    //         }
    //      })
    // }

    // function getFedExData(){
    //     setTrackingData((ps) =>{
    //         return { 
    //          ...ps,
    //          trackingId: data.shipments[0]?.id,
    //          origin: data.shipments[0]?.origin?.address?.addressLocality,
    //          destination: data.shipments[0]?.destination?.address?.addressLocality, 
 
    //         }
    //      })
    // }

    // function getUPSData(){
    //     setTrackingData((ps) =>{
    //         return { 
    //          ...ps,
    //          trackingId: data.shipments[0]?.id,
    //          origin: data.shipments[0]?.origin?.address?.addressLocality,
    //          destination: data.shipments[0]?.destination?.address?.addressLocality, 
 
    //         }
    //      })
    // }

    return (
        <>
        { loader ? <Loader /> : ''}
        <div class="justify-content-end  pt-5 row">
            <div class="col-md-7 ml-3">
                <div class="row align-items-stretch d-flex no-gutters">
                    <div class="col-md-8 col-sm-6 search pb-2"> 
                        <input 
                            class="form-control w-400"
                            placeholder="Enter your tracking number" 
                            value={trackingNumber}
                            onChange={(e) => {setTrackingNumber(e.target.value); e.target.value === '' ? setFound(false) : '';}}
                        /> 
                    </div>
                    <div class="col-md-4 col-sm-6"> 
                        <div class="">
                            <button 
                                class="btn btn-primary form-control ml-2"
                                onClick={getTracking}
                            >TRACK</button> 
                        </div>
                        
                    </div>
                </div>
            </div>
            {
                found  && 
                (<div class="card col-md-7 ml-3 mt-2">
                    <div className={`card-body ${openTracking ? '' : 'color-r'} fs-5`}>
                        {message}
                    </div>
                </div>)
            }            
    </div>
        </>
        
    )

}
export default Tracking;