import React, { useContext} from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../images/logo.png';
import Notifications from './Notifications';
import Settings from './Settings';
import './topbar.css';
import UserLogin from './UserLogin';

import { AuthContext } from '../../context/AuthContext';

const Topbar = () => {

	const { user } = useContext(AuthContext);
	
	return (
		<>
			<div className='topbar-section top-header topbar-theme'>
				<div className='sidebar-brand'>
					<Link to='/' className='sidebar-link-logo'>
						<img src={Logo} className='' alt='TGL Express' />
					</Link>
				</div>
				{user ? (
				<div className='d-flex justify-content-between container-fluid align-items-center'>
					<div className='d-flex'>
						<div className='mx-custom d-flex align-items-center justify-content-center cursor-pointer'>
							<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0H24V24H0z"></path><path d="M21 18v2H3v-2h18zM17.05 3.55L22 8.5l-4.95 4.95v-9.9zM12 11v2H3v-2h9zm0-7v2H3V4h9z"></path></g></svg>
						</div>
						<div className='mx-custom'>
							<div className='nav-search'>
								<input type='text' className='form-control' placeholder='Search...' />
								<span>
									<svg stroke="#7e8299" fill="#7e8299" strokeWidth="0" viewBox="0 0 24 24" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path><path d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 0 0-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z"></path></svg>
								</span>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='mx-custom d-flex align-items-center justify-content-center'>
							<Notifications />
						</div>
						<div className='mx-custom d-flex align-items-center justify-content-center'>
							<Settings />
						</div>
						<div className='mx-custom'>
							<UserLogin />
						</div>
					</div>
				</div>): ''
				}
			</div>
		</>
	)
}

export default Topbar