import React, { useContext } from "react";
import Input from "../Common/Input";


const ProductDetail = (props) =>{
    const { bookingDetail, handleChange } = props;
    return(
        <section className="lh-0">
            <h4>Product Details</h4>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Product</div>
                <div className="col-md-8">
                    <Input 
                        name="product"
                        required
                        type="text" 
                        placeholder="Enter Product"
                        value={bookingDetail.product}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Product Detail</div>
                <div className="col-md-8">
                    <Input 
                        name="productDetail"
                        required
                        type="text" 
                        placeholder="Enter Product Detail"
                        value={bookingDetail.productDetail}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Content Description</div>
                <div className="col-md-8">
                    <Input 
                        name="description"
                        required
                        type="text" 
                        placeholder="Enter Description"
                        value={bookingDetail.description}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Contact Person</div>
                <div className="col-md-8">
                    <Input 
                        name="contactPerson"
                        required
                        type="text" 
                        placeholder="Enter Contact Person"
                        value={bookingDetail.contactPerson}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row p-10px">
                <div className="col-md-4 mt-4">Weight</div>
                <div className="col-md-2 mt-auto">
                    <span className="">{`${bookingDetail.weight} Kgs`}</span>
                </div>
                <div className="col-md-4 mt-4">Number of Parcel</div>
                <div className="col-md-2 mt-auto">
                    <span className="">{bookingDetail.pkgCount}</span>
                </div>
            </div>
        </section>
    )
}

export default ProductDetail;