import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDownload, faRemove } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CsvDownloader from 'react-csv-downloader';
import DatePicker from "react-datepicker";
import Loader from '../../Common/Loader'
import UpdateTDS from './UpdateTDS';
import ApiTypeAhead from '../../Common/ApiTypeAhead';
import ConfirmationPopup from '../InvoiceSaveUpadte/ConfirmationPopup';
import { FYFormat } from '../../CommonJsFunc/CommonJsFunc';

import { Context } from '../../../context/MenuContext';

const InvoiceTDS = () => {
    const defaultInvTDS = () => {
        return {
            clientName: '', 
            invoiceNumber: '',
            date: new Date(), 
            pan: '',
            netAmount: 0,
            gstAmount: 0,
            invoiceAmount: 0,
            tds: 0,
            tdsAmount: 0,
            finalAmount: 0,
            balanceAmount: 0,
            paymentDate: new Date(),
            action: 'save'
        };
      };

    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};

    const [loader, setLoader] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [updateInvoices, setUpdateInvoices] = useState(defaultInvTDS());
    const [modalOpen, setModalOpen] = useState(false);
    const [month, setMonth] = useState('');
    const [monthlyCost, setMonthlyCost] = useState({});
    const [action, setAction] = useState('Save');
    const [clientName, setClientName] = useState('');
    const [deletingInvoice, setDeletingInvoice] = useState('');
    const [confirmModalPopup, setConfirmModalPopup] = useState(false);
    const [searchClient, setSearchClient] = useState('');

    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(Context);

    const dateFormatter = (date) => {
        const yyyy = new Date(date).getFullYear();
        let mm = new Date(date).getMonth() + 1; // Months start at 0!
        let dd = new Date(date).getDate();
    
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
    
        return `${dd}.${mm}.${yyyy}`;
    };
    const getInvoicReports = () => {
        axios
        .get('/api/Lookup/GetTDSInvoiceReports')
        .then((response) => {
            setInvoices(response.data);
            setInvoiceData(response.data);
            setLoader(false);
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message);
                notify('error', e.message);
            }
            setLoader(false);
        });
    } 
    useEffect(() => {
        setLoader(true);
        getInvoicReports();
        setSelectedMenu('sales');
		setSelectedSubMenu('invoiceTDS');
    }, []);

    useEffect(() => {
        var monthlyInvoice = [];
        if(month !== '' && month !== null) {           
            if(clientName !== ''){
                monthlyInvoice = invoiceData.filter((x) => 
                                new Date(x.date).getMonth() === month?.getMonth() 
                                && new Date(x.date).getFullYear() === month?.getFullYear()
                                && x.clientName === clientName
                                );
            }
            else{
                monthlyInvoice = invoiceData.filter((x) => 
                                new Date(x.date).getMonth() === month?.getMonth() 
                                && new Date(x.date).getFullYear() === month?.getFullYear());
            }           
            setMonthlyCost({
                monthlyNetAmount: parseFloat(monthlyInvoice?.map((item) =>  parseFloat(item.netAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                monthlyTotalGST: parseFloat(monthlyInvoice?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                monthlyInvoiceAmount: parseFloat(monthlyInvoice?.map((item) =>  parseFloat(item.invoiceAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                monthlyTds: parseFloat(monthlyInvoice?.map((item) =>  parseFloat(item.tdsAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                monthlyFinalAmount: parseFloat(monthlyInvoice?.map((item) =>  parseFloat(item.finalAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                monthlyBalanceAmount: parseFloat(monthlyInvoice?.map((item) =>  parseFloat(item.balanceAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
            })
            setInvoices(monthlyInvoice);
        }
        else{
            if(clientName !== ''){
                monthlyInvoice = invoiceData.filter((x) => x.clientName === clientName);
                setInvoices(monthlyInvoice);                
            }
            else{
                setInvoices(invoiceData);
            }           
        }
      }, [month]);


    useEffect(() => {
        if(clientName !== '') {
            const searchResult = invoiceData.filter((x) => x.clientName === clientName);
            setInvoices(searchResult);
        }
        else{
            setInvoices(invoiceData);
        }
    }, [clientName]);

    const handleUpdateTDS = () => {
        setLoader(true);
        const method = 'post';
        axios({
          method,
          url: '/api/GenerateInvoice/SaveAndUpdateTDS',
          data: JSON.stringify(updateInvoices),
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            setInvoices(response.data);
            setModalOpen(false);
            getInvoicReports();  
            notify('success',`Successfully ${action}d`);
        })
        .catch((e) => {
          if (e.name !== 'AbortError') {
              console.log('fail:', e.message)
              setLoader(false);
              setModalOpen(false);
              notify('error', e.message);
          }
        });
    } 
    const parseValue = (name, value) => {
        switch (name) {
            case 'tds':
            case 'netAmount':
            case 'gstAmount':
            case 'balanceAmount':
                return parseFloat(value === '' ? 0 : value);
            default:
                return value;
        }
    }
    const handleChange = (e) => {
        const { target } = e;
        const { name, value} = target;
        setUpdateInvoices({
          ...updateInvoices,
          [name]: parseValue(name, value),
        });
        if(name === "clientName"  && isNaN(value)){
            setSearchClient(value);
        }  
    };

    useEffect(() => {
        if(updateInvoices.clientName !== ''){
            axios
            .get(`api/Lookup/GetTDSClientDetails?clientName=${updateInvoices.clientName}`)
            .then((response) => {
                var result = response.data;
                setUpdateInvoices({
                    ...updateInvoices,
                    pan: result.pan,
                });
                setLoader(false);
            })
            .catch((err) => {
                if (err.name !== 'AbortError') {
                    console.error(err);
                    notify('error', err.message);
                }
            });
            }
    }, [searchClient]);

    const handleValidation = (e) => {
        const { target } = e;
        const {
          name, required, value,
        } = target;

        if (name === "clientName") {
            setUpdateInvoices({
                ...updateInvoices,
                [name]: value,
              });
        }
    }

    const handleEdit = (invoiceNumber) => {
        const filterData = invoices.filter((a)=>a.invoiceNumber === invoiceNumber);
        const data = {...filterData[0], action: 'Update'};
        setModalOpen(true); 
        setUpdateInvoices(data);
        setAction('Update');        
      };
    
    const yearFormat =()=> {
        const selectedMonth = new Date(month);
        if(month === '' || month === null) {
            // return new Date().getFullYear() +"-"+(parseInt(new Date().getFullYear()) + 1).toString().substring(2,4);        
            return FYFormat();
        }
        else{
            // return selectedMonth?.getFullYear() +"-"+(parseInt(selectedMonth?.getFullYear()) + 1).toString().substring(2,4);        
            return FYFormat(selectedMonth);
        }
    }

    const removeInvoice = (invoiceNumber) => {
        if(invoiceNumber !== ''){
          setDeletingInvoice(invoiceNumber);
          setConfirmModalPopup(true);
        }
      };

    const handleRemove = () => {
        setLoader(true);
        setConfirmModalPopup(false);
        axios.get(`/api/Lookup/DeleteTDS/${deletingInvoice.replace(/\//g, "_")}`)
            .then((response) => {
                if(response.data === "success"){
                    notify('success', 'Successfully Invoice TDS Removed');
                    getInvoicReports();
                }
                else{
                    notify('error', "Something Went Wrong");
                    setLoader(false);
                }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                    setLoader(false);
                }
            });
    }  
    const CSVData = () => {
        const csvData = invoices.map((x) => (
            {
              InvoiceNumber: x.invoiceNumber,
              ClientName: x.clientName,
              Date: dateFormatter(x.date),
              PAN: x.pan,
              NetAmount: x.netAmount,
              GST: x.gstAmount,
              InvAmount: x.invoiceAmount,
              TDS: x.tdsAmount,
              FinalAmount: x.finalAmount,
              BalanceAmount: x.balanceAmount,
              PaymentDate: dateFormatter(x.paymentDate)
            }
          ));
          if(month !== '' && month !== null) {
            csvData.push(
                {
                    InvoiceNumber: `${month?.toLocaleString('default', { month: 'long' })} month Sub Total Amount`,
                    NetAmount: monthlyCost.monthlyNetAmount,
                    GST: monthlyCost.monthlyTotalGST,
                    InvAmount: monthlyCost.monthlyInvoiceAmount,
                    TDS: monthlyCost.monthlyTds,
                    FinalAmount: monthlyCost.monthlyFinalAmount,
                    BalanceAmount: monthlyCost.monthlyBalanceAmount,
                  }
            )  
          }
       
        return csvData;
      };  
    return (
        <>
        {confirmModalPopup && <ConfirmationPopup invoiceNumber={deletingInvoice} setConfirmModalPopup={setConfirmModalPopup} handleRemove={handleRemove} />}
         {(!loader && modalOpen) && <UpdateTDS 
                            setModalOpen={setModalOpen} 
                            updateInvoices={updateInvoices} 
                            handleUpdateTDS={handleUpdateTDS} 
                            handleChange={handleChange}
                            handleValidation={handleValidation}
                            setUpdateInvoices={setUpdateInvoices}
                            action={action}
                            setAction={setAction}
                        />}
              {loader ? <Loader /> :
              (
            <div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
                            <div className='d-flex flex-column'>
                                <h2 className='card-h2'>TDS Invoices</h2>
                            </div>
						</div>
                        <button className='btn btn-sm btn-primary-custom' onClick={() => { setModalOpen(true); setUpdateInvoices(defaultInvTDS()); setAction('Save'); }}>
							<svg stroke="currentColor" fill="currentColor" strokeWidth="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z"></path></svg>
							New TDS
						</button>
					</div>
                    <div className='card-body'>
                        <div className='row pb-3'>
                            <div className='col-md-3 fs-5 fw-medium pt-2'>
                                {`TDS - ${(month !== '' && month !== null) ? (month?.toLocaleString('default', { month: 'long' })) : 'Year '} ${yearFormat()}`}
                            </div>
                            <div className='col-md-3'>
                                <ApiTypeAhead
                                    name="clientName"
                                    title=''
                                    displayKey="clientName"
                                    query={clientName}
                                    placeholder="Enter Client Name to search"
                                    url="/api/Lookup/GetTDSClientName"
                                    onChange={(e) => setClientName(e.target.value)}
                                    onBlur={(e) => setClientName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='col-md-3'>
                                <DatePicker 
                                    name="month"
                                    title='Select Month for Filter'
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    selected={month} 
                                    onChange={(d) => setMonth(d) }
                                    placeholderText='Please Select Monthly TDS'
                                    className='form-control '
                                />
                            </div>
                            <div className='col-md-3'>
                                <CsvDownloader
                                    className="ml-3 text-right"
                                    filename="TDSInvoice"
                                    extension=".csv"
                                    datas={CSVData}
                                >
                                    <button type="button" className="btn btn-sm btn-primary">
                                        <span className="svg-icon svg-light">
                                        <FontAwesomeIcon icon={faDownload} />
                                        </span>
                                        {' '}
                                        Download CSV
                                    </button>
                                </CsvDownloader>
                            </div>
                        </div>                           
                        <div className="row p-10px item-list respnsive-custom-table">
                            <table className='table'>
                                <thead className='sticky-header'>
                                    <tr className="heading">
                                        <th className='bg-body-secondary border-2'>Client Name</th>
                                        <th className='bg-body-secondary border-2'>Invoice No</th>
                                        <th className='bg-body-secondary border-2'>Date</th>
                                        <th className='bg-body-secondary border-2'>PAN</th>
                                        <th className='bg-body-secondary border-2'>Net Amt</th>
                                        <th className='bg-body-secondary border-2'>GST Amt</th>
                                        <th className='bg-body-secondary border-2'>Inv. Amt</th>
                                        <th className='bg-body-secondary border-2'>TDS Amt</th>
                                        <th className='bg-body-secondary border-2'>Final Amt</th>
                                        <th className='bg-body-secondary border-2'>Bal Amount</th>
                                        <th className='bg-body-secondary border-2'>Payment Date</th>
                                        <th className='bg-body-secondary border-2'>Action</th>
                                        <th className='bg-body-secondary border-2'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { invoices.map((item, idx) => (
                                        <tr className="p-10px item" key={idx}>
                                            <td className='border-2'><strong>{item.clientName}</strong></td>
                                            <td className='border-2'><strong>{item.invoiceNumber}</strong></td>
                                            <td className='border-2'><span>{item.date ? dateFormatter(item.date) : '-'}</span></td>
                                            <td className='border-2'><span>{item.pan}</span></td>
                                            <td className='border-2'><span>{item.netAmount}</span></td>
                                            <td className='border-2'><span>{item.gstAmount}</span></td>
                                            <td className='border-2'><span>{item.invoiceAmount}</span></td>
                                            <td className='border-2'><span>{item.tdsAmount}</span></td>
                                            <td className='border-2'><span>{item.finalAmount}</span></td>
                                            <td className='border-2'><span>{item.balanceAmount === 0 ? "-" : item.balanceAmount}</span></td>
                                            <td className='border-2'><span>{item.paymentDate ? dateFormatter(item.paymentDate) : '-'}</span></td>
                                            <td className='border-2'>
                                                <button type="button" className="btn btn-link text-dark" onClick={()=> handleEdit(item.invoiceNumber)}>
                                                    <FontAwesomeIcon icon={faEdit} size="sm" />
                                                </button>
                                            </td>
                                            <td className='border-2'>
                                                <button type="button" className="btn btn-link text-dark color-r" onClick={()=> removeInvoice(item.invoiceNumber)}>
                                                    <FontAwesomeIcon icon={faRemove} size="sm" />
                                                </button>
                                            </td>
                                    </tr>
                                    ))
                                }
                                {
                                    month !== '' && month !== null ?
                                    <>
                                        <tr>
                                            <td colSpan="4" className='bg-body-secondary border-2'>
                                                <strong>
                                                    {`${month?.toLocaleString('default', { month: 'long' })} month Sub Total Amount`}
                                                </strong>
                                            </td>
                                            <td className='bg-body-secondary border-2'><strong>{monthlyCost.monthlyNetAmount}</strong></td>
                                            <td className='bg-body-secondary border-2'><strong>{monthlyCost.monthlyTotalGST}</strong></td>
                                            <td className='bg-body-secondary border-2'><strong>{monthlyCost.monthlyInvoiceAmount}</strong></td>
                                            <td className='bg-body-secondary border-2'><strong>{monthlyCost.monthlyTds}</strong></td>
                                            <td className='bg-body-secondary border-2'><strong>{monthlyCost.monthlyFinalAmount}</strong></td>
                                            <td className='bg-body-secondary border-2'><strong>{monthlyCost.monthlyBalanceAmount === 0 ? "-" : monthlyCost.monthlyBalanceAmount}</strong></td>
                                            <td className='bg-body-secondary border-2'></td>
                                            <td className='bg-body-secondary border-2'></td>
                                        </tr>
                                    </> : ''
                                }
                                </tbody>
                            </table>
                        </div>
					</div>
				</div>
				
			</div>)}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
    </>
    );
}

export default InvoiceTDS;
