import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from '../Common/Input';
import Loader from '../Common/Loader';

const ChangePassword = () => {
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    const [loader, setLoader] = useState(false);
    const [userName, setUserName] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] =  useState('');
    const [confirmPassNotMatch, setConfirmPassNotMatch] = useState('');
    
    const login = () => {
        if(userName !== '' && oldPassword !== '' && newPassword !== '' && confirmPassword !== ''){
            if(confirmPassNotMatch === ''){
                setLoader(true);
                axios
                .get(`/api/Login/ChangePassword?userName=${userName}&oldPassword=${oldPassword}&newPassword=${newPassword}`)
                .then((response) => {
                    if(response.data === "success"){
                        notify('success', 'Password Successfully Changed');
                        setLoader(false);
                    }
                    else if (response.data === "fail"){
                        notify('error', 'Something Went Wrong. Please Check your Old Password and UserName is correct');
                        setLoader(false);
                    }
                })
                .catch((e) => {
                    if (e.name !== 'AbortError') {
                        notify('error', e.message);
                        setLoader(false);
                        console.log('fail:', e.message)
                    }
                });
            }else{
                notify('warning', 'Please enter correct confirm password');
            }
            
        }else{
            notify('warning', 'Please fill the details');
        }
        
    } 

    const confirmPassMatch =() => {
        if(confirmPassword === '' || confirmPassword === newPassword){
            setConfirmPassNotMatch('');            
        }else{
            setConfirmPassNotMatch('Confirm Password Not Matched');            
        }
      };
    return (
        <>
        {
            loader ? 
            <Loader /> : ''
        }
            <div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
                            <div className='d-flex flex-column'>
                                <h2 className='card-h2'>Change Password</h2>
                            </div>
						</div>
					</div>
                    <div className='card-body'>
                    <div>
                        <div className="row m-2">
                            <div className="col-md-3 mt-4">
                                User Name: 
                            </div>
                            <div className="col-md-3">
                            <Input 
                                name="userName"
                                required
                                type="text" 
                                placeholder="UserName"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />                     
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-4">
                                Old Password: 
                            </div>
                            <div className="col-md-3">
                                <Input 
                                    name="oldPassword"
                                    required
                                    type="password" 
                                    placeholder="Old Password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />     
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-4">
                                New Password: 
                            </div>
                            <div className="col-md-3">
                                <Input 
                                    name="newPassword"
                                    required
                                    type="password" 
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    errorText={confirmPassNotMatch}
                                />     
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-4">
                                Confirm Password: 
                            </div>
                            <div className="col-md-3">
                                <Input 
                                    name="confirmPassword"
                                    required
                                    type="password" 
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    onBlur={confirmPassMatch}
                                    errorText={confirmPassNotMatch}
                                />   
                            </div>
                       </div>
                    </div>
                    <div className="row p-10px mb-3 pt-4">
                    <div className="col-md-6 text-right">
                        <button 
                            type="button" 
                            className="btn btn-sm btn-primary-custom" 
                            onClick={login}
                        >
                           Update</button>
                    </div>
            </div>                      
					</div>
				</div>
				
			</div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
    </>
    );
}

export default ChangePassword;
