import React from "react";
import DatePicker from "react-datepicker";
import Input from "../../Common/Input";

const UpdateStatement =({
    setModalOpen, 
    updateInvoices, 
    handleUpdateStatement, 
    handleChange, 
    setUpdateInvoices})=>{
    
    // const getDDValues =(val)=>{
    //     const ddValues = val === 'remark' ? [{label:'Select', value: ''}, {label:'PENDING', value: 'PENDING'}] : [{label:'Select', value: ''},{label:'RECEIVED', value: 'RECEIVED'}];
    //     return ddValues;
    // }

    const ddValues = [{label:'Select', value: ''}, {label:'PENDING', value: 'PENDING'},{label:'RECEIVED', value: 'RECEIVED'}]

    return (
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer update-tds">
                <div className="titleCloseBtn">
                    <button onClick={() => {
                        setModalOpen(false);
                    }}>
                        X
                    </button>
                </div>
                <div className="invoice-box">
                    <form>
                        <div className="row m-2">
                            <div className="col-md-6 mt-2">
                                Remark: 
                            </div>
                            <div className="col-md-6">
                            <select 
                                value={updateInvoices.remarks}
                                name='remarks'
                                onChange={handleChange} 
                                className="form-control" 
                            >
                                {ddValues.map((option) => (
                                <option value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-6 mt-2">
                                Balance: 
                            </div>
                            <div className="col-md-6">
                                {/* <select 
                                    value={updateInvoices.balance}
                                    name='balance'
                                    onChange={handleChange} 
                                    className="form-control" 
                                >
                                    {getDDValues('balance').map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                    ))}
                                </select> */}
                                 <Input 
                                    name="balance" 
                                    value={updateInvoices.balance} 
                                    title="" 
                                    placeholder="Balance Amount" 
                                    onChange={handleChange}
                                />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-6 mt-2">
                                Payment Date: 
                            </div>
                            <div className="col-md-6">
                                <DatePicker 
                                    name='paymentDate'
                                    title=''
                                    selected={updateInvoices.paymentDate ? new Date(updateInvoices.paymentDate) : new Date()} 
                                    onChange={(d) => 
                                        setUpdateInvoices({
                                            ...updateInvoices, 
                                            paymentDate : new Date(d).toLocaleDateString() 
                                            })
                                        }
                                    placeholderText='Pay Date'
                                    className='form-control' 
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                       </div>
                    </form>
                </div>
                <div className="footer h-50">
                    <button onClick={() => {
                        setModalOpen(false);
                    }}>
                        Cancel
                    </button>
                    <button type="submit" onClick={handleUpdateStatement}>Update</button>
                </div>
            </div>
        </div>
    )
}
export default UpdateStatement;