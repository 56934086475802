// MainLayout.js
import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';

import '../components/Home/css/animate.css';
import '../components/Home/css/style.css';
import '../components/Home/css/media-css.css';
// import '../components/Home/css/hamburger-menu.css';

import Home from './Home';

const MainLayout = ({ children }) => {

  return (
    <div>
      {/* <header>Main Header</header> */}
      <main>
        <Outlet /> {/* This renders the matched child route */}
      </main>
      {/* <footer>Main Footer</footer> */}
    </div>
  );
};

export default MainLayout;
