import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import MUIDataTable from 'mui-datatables';
import { FaEdit, FaPrint, FaMinusCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InvoicePreview from './InvoicePreview';
import { Context } from '../../../context/InvoiceContext';
import Loader from '../../Common/Loader'
import ApiTypeAhead from '../../Common/ApiTypeAhead';
import ConfirmationPopup from './ConfirmationPopup';
import { dateFormatter, FYFormat } from '../../CommonJsFunc/CommonJsFunc';

const InvoiceReports = () => {
  const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    const [loader, setLoader] = useState(false);
    const [, , , setButtonType] = useContext(Context);
    const [invoices, setInvoices] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [invoiceReview, setInvoiceReview] = useState({});
    const [tableData, setTableData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [invoiceData, setInvoiceData] = useState([]);
    const [clientName, setClientName] = useState('');
    const [deletingInvoice, setDeletingInvoice] = useState('');
    const [confirmModalPopup, setConfirmModalPopup] = useState(false);

    const [month, setMonth] = useState('');
    
    const headers = [
        {
          name: 'Invoice No',
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: 'Client Name',
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: 'Total Tax',
          options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
              style: {
                textAlign: 'center',
              },
            }),
          },
        },
        {
          name: 'Total Invoice Amount',
          options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
              style: {
                textAlign: 'center',
              },
            }),
          },
        },
        {
            name: 'Invoice Date',
            options: {
              filter: true,
              sort: true,
            },
        },
        {
          name: 'Re-Print',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => (
                <button
                  type="button"
                  className="btn btn-link m-0 p-0 text-edit"
                  title="Edit"
                  onClick={(e) => handleSubmit(value)}
                >
                  <FaPrint />
                </button>
            ),
          },
        },
        {
          name: 'Edit',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => (
                <Link
                    key={value}
                    to={`/admin/newinvoice/${value.replace(/\//g, "_")}`}
                    >
                      <FaEdit />
                </Link>
            ),
          },
        },
        {
          name: 'Delete',
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => (
              <button
                type="button"
                className="btn btn-link m-0 p-0 color-r"
                title=""
                onClick={(e) => removeInvoice(value)}
              >
                <FaMinusCircle />
              </button>
            ),
          },
        }
      ];

      const handleRemove = () => {
        setLoader(true);
        setConfirmModalPopup(false);
        
        axios.get(`/api/Lookup/DeleteInvoice/${deletingInvoice.replace(/\//g, "_")}`)
            .then((response) => {
              if(response.data === "success"){
                notify('success', 'Successfully Invoice Removed');
                getInvoicReports();
              }
              else{
                  notify('error', "Something Went Wrong");
                  setLoader(false);
              }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                    setLoader(false);
                }
            });
    }
    const removeInvoice = (invoiceNumber) => {
      if(invoiceNumber !== ''){
        setDeletingInvoice(invoiceNumber);
        setConfirmModalPopup(true);
      }
    };

    const onChangeRowsPerPage = (rowsPerPagee) => {
      setRowsPerPage(rowsPerPagee);
    };

      const options = {
        filter: true,
        filterType: 'dropdown',
        rowsPerPage: 10,
        rowsPerPageOptions: [5, 10],
        onChangeRowsPerPage,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        downloadOptions: { filename: 'TGLExpressInvoiceReport.csv' },
        responsive: 'standard',
        onDownload: (buildHead, buildBody, columns, data) => {
          const formattedData = data.map((x) => {
            const description = x.data[6].eventDescription;
            x.data[5] = x.data[5] === '-' ? '' : x.data[5];
            x.data.pop();
            x.data.push(description);
            return (
              {
                index: x.index,
                data: x.data,
              }
            );
          });
          return (buildHead(columns) + buildBody(formattedData));
        },
      };  
    
    const yearFormat =()=> {
      const selectedMonth = new Date(month);
      if(month === '' || month === null) {
          // return new Date().getFullYear() +"-"+(parseInt(new Date().getFullYear()) + 1).toString().substring(2,4);        
          return FYFormat();
      }
      else{
        return FYFormat(selectedMonth);
          // return selectedMonth?.getFullYear() +"-"+(parseInt(selectedMonth?.getFullYear()) + 1).toString().substring(2,4);        
      }
    }  
    const getInvoicReports = () => {
        axios
        .get('/api/Lookup/GetInvoiceReports')
        .then((response) => {
            setInvoices(response.data);
            setLoader(false);
            setInvoiceData(response.data);
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
            }
            setLoader(false);
        });
    } 
    useEffect(() => {
        setLoader(true);
        getInvoicReports();
    }, []);

    const handleSubmit = (invoiceNumber) => {
        const data = invoices.filter((a)=>a.invoiceNumber === invoiceNumber);
        setModalOpen(true); 
        setInvoiceReview(data[0]);        
        setButtonType('Print');
      };

    useEffect(() => {
        const s = invoices.map((x) => (
          {
            invoiceNo: x.invoiceNumber,
            clientName: x.clientName,
            totalTax: parseFloat(x.items?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
            totalAmount: Math.round(parseFloat(x.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0))).toFixed(2),
            invoiceDate: dateFormatter(x.invoiceDate),
            rePrint: x.invoiceNumber,
            edit: x.invoiceNumber,
            delete: x.invoiceNumber,
          }
        ));
        setTableData(s.map(Object.values));
      }, [invoices]);  

      useEffect(() => {
        var monthlyInvoice = [];
        if(month !== '' && month !== null) {
          if(clientName !== ''){
              monthlyInvoice = invoiceData.filter((x) => 
                                new Date(x.invoiceDate).getMonth() === month?.getMonth() 
                                && new Date(x.invoiceDate).getFullYear() === month?.getFullYear()
                                && x.clientName === clientName
                                ).map((x) => (
                                {
                                  invoiceNo: x.invoiceNumber,
                                  clientName: x.clientName,
                                  totalTax: parseFloat(x.items?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                                  totalAmount: Math.round(parseFloat(x.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0))).toFixed(2),
                                  invoiceDate: dateFormatter(x.invoiceDate),
                                  rePrint: x.invoiceNumber,
                                  edit: x.invoiceNumber,
                                  delete: x.invoiceNumber,
                                }
                              ));            
          }
          else{
            monthlyInvoice = invoiceData.filter((x) => 
                              new Date(x.invoiceDate).getMonth() === month?.getMonth() 
                              && new Date(x.invoiceDate).getFullYear() === month?.getFullYear()
                              ).map((x) => (
                              {
                                invoiceNo: x.invoiceNumber,
                                clientName: x.clientName,
                                totalTax: parseFloat(x.items?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                                totalAmount: Math.round(parseFloat(x.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0))).toFixed(2),
                                invoiceDate: dateFormatter(x.invoiceDate),
                                rePrint: x.invoiceNumber,
                                edit: x.invoiceNumber,
                                delete: x.invoiceNumber,
                              }
                            ));   
          }
          setTableData(monthlyInvoice.map(Object.values));
        }
        else {
          if(clientName !== ''){
              monthlyInvoice = invoiceData.filter((x) => x.clientName === clientName ).map((x) => (
                              {
                                invoiceNo: x.invoiceNumber,
                                clientName: x.clientName,
                                totalTax: parseFloat(x.items?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                                totalAmount: Math.round(parseFloat(x.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0))).toFixed(2),
                                invoiceDate: dateFormatter(x.invoiceDate),
                                rePrint: x.invoiceNumber,
                                edit: x.invoiceNumber,
                                delete: x.invoiceNumber,
                              }
                            ));
          }
          else {
            monthlyInvoice = invoiceData.map((x) => (
                      {
                        invoiceNo: x.invoiceNumber,
                        clientName: x.clientName,
                        totalTax: parseFloat(x.items?.map((item) =>  parseFloat(item.gstAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                        totalAmount: Math.round(parseFloat(x.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0))).toFixed(2),
                        invoiceDate: dateFormatter(x.invoiceDate),
                        rePrint: x.invoiceNumber,
                        edit: x.invoiceNumber,
                        delete: x.invoiceNumber,
                      }
                    ));
          }
          setTableData(monthlyInvoice.map(Object.values));
        }
        
      }, [month]);
   
    useEffect(() => {
      if(clientName !== '') {
          const searchResult = invoiceData.filter((x) => x.clientName === clientName);
          setInvoices(searchResult);
      }
      else{
          setInvoices(invoiceData);
      }
    }, [clientName]);

    return (
        <>
        {confirmModalPopup && <ConfirmationPopup invoiceNumber={deletingInvoice} setConfirmModalPopup={setConfirmModalPopup} handleRemove={handleRemove} />}
         {modalOpen && <InvoicePreview setOpenModal={setModalOpen} invoice={invoiceReview} items={invoiceReview.items} />}
              {loader ? <Loader /> :
              (
              <>
              <div className='row pb-3'>
                            <div className='col-md-3 fs-5 fw-medium pt-2'>
                                {`Invoices - ${(month !== '' && month !== null) ? (month?.toLocaleString('default', { month: 'long' })) : 'Year '} ${yearFormat()}`}
                            </div>
                            <div className='col-md-3'>
                                <ApiTypeAhead
                                    name="clientName"
                                    title=''
                                    displayKey="clientName"
                                    query={clientName}
                                    placeholder="Enter Client Name to search"
                                    url="/api/Lookup/GetClientName"
                                    onChange={(e) => setClientName(e.target.value)}
                                    onBlur={(e) => setClientName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='col-md-3'>
                                <DatePicker 
                                    name="month"
                                    title='Select Month for Filter'
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    selected={month} 
                                    onChange={(d) => setMonth(d) }
                                    placeholderText='Please Select Monthly TDS'
                                    className='form-control '
                                />
                            </div>
                            <div className='col-md-3'>
                               
                            </div>
                        </div> 
                  
              <MUIDataTable
                data={tableData}
                columns={headers}
                options={options}
              /></>)}
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </>
    );
}

export default InvoiceReports;
