import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import './aboutus.css';

import aboutus from '../../images/aboutus-bg.jpg'

const AboutUsCard =()=>{

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
        <div className="aboutus">
            <div class="hero-wrap js-fullheight height contact">
                <div class="overlay"></div>
                <section class="contact" id="contact">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="heading mt-5 text-center">
                                    <h2>About
                                        <span> Us </span></h2>
                                        <br />
                                </div>
                                <div class="title">
                                    <p class="fs-5 mission" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">We would like to introduce ourselves as International Courier, Freight Forwarding & Customs Clearing Company for Import & Export consignments which include both Air and Ocean mode. We manage our operations professionally with skilled staff that are knowledgeable with customs regulations, procedures, documentation, import and export policy matters and license regulation.</p>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div>
                                    <img src={aboutus} style={{height:450, width: 450}} />
                                </div>
                            </div>
                        </div>
                        <div className="row pt-6">
                            <div className="col-md-4">
                            <h2 className="d-inline fs-wt text">01</h2>
                            <h5 className="d-inline fs-2 fw-bolder label">Our Process</h5>
                            <p class="fs-5 mission mt-5" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">Our service starts right from collection of cargo along with necessary documents, processing through customs, forwarding to the destination port, customs formalities at port & delivery to the consignee with best lead time & competitive rates.</p>
                            </div>
                            <div className="col-md-4">
                                <h2 className="d-inline fs-wt text">02</h2>
                                <h5 className="d-inline fs-2 fw-bolder label">Consolidation</h5>
                                <p class="fs-5 mission mt-5" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">In case of exports we ship the consignment both by International Courier shipments, Air commercial cargo and Sea (FCL/LCL) mode to the destination worldwide, our flawless documentation and quick completion of formalities will enable to reachdestination with best lead time & competitive cost.</p>
                            </div>
                            <div className="col-md-4">
                                <h2 className="d-inline fs-wt text">03</h2>
                                <h5 className="d-inline fs-2 fw-bolder label">Air and Ocean Freight</h5>
                                <p class="fs-5 mission mt-2" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">We request you kindly to give us an opportunity to handle the consignments and serve your organization. Please feel free to contact us any time for enquiries and we will be glad to attend to same immediately.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div> 
        </div>
        </>
    )
}

export default AboutUsCard;