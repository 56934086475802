import Header from "../Home/Header";
import Menu from "../Home/Menu";
import Footer from "../Home/Footer";
import Contact from "./Contact";

const ContactUs = () => {

    return (
        <>
            <Header />
            <Menu />
            <Contact />
            <Footer />
        </>
    );
}

export default ContactUs;