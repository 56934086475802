import React from 'react';
import '../../styles/loader.css';

const Loader = (props) => {
  return (
    <div className={`log-out-layer ${props.class}`}>
      <div className="loader">
        <div className="pre-loader">
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
      </div>
    </div>
  );
}

export default Loader;
