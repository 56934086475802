import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import {USDCents, RsPaise, EUROCents} from '../../CommonJsFunc/CommonJsFunc';
import { dateFormatter } from "../../CommonJsFunc/CommonJsFunc"; 

const FreightInvModalPopup = (props) =>{
    const {invoiceDetails, setInvoiceDetails, setPreviewModal, action, handleSubmit } = props;

    const data = invoiceDetails?.freightInvItems?.filter((a) => a.itemName !== '')
    const totalAmount = invoiceDetails?.freightInvItems?.map((a) => parseFloat(a.amountUSD)).reduce((prev, curr) => prev + curr, 0);
    const totalUSDRate = invoiceDetails?.freightInvItems?.map((a) => parseFloat(a.usdRate)).reduce((prev, curr) => prev + curr, 0);
    const componentRef = useRef();
   
    const generateInvoiceNumber = () => {
        axios
        .get('/api/Lookup/GenerateFINumber')
        .then((response) => {
            setInvoiceDetails({
              ...invoiceDetails,
              fiNumber: response.data,
              fiDate: dateFormatter(new Date())
            })
        })
        .catch((e) => {
        if (e.name !== 'AbortError') {
            console.log('fail:', e.message)
        }
        });
      }; 

    useEffect(() => {
        if(action === 'Save'){
           generateInvoiceNumber();
        }
     }, []);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const GetButtonType = () => {
        switch (action) {
            case 'Save':
            case 'Update':
                return (<button type="submit" onClick={handleSubmit}>{action}</button>);
            case 'Print':
                return <button type="submit" onClick={handlePrint}>{action}</button>;
            default:
            // code block
        }
    }
    const InWords = () => {
        switch (invoiceDetails.currency) {
            case 'Rs':
                return RsPaise(totalAmount.toFixed(2));
            case 'USD':
                return USDCents(totalAmount.toFixed(2));
            case 'EURO':
                return EUROCents(totalAmount.toFixed(2));
            default:
            // code block
        }
    }
    return  (
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button onClick={() => {
                        setPreviewModal(false);
                    }}>
                        X
                    </button>
                </div>
                <div className="invoice-box" ref={componentRef}>
                    <form>
                        <table className='bg-aliceblue'>
                            <tr>
                                <td colSpan="2" className="t-a-c border-2 bg-c-head">
                                    <strong>FREIGHT INVOICE</strong>                                    
                                </td>
                            </tr>
                            <tr>
                                <td className="border-2">
                                    <strong>INV. Ref. No: {invoiceDetails.fiNumber} </strong>                                    
                                </td>
                                <td className="border-2">
                                    <strong>Date: </strong>{invoiceDetails.fiDate}                                    
                                </td>
                            </tr>
                            <tr>
                                <td className='border-2'>
                                    <div><strong className='text-decoration-underline'>Debit To,</strong></div>
                                    <div className=''>
                                        <div className='fs-5 p-10px'>
                                            <strong>{invoiceDetails.clientName}</strong><br />
                                        </div>
                                        <div className='fs-6 m-auto p-10px'>
                                            {invoiceDetails.billingAddress}<br />
                                            {invoiceDetails.billingAddressLine}<br />
                                            {invoiceDetails.billingCity}<br />
                                            {invoiceDetails.billingStateName}<br />
                                            <strong>GSTIN: </strong>{invoiceDetails.billingGSTIN}<br />
                                        </div>
                                    </div>        
                                </td>
                                <td rowSpan="2" className='border-2'>
                                    <div className="t-a-c">
                                        <strong className='text-decoration-underline t-a-c'>Shipment Details</strong>
                                    </div>
                                    <div className="mt-5 t-a-c">
                                        <strong>CONTAINER TYPE : {invoiceDetails.shipmentDetails}</strong>
                                    </div>
                                   
                                </td>
                            </tr>
                            <tr>
                                <td className='border-2'>
                                    <div className=''>
                                        <div className='fs-5 p-10px'>
                                            <strong>TGL EXPRESS INDIA PRIVATE LIMITED</strong><br />
                                        </div>
                                        <div className='fs-6 m-auto p-10px'>
                                            NO.79, GROUND FLOOR, 2ND MAIN,<br />
                                            MS RAMAIAH ENCLAVE, 8TH MILE,<br />
                                            NAGASANDRA POST, BENGALURU - 560073.<br />
                                            GSTN : 29AAJCT3309H1Z6.<br />
                                        </div>
                                    </div>        
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='border-2 '>
                                    <strong>EXPORTER INVOICE NO.: {invoiceDetails.expInvoiceNo} / DT.: {dateFormatter(invoiceDetails.expDate)}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='border-2 '>
                                    <strong>Description of goods: </strong>{invoiceDetails.description}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='border-2'>
                                    <table className='t-a-c'>
                                            <thead>
                                                <tr className="bg-c-head">
                                                    <th className='border-2 w-5'>S.No.</th>
                                                    <th className='border-2 w-45'>Particulers</th>
                                                    <th className='border-2 w-10'>Details</th>
                                                    <th className='border-2 w-10'>Rate {invoiceDetails.currency}</th>
                                                    <th className='border-2 w-10'>Amount({invoiceDetails.currency})</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {data?.map((item, idx) => 
                                                (<tr className="border-2" key={idx}>
                                                        <td className="border-2">{idx + 1}</td>
                                                        <td className="border-2 t-a-l">{item.itemName}</td>                                        
                                                        <td className="border-2">{item.details}</td>
                                                        <td className="border-2">{item.usdRate.toFixed(2)}</td>
                                                        <td className="border-2">{item.amountUSD.toFixed(2)}</td>
                                                    </tr>))
                                            }
                                                <tr>
                                                    <td colSpan="2" className="border-2">SUB TOTAL</td>
                                                    <td className='border-2'></td>
                                                    <td className="border-2 w-20">{totalUSDRate.toFixed(2)}</td>
                                                    <td className="border-2 w-10">{totalAmount.toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr className="border-2 fw-bold text-lg-end">
                                <td colSpan="2">Total Amount: {totalAmount.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="currancyText border-2">
                                    <div>
                                        <strong>In Words: </strong>{InWords()} Only.
                                    </div>                                    
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="currancyText border-2">
                                    <div>
                                        <strong>Please do payment favor of M/S.TGL EXPRESS INDIA PRIVATE LIMITED, Payable at Bangalore</strong>
                                    </div>                                    
                                </td>
                            </tr>								
                            <tr className="border-2">
                                <td colSpan="2" className="border-2">
                                    <div className="row">
                                        <div className="col-md-8"><strong>OUR BANK: State Bank of India</strong></div>
                                        <div className="col-md-4"><strong>PAN: AAJCT3309H</strong></div>
                                    </div>                                    
                                    <div className="col-md-4"><strong>ACCOUNT HOLDER NAME: TGL EXPRESS INDIA PRIVATE LIMITED.</strong></div>
                                    <div className="col-md-4"><strong>A/C: 43240724485</strong></div>
                                    <div className="col-md-4"><strong>IFSC: SBIN0017736</strong></div>
                                    <div className="col-md-4"><strong>BRANCH:  MALLESHWARAM</strong></div>
                                </td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="2" className="border-2 text-lg-center">
                                    <div>
                                        <strong>This is computer generated invoice, seal and sign not required.</strong>
                                    </div>
                                    
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div className="footer">
                    <button onClick={() => {
                        setPreviewModal(false);
                    }}>
                        Cancel
                    </button>
                    {GetButtonType()}
                </div>
            </div>
        </div>
    )
}

export default FreightInvModalPopup;