import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Context } from "../../context/MenuContext";

import { AuthContext } from "../../context/AuthContext";

const Menu =({ scrollToSection })=>{
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const [, , , , mainMenu, setMainMenu] = useContext(Context);
    const { user, logout } = useContext(AuthContext);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const onServiceMenuClick = () => {
        // navigate('/');
        setMainMenu('home');
        scrollToSection('Services'); 
    }

    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
            <div className="container">
            <div className="logo">
                <a className="navbar-brand" href="#">
                <img className="img-fluid-logo logo-img" alt="InnovationEngine" />
                </a>
            </div>
            
            <button className="navbar-toggler" type="button" onClick={handleToggle} data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="oi oi-menu"></span> 
                <i className="fa fa-bars"></i>
            </button>
           
            <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="ftco-nav">
                <ul className="navbar-nav ml-auto">
                <li className={`nav-item ${mainMenu === 'home' ? 'active' : ''}`}>
                    <Link to="/" className="nav-link" onClick={() => setMainMenu('home')}> 
                        Home
                    </Link>
                </li>
                <li className={`nav-item ${mainMenu === 'aboutUs' ? 'active' : ''}`}>
                    <Link to="/aboutUs" className="nav-link" onClick={() => setMainMenu('aboutUs')}> 
                        About
                    </Link>
                </li>
                <li className={`nav-item ${mainMenu === 'getRates' ? 'active' : ''}`}>
                    <Link to="/getRates" className="nav-link" onClick={() => setMainMenu('getRates')}> 
                    Get Rate
                    </Link>
                </li>
                {
                    mainMenu === 'home' ?
                    <li className={`nav-item ${mainMenu === 'home' ? 'active' : ''}`}>
                        {/* <Link to="/services" className="nav-link" data-abc="true"> 
                        Services
                        </Link> */}
                        <button onClick={onServiceMenuClick} className="nav-link" >
                        Services
                        </button>
                    </li> : ''

                }
                
                <li className={`nav-item ${mainMenu === 'contactUs' ? 'active' : ''}`}>
                    <Link to="/contactUs" className="nav-link" onClick={() => setMainMenu('contactUs')}> 
                    Contact us
                    </Link>
                </li>
                {
                    isOpen ?
                    (user ? 
                    <li className={`nav-item ${mainMenu === 'contactUs' ? 'active' : ''}`}>
                        <Link to="/admin/signIn" className="nav-link" onClick={logout} > 
                            Log Out
                        </Link>
                    </li> : 
                    <li className={`nav-item ${mainMenu === 'contactUs' ? 'active' : ''}`}>
                        <Link to="/admin/signIn" className="nav-link"> 
                            Log In
                        </Link>
                    </li>) : ''
                }
                </ul>
            </div>
            </div>
            </nav>
        </>
    )
}

export default Menu;