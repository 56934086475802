import React, { useContext } from "react";
import { Link } from "react-router-dom";


import { AuthContext } from "../../context/AuthContext";

const Header = () => {

    const { user, logout } = useContext(AuthContext);

    return (
        <>
        <div class="wrap">
            <div class="container">
                <div class="row justify-content-between">
                <div class="col-12 col-md d-flex align-items-center">
                <p class="mb-0 phone"><span class="mailus">Phone no:</span>
                    <a href="#">(+91)9663034113</a>
                    {' '} or <span class="mailus">email us:</span> <a href="mailto:info@tglexpressindia.com">info@tglexpressindia.com</a></p>
                </div>
                <div class="col-12 col-md d-flex justify-content-md-end">
                <div class="social-media">
                    <p class="mb-0 d-flex">
                    <a href="https://www.facebook.com/p/TGL-Express-India-Private-Limited-100084868929711/?locale=en_GB" target="_blank" class="d-flex align-items-center justify-content-center"><span class="fa fa-facebook"><i class="sr-only">Facebook</i></span></a>
                    <a href="#" class="d-flex align-items-center justify-content-center"><span class="fa fa-linkedin"><i class="sr-only">LinkedIn</i></span></a>
                    <a href="#" class="d-flex align-items-center justify-content-center"><span class="fa fa-twitter"><i class="sr-only">Twitter</i></span></a>
                    <a href="https://api.whatsapp.com/send?phone=919663034113" class="d-flex align-items-center justify-content-center" target="_blank"><span class="fa fa-whatsapp"><i class="sr-only">Whatsapp</i></span></a>
                    {
                        user ?
                        <Link to="/" onClick={logout} class="d-flex align-items-center justify-content-center" data-abc="true"> 
                            <span class="fa fa-sign-out"><i class="sr-only">LogOut</i></span>
                        </Link>
                        :
                        <Link to="/admin/signIn" class="d-flex align-items-center justify-content-center" data-abc="true"> 
                            <span class="fa fa-user"><i class="sr-only">Login</i></span>
                        </Link>

                    }
                    
                </p>
                </div>
            </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default Header;