import React, { useEffect, useContext, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../images/logo.png';
import QRCode from '../../../images/TGLQRCode.jpeg';
import { Context } from '../../../context/InvoiceContext';
import '../../../styles/print.css';
import { dateFormatter } from '../../CommonJsFunc/CommonJsFunc';
import { RsPaise } from '../../CommonJsFunc/CommonJsFunc';

function InvoicePreview({ setOpenModal, invoice, items, Submit }) {

    // const [title, setTitle] = useState(invoice.invoiceNumber);
    const [, , buttonType] = useContext(Context);
    const componentRef = useRef();
    const totalAmount = items.map((a) => parseFloat(a.total)).reduce((prev, curr) => prev + curr, 0);
    const nonTaxableAmount = items.filter((a) => a.gst === 0).map((a) => parseFloat(a.total)).reduce((prev, curr) => prev + curr, 0);
    const totalRate = items.map((a) => parseFloat(a.rate)).reduce((prev, curr) => prev + curr, 0);
    const tax = items.map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0);
    const data = items.filter((a) => a.itemName !== '');
    var invoiceDate = invoice.invoiceDate === undefined ? new Date() : new Date(invoice.invoiceDate);
    
    const toIndianCurrency = (num) => {
        return parseFloat(num).toFixed(2);
    };
    const GetButtonType = () => {
        switch (buttonType) {
            case 'Save':
            case 'Update':
                return (<button type="submit" onClick={Submit}>{buttonType}</button>);
            case 'Print':
                return <button type="submit" onClick={handlePrint}>{buttonType}</button>;
            default:
            // code block
        }

    }
   
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: invoice.invoiceNumber,
    });
    var netAmount = toIndianCurrency(parseFloat(totalAmount)-parseFloat(!isNaN(invoice.advanceDeposite) ? invoice.advanceDeposite : 0));
    return (
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button onClick={() => {
                        setOpenModal(false);
                    }}>
                        X
                    </button>
                </div>
                <div className="invoice-box" ref={componentRef}>
                    <form>
                        <table className='bg-aliceblue'>
                            <tr className="top border-2">
                                <td colSpan="9" className='border-2'>
                                    <div className='d-flex'>
                                        <table>
                                            <tr>
                                                <td className=''>
                                                    <div id="brandingInvoice" className=''>
                                                        <img src={Logo} alt="Company Logo" className="logo" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='m-l-15'>
                                                        <div id="brandingInvoice">
                                                            <h5 className="c-head">TGL EXPRESS INDIA PRIVATE LIMITED</h5>
                                                        </div>
                                                        <div className="invoice-subheading c-subhead">
                                                            <span className="">No.79, GROUND FLOOR, 2ND MAIN, M.S RAMAIYAH ENCLAVE</span><br />
                                                            <span className="m-4">8TH MILE, NAGASANDRA POST, BENGALURU - 560073</span><br />
                                                            <span className="m-4">GST No. 29AAJCT3309H1Z6, info@tglexpressindia.com</span>
                                                        </div>
                                                    </div>    
                                                </td>
                                            </tr>
                                        </table>
                                        <div id="brandingInvoice">
                                            <img src={QRCode} alt="Company Logo" className="qrcode" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className='bg-c-head'>
                                <td colSpan="3" className='border-2'>
                                <strong>Invoice No. : {invoice.invoiceNumber}</strong>
                                </td>
                                <td colSpan="3" className='border-2 text-lg-center'>
                                <strong>TAX INVOICE</strong>
                                </td>
                                <td colSpan="3" className='border-2 text-lg-center'>
                                <strong>Invoice Date:{dateFormatter(invoiceDate)}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9" className='border-2'>
                                    <table className=''>
                                        <tr className='border-2'>
                                            <td rowSpan="8" className='border-2 w-60'>
                                                <div><strong className='text-decoration-underline'>Billing Address,</strong></div>
                                                <div className='m-4'>
                                                    <div className='fs-5 p-10px'>
                                                        <strong>M/s. {invoice.clientName}</strong><br />
                                                    </div>
                                                    <div className='fs-6 m-auto p-10px m-l-45'>
                                                        {invoice.clientAddress}<br />
                                                        {invoice.clientAddressLine}<br />
                                                        {invoice.stateName}<br />
                                                    </div>
                                                    <div className='fs-6 m-auto pt-5'>
                                                    <strong>GSTIN : {invoice.clientGSTNo}</strong>    
                                                    </div>
                                                </div>                                
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td className='border-2 w-20'>
                                                <strong>SB/BOE NO: </strong>
                                            </td>
                                            <td className='border-2 w-20'>
                                                {invoice.sB_BOENo}
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td className='border-2 w-20'>
                                                <strong>Dated: </strong>
                                            </td>
                                            <td className='border-2 w-20'>
                                                {dateFormatter(invoice.sB_BOENoDate)}
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td className='border-2 w-20'>
                                                <strong>MBL/MAWB NO: </strong>
                                            </td>
                                            <td className='border-2 w-20'>
                                                {invoice.mbL_MAWBNo}
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td className='border-2 w-20'>
                                                <strong>Dated: </strong>
                                            </td>
                                            <td className='border-2 w-20'>
                                                {dateFormatter(invoice.mbL_MAWBNoDate)}
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td className='border-2 w-20'>
                                                <strong>HBL/HAWB NO: </strong>
                                            </td>
                                            <td className='border-2 w-20'>
                                                {invoice.bhL_HAWBNo}
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td className='border-2 w-20'>
                                                <strong>Dated: </strong>
                                            </td>
                                            <td className='border-2 w-20'>
                                                {dateFormatter(invoice.bhL_HAWBNoDate)}
                                            </td>
                                        </tr>
                                        <tr className='border-2  w-20'>
                                            <td colSpan="2" className='border-2'>
                                                <strong>Package: </strong>{invoice.package} &nbsp;&nbsp;&nbsp;&nbsp; 
                                                | <strong>Weight: </strong>{invoice.weight}
                                                | <strong>Ch Wt: </strong>{invoice.chargeableWt}
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td rowSpan="3" className='border-2 w-60'>
                                            <strong>Shipper/Consignee: </strong> {invoice.shipper}<br />
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td className='border-2 w-20'>
                                                <strong>Container No: </strong>
                                            </td>
                                            <td className='border-2 w-20'>
                                                {invoice.containerNo}
                                            </td>
                                        </tr>
                                        <tr className='border-2'>
                                            <td className='border-2 w-20'>
                                                <strong>Exchange Rate: </strong>
                                            </td>
                                            <td className='border-2 w-20'>
                                                {`${invoice.exchangeCurr}. ${invoice.exchangeRate}`}
                                            </td>
                                        </tr> 
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9" className='border-2 '>
                                    <strong>Invoice No. & DT: </strong>{invoice.shippedInvoiceNo} / {dateFormatter(invoice.shippedInvoiceDate)}<br />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9" className='border-2'>
                                    <strong>Description: </strong> {invoice.invoiceDescription}                               
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="9" className='border-2'>
                                    <table className=' t-a-c' style={{tableLayout: "fixed"}}>
                                            {
                                                invoice.stateId == 16 ?
                                                (
                                                <>
                                                <thead className='bg-c-head'>
                                                    <tr className="">
                                                        <th rowSpan="2" className='border-2' width='5%'>Sl.No.</th>
                                                        <th rowSpan="2" className='border-2' width='10%'>SAC Code</th>
                                                        <th rowSpan="2" className='border-2' width='25%'>Particulers</th>
                                                        <th rowSpan="2" className='border-2' width='10%'>Amount</th>
                                                        <th colSpan="2" className='border-2' width='20%'>CGST</th>
                                                        <th colSpan="2" className='border-2' width='20%'>SGST</th>
                                                        <th rowSpan="2" className='border-2' width='10%'>Total (₹)</th>
                                                    </tr>
                                                    <tr className="">
                                                        <th className='border-2' width='25%'>Rate %</th>
                                                        <th className='border-2' width='25%'>Amount</th>
                                                        <th className='border-2' width='25%'>Rate %</th>
                                                        <th className='border-2' width='25%'>Amount</th>
                                                    </tr>
                                                    </thead>    
                                                </>
                                                ) :(
                                                <>
                                                    <tr className="bg-c-head">
                                                        <th className='border-2' width='5%'>Sl.No.</th>
                                                        <th className='border-2' width='10%'>SAC Code</th>
                                                        <th className='border-2' width='45%'>Particulers</th>
                                                        <th className='border-2' width='10%'>Amount</th>
                                                        <th className='border-2' width='10%'>IGST</th>
                                                        <th className='border-2' width='10%'>IGST Amount</th>
                                                        <th className='border-2' width='10%'>Total (₹)</th>
                                                    </tr>
                                                </>
                                                )
                                            }
                                            {data.map((item, idx) => (invoice.stateId == 16 ?
                                                (
                                                <tr className="border-2" key={idx}>
                                                        <td className="border-2">{idx + 1}</td>
                                                        <td className="border-2">{item.code}</td>
                                                        <td className="border-2 t-a-l">{item.itemName}</td>                                        
                                                        <td className="border-2">{toIndianCurrency(item.rate)}</td>
                                                        <td className="border-2">{item?.gst / 2}%</td>
                                                        <td className="border-2">{toIndianCurrency(item.gstAmount / 2)}</td>
                                                        <td className="border-2">{item?.gst / 2}%</td>
                                                        <td className="border-2">{toIndianCurrency(item.gstAmount / 2)}</td>
                                                        <td className="border-2">{toIndianCurrency(item.total)}</td>
                                                    </tr>
                                                ):(
                                                <tr className="border-2" key={idx}>
                                                        <td className="border-2">{idx + 1}</td>
                                                        <td className="border-2">{item.code}</td>
                                                        <td className="border-2 t-a-l">{item.itemName}</td>                                        
                                                        <td className="border-2">{toIndianCurrency(item.rate)}</td>
                                                        <td className="border-2">{item.gst}%</td>
                                                        <td className="border-2">{toIndianCurrency(item.gstAmount)}</td>
                                                        <td className="border-2">{toIndianCurrency(item.total)}</td>
                                                    </tr>
                                                )
                                                ))
                                            }
                                            {invoice.stateId == 16 ?
                                            (                                            
                                            <tr>
                                                <td colspan="3" className="border-2 w-48">SUB TOTAL</td>
                                                <td className="border-2 w-10">{toIndianCurrency(totalRate)}</td>
                                                <td className='border-2'></td>
                                                <td className="border-2 w-16">{toIndianCurrency(tax / 2)}</td>
                                                <td className='border-2'></td> 
                                                <td className="border-2 w-16">{toIndianCurrency(tax / 2)}</td>
                                                <td className="border-2 w-10">{toIndianCurrency(totalAmount)}</td>
                                            </tr>
                                            ):
                                            (
                                            <tr>
                                                <td colspan="3" className="border-2">SUB TOTAL</td>
                                                <td className="border-2 w-10">{toIndianCurrency(totalRate)}</td>
                                                <td className='border-2'></td>
                                                <td className="border-2 w-20">{toIndianCurrency(tax)}</td>
                                                <td className="border-2 w-10">{toIndianCurrency(totalAmount)}</td>
                                            </tr>
                                            )                                        
                                        }
                                    </table>
                                </td>
                            </tr>							
                            <tr>
                                <td rowSpan={invoice.stateId == 16 ? "8" : "6"} colSpan="7" className="currancyText border-2">
                                    <div>
                                        <strong>Non Taxable Amount: Rs. {toIndianCurrency(Math.round(nonTaxableAmount))}</strong>
                                    </div>  
                                    <div>
                                        <strong>In Words: </strong>{RsPaise(Math.round(totalAmount).toFixed(2))} Only.
                                    </div>                                    
                                </td>
                            </tr>
							{
							invoice.stateId == 16 ?
							(
							<>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="2">CGST @ 9%: {toIndianCurrency(items.filter((a) => a.gst == 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)}</td>
                                </tr>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="2">SGST @ 9%: {toIndianCurrency(items.filter((a) => a.gst == 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)}</td>
                                </tr>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="2">CGST @ 2.5%: {items.filter(a=>a?.gst==5).length == 0 ? '' : toIndianCurrency(items.filter((a) => a.gst == 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)}</td>
                                </tr>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="2">SGST @ 2.5%: {items.filter(a=>a?.gst==5).length == 0 ? '' : toIndianCurrency(items.filter((a) => a.gst == 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)}</td>
                                </tr>
							</>
							
							) :(
                            <>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="2">IGST @ 18%: {toIndianCurrency(items.filter((a) => a.gst == 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0))}</td>
                                </tr>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="2">IGST @ 5%: {toIndianCurrency(items.filter((a) => a.gst == 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0))}</td>
                                </tr>
                            </>
							)}
                            <tr className="border-2 fw-bold text-lg-end">
                                <td colSpan="2">Total Invoice Amount: {toIndianCurrency(Math.round(totalAmount))}</td>
                            </tr>
                            <tr className="border-2 fw-bold text-lg-end">
                                <td colSpan="2">Advance: {toIndianCurrency(parseFloat(invoice.advanceDeposite))}</td>
                            </tr>
                            <tr className="border-2 fw-bold text-lg-end">
                                <td colSpan="2">Net Amount: {netAmount == ' ₹NaN' ? 0 : toIndianCurrency(Math.round(netAmount))}</td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="9" className='border-2'>
                                    <table>
                                        <tr>
                                            <td>
                                                <ul>
                                                    <li>Subject to Bangalore Jurisdiction.</li>
                                                    <li>Overdue intrest will be charged @25% p.a after 30 days.</li>
                                                    <li>Subject to your paying short collection of custom duty under section 28(i) of the CA 1962.</li>
                                                    <li>Cheque / DD should be made out of M/s TGL EXPRESS INDIA PRIVATE LIMITED.</li>
                                                </ul>
                                            </td>
                                           
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="3" className="border-2">OUR BANK: State Bank of India</td>
                                <td colSpan="3" className="border-2">A/C : 43240724485</td>
                                <td colSpan="3" className="border-2">IFSC : SBIN0017736</td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="3" className="border-2">
                                    PAN: AAJCT3309H
                                </td>
                                <td colSpan="6" className="border-2 text-lg-center">
                                    <div>
                                        <strong>This is computer generated invoice, seal and sign not required.</strong>
                                    </div>
                                    
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div className="footer">
                    <button onClick={() => {
                        setOpenModal(false);
                    }}>
                        Cancel
                    </button>
                    {GetButtonType()}
                </div>
            </div>
        </div>
    );
}

export default InvoicePreview;
