import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Notifications = () => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<>
			<div className='user-notifications' onClick={handleShow} >
				<svg stroke="#7E8299" fill="#7E8299" strokeWidth="0" viewBox="0 0 448 512" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z"></path></svg>
				<span className='notification-count-active' />
			</div>

			<Offcanvas show={show} onHide={handleClose} placement="end" className='notification-offcanvas'>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Notifications</Offcanvas.Title>
				</Offcanvas.Header>
				<hr className='m-0' />
				<Offcanvas.Body className='p-0'>
					<div className='notifications-lists'>
						<div className='notification-flex unread-notification'>
							<div className='notification-icon'>
								<svg stroke="#a8a8a8" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><path d="m12 8-9.04 9.06a2.82 2.82 0 1 0 3.98 3.98L16 12"></path><circle cx="17" cy="7" r="5"></circle></svg>
							</div>
							<div className='notification-desc'>
								<div className='text-desc'>Invoice INV-0000 has been imported</div>
								<div className='text-date'>01/03/2022 10:56 PM</div>
							</div>
						</div>
						<div className='notification-flex'>
							<div className='notification-icon'>
								<svg stroke="#a8a8a8" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><path d="m12 8-9.04 9.06a2.82 2.82 0 1 0 3.98 3.98L16 12"></path><circle cx="17" cy="7" r="5"></circle></svg>
							</div>
							<div className='notification-desc'>
								<div className='text-desc'>The timer started for the task task 10 for Project has reached the maximum limit for today (24:00).</div>
								<div className='text-date'>01/03/2022 10:56 PM</div>
							</div>
						</div>
						<div className='notification-flex'>
							<div className='notification-icon'>
								<svg stroke="#a8a8a8" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><path d="m12 8-9.04 9.06a2.82 2.82 0 1 0 3.98 3.98L16 12"></path><circle cx="17" cy="7" r="5"></circle></svg>
							</div>
							<div className='notification-desc'>
								<div className='text-desc'>Invoice INV-0000 has been imported</div>
								<div className='text-date'>01/03/2022 10:56 PM</div>
							</div>
						</div>
						<div className='notification-flex unread-notification'>
							<div className='notification-icon'>
								<svg stroke="#a8a8a8" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><path d="m12 8-9.04 9.06a2.82 2.82 0 1 0 3.98 3.98L16 12"></path><circle cx="17" cy="7" r="5"></circle></svg>
							</div>
							<div className='notification-desc'>
								<div className='text-desc'>The timer started for the task task 10 for Project has reached the maximum limit for today (24:00).</div>
								<div className='text-date'>01/03/2022 10:56 PM</div>
							</div>
						</div>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	)
}

export default Notifications