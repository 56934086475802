import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import RadioButtons from "../Common/RadioButtons";
import Loader from "../Common/Loader";


import { Context as MenuContext } from '../../context/MenuContext';

const RateFileUpload = () => {

    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(MenuContext);

    const [selectedFile, setSelectedFile] = useState(null);
    const [category, setCategory] = useState(null);
    const [company, setCompany] = useState('');
    const [loader, setLoader] = useState(false);
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    
    useEffect(() =>{
        setSelectedMenu('rate');
		setSelectedSubMenu('rateFileUpload');
    },[]);

    const companies = [
        {label:'Select Company', value: '0'},
        {label:'DHL', value: 'DHL'},
        {label:'FedEx', value: 'FedEx'},
        {label:'UPS', value: 'UPS'}];

    const SaveFile = async (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        // setFileName(file.name);
      };

    const UploadFile = () => {
        const formData = new FormData();
        formData.append('formFile', selectedFile);
        formData.append('company', company);
        formData.append('category', category);
        setLoader(true);
        var url = '/api/Booking/UploadFile';
        const method = 'post';
        axios({
            method,
            url: url,
            data: formData,
            headers: {  'Content-Type':'multipart/form-data' },
            })
            .then((response) => {
                if(response.data === "uploaded"){
                    setLoader(false);
                    notify('success',`Successfully File Uploaded`);
                }
            })
            .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
                setLoader(false);
                notify('error', e.message);
            }
            });
      };

    return (
        <>
        {loader ? <Loader /> : ''}
        <div className='custom-container'>				
            <div className='card mt-5'>
                <div className='card-header'>
                    <div className='card-title m-0'>
                        <div className='d-flex flex-column'>
                            <h2 className='card-h2'>Countries Rate File Upload</h2>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="row p-3">
                        <div className="col-md-2 mt-4">
                            Select Category
                        </div>
                        <div className="col-md-4">
                            <RadioButtons
                                name="category"
                                value={category}
                                inline
                                options={{ Export: 'EXPORT', Import: 'IMPORT' }}
                                onChange={(e) => setCategory(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className="row p-3">
                        <div className="col-md-2 mt-2">Select Company</div>
                        <div className="col-md-4">
                            <select 
                                value={company}
                                name='company'
                                onChange={(e) => setCompany(e.target.value)} 
                                className="form-control" 
                            >
                                {companies?.map((option) => (
                                <option value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="modal-body">
                        <form>
                            <label className="file-upload">
                                <input 
                                    type="file" 
                                    onChange={SaveFile} 
                                    id="fileUpload" 
                                    accept=".csv" 
                                />
                                <p><FontAwesomeIcon icon={faCloudUploadAlt} style={{ fontSize: '50px' }} /></p>
                                <p className="lead mb-0">
                                Drag and drop or Browse file to upload
                                </p>
                            </label>
                        </form>
                    </div>
                    <div className="row p-10px mb-3 pt-4">
                        <div className="col-md-12">
                            <div className="t-a-c">
                                <button 
                                    type="button" 
                                    className="form-control btn btn-sm btn-primary-custom w-10" 
                                    onClick={UploadFile}
                                    >Upload File
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
            <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        /></>
    )
}
export default RateFileUpload;