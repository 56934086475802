import React, { useContext, useState } from "react";
import Input from "../Common/Input";

import { Context } from "../../context/RateContext";

const Dimension = (props) =>{
    const { apiCall } = props;
    const [measurement, setMeasurement] = useContext(Context);
    const [pkgCount, setpkgCount] = useState(0);
    const [multiParcelWt, setMultiParcelWt] = useState(0);
    // const [dimAdded, setDimAdded] = useState(false);

    const handleChange = (e) => {
        const { target } = e;
        const {
            name, value, type
        } = target; 
        setMeasurement((v) => {
            return {
                ...v,
                [name]: value
              };
        });
    }
    function calculateWeight() {
        let wt = 0;
        if(pkgCount > 1)
        {
            wt = multiParcelWt/5000;
        }
        else{
            wt = (measurement.length * measurement.width * measurement.height)/5000
        }
        if(measurement.weight <= wt)
        {
            apiCall(wt);
        }
    }
    function addNumberOfPacket() {
        const wt = multiParcelWt + (parseFloat(measurement.length) * parseFloat(measurement.width) * parseFloat(measurement.height)) 
        setMultiParcelWt(wt);
        setpkgCount(pkgCount+1)
        const dim = {
            'length': parseFloat(measurement.length),
            'width': parseFloat(measurement.width),
            'height': parseFloat(measurement.height),
         }
        // setDimAdded(true);
        setMeasurement((v) => {
            return {
                ...v,
                // length: 0,
                // width:0,
                // height:0,
                pkgCount: pkgCount + 1,
                dimensions: [...measurement.dimensions, dim],
              };
        });
    }
    return(
        <>
        <div className="dimension-card">
        <table class="table w-30">
                <thead>
                    <tr>
                        <th scope="col">Length(cm)</th>
                        <th scope="col">Width(cm)</th>
                        <th scope="col">Height(cm)</th>
                        <th scope="col">Counts</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Input 
                                name="length"
                                required
                                type="text" 
                                placeholder="Length"
                                value={measurement.length}
                                onChange={(e) => handleChange(e)}
                            />
                        </td>
                        <td>
                            <Input 
                                name="width"
                                required
                                type="text" 
                                placeholder="Width"
                                value={measurement.width}
                                onChange={(e) => handleChange(e)}
                            />
                        </td>
                        <td>
                            <Input 
                                name="height"
                                required
                                type="text" 
                                placeholder="Height"
                                value={measurement.height}
                                onChange={(e) => handleChange(e)}
                            />
                        </td>
                        <td></td>
                    </tr>
                    {
                        measurement?.dimensions?.map((dim, idx) => {
                           return  (<tr>
                                <td className="t-a-c">{dim.length}</td>
                                <td className="t-a-c">{dim.width}</td>
                                <td className="t-a-c">{dim.height}</td>
                                <td className="t-a-c">{`Box ${idx + 1}`}</td>
                            </tr>)
                        })
                    }
                </tbody>
            </table>
            <div className="t-a-c">
                <button 
                    type="button" 
                    className="form-control btn btn-sm btn-primary-custom wt-100" 
                    onClick={addNumberOfPacket}
                    >{`+${pkgCount} Plus`}
                </button>
            </div>
            {
                pkgCount > 0 ? 
                <div className="t-a-c warning">
                    <span>Please Click on Calculate Volumetric Weight, after added dimentions</span>
                </div> : ''
            }            
            <div className="row p-10px mb-3 pt-4">
                <div className="col-md-12 sidebar-nav-link">
                    <div className="t-a-c">
                        <button 
                            type="button" 
                            className="form-control btn btn-sm btn-primary-custom wt-100" 
                            onClick={calculateWeight}
                            >Calculate Volumetric Weight
                        </button>
                    </div>
                    <div className="p-4">
                        <strong>Weight: </strong> 
                        {' '}
                        {' '}
                        <span>{measurement.weight}</span>
                    </div>
                   
                </div>
            </div>
            <hr className="border-2 w-300" />
        </div>
        </>
    )
}

export default Dimension;