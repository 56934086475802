import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropdown from '../Common/Dropdown';
import Input from "../Common/Input";
import Loader from "../Common/Loader";

import { Context } from '../../context/RateContext';
import { Context as MenuContext } from '../../context/MenuContext';

const AddFuelSurcharge = () =>{
    const [measurement, setMeasurement] =  useContext(Context);
    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(MenuContext);

    const [company, setCompany] = useState('');
    const [fuelSurcharge, setFuelSurcharge] = useState(0);
    const [loader, setLoader] = useState(false);

    const [fsc, setFsc] = useState([]);

    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};

    const companies = [
        {label:'Select Company', value: '0'},
        {label:'DHL', value: 'DHL'},
        {label:'FedEx', value: 'FedEx'},
        {label:'UPS', value: 'UPS'}];

    function getfsc() {
        axios
            .get('/api/Booking/GetFuelCharges')
            .then((response) => {
                setFsc(response.data)
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.error(e);
                }
            });
    }
    function submit() {
        setLoader(true);
        axios
        .get(`/api/Booking/SaveFuelSurcharge/${company}/${fuelSurcharge}`)
        .then((response) => {
            if(response.data === "success"){
                setLoader(false);
                notify('success',`Successfully updated`);
                getfsc();
            }
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
                notify('error', e.message);
            }
            setLoader(false);
        })
      }

    const handleChange = (e) => {
        const { target } = e;
        const { name, value} = target;
    };

    useEffect(() =>{
        getfsc();
        setSelectedMenu('rate');
		setSelectedSubMenu('addFuelSurcharge');
    },[]);

    return (
        <>
        <div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
							<div className='d-flex flex-column'>
								<h2 className='card-h2'>Add Fuel Surcharge</h2>
							</div>
						</div>
					</div>
					<div className='card-body'>
                        <div className="row p-10px mb-31">
                            <div className="col-md-4 mt-4">Select Country</div>
                                <div className="col-md-4">
                                    <select 
                                        value={company}
                                        name='company'
                                        onChange={(e) => setCompany(e.target.value)} 
                                        className="form-control" 
                                    >
                                        {companies?.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                        </div>
                        <div className="row p-10px mb-31">
                            <div className="col-md-4 mt-4">Fuel Surcharge</div>
                            <div className="col-md-4">
                                <Input 
                                    name="fuelSurcharge"
                                    required
                                    type="text" 
                                    placeholder="Fuel Surcharge"
                                    value={fuelSurcharge}
                                    onChange={(e) => setFuelSurcharge(e.target.value)} 
                                />
                            </div>
                        </div>
                        <div className="row p-10px mb-3 pt-4">
                            <div className="col-md-12">
                                <div className="t-a-c">
                                    <button 
                                        type="button" 
                                        className="form-control btn btn-sm btn-primary-custom wt-100" 
                                        onClick={submit}
                                        >Save
                                    </button>
                                </div>
                                
                            </div>
                        </div>
					</div>
                    <div className="dimension-card">
                        <table class="table table-bordered table-striped w-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="bg-warning">Company</th>
                                    <th scope="col" className="bg-warning">Fuel Surcharges(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    companies?.map((item) => {
                                        if(fsc[item.value]){
                                            return <tr>
                                            <td>{item.value}</td>
                                            <td>{fsc[item.value]}</td>
                                        </tr>
                                        }
                                    })
                                }
                                {/* <tr>
                                            <td>DHL</td>
                                            <td>30</td>
                                        </tr> */}
                            </tbody>
                        </table>
                    </div>
				</div>
				
			</div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}
export default AddFuelSurcharge;
