import React, {useState, useRef, useEffect} from "react";
import { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Logo from '../../images/logo.png'
import Loader from "../Common/Loader";

const Invoice = (props) => {
    
    const { bookingDetail, openPopup, action, setAction } = props;
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    
    const {
        bookingId,
        packageType,
        category,
        courierCompany,
        description,
        product,
        productDetail,
        receiverAddress,
        shipperAddress,
        weight,
        pkgCount,
        baseRate,
        fuelSurcharge,
        gst,
        actualBookingId,
        contactPerson,
        dimensions,
    } = bookingDetail



    const [waybillNo, setWayBillNo] = useState(bookingId);
    const [loader, setLoader] = useState(false);
    const [print, setPrint] = useState(false);
    const [success, setSuccess] = useState(false);
    const [getDimensions, setGetDimensions] = useState(null);

    const componentRef = useRef();

    const GetButtonType = () => {
        switch (action) {
            case 'Save':
            case 'Update':
                return (<button type="submit" onClick={handleSubmit}>{action}</button>);
            case 'Print':
                return <button type="submit" onClick={handlePrint}>{action}</button>;
            default:
            // code block
        }
    }
    
    useEffect(() =>{
        if(action == 'Save')
        {
            setGetDimensions(dimensions);
        }
        else{
            setGetDimensions(JSON.parse(dimensions));
        }
    },[])
    const flightCharges = Math.round(parseFloat(baseRate) + parseFloat(fuelSurcharge));
    const total = Math.round(flightCharges + parseFloat(gst))
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getDate = () => {
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		return dd + '-' + mm + '-' + yyyy;
	}

    const handleSubmit = () => {
        setLoader(true);
        var url = '/api/Booking/SaveAndUpdateBooking';
        const method = 'post';
        const payLoad = { 
            ...bookingDetail, 
            action: action,
            dimensions: JSON.stringify(bookingDetail.dimensions),
        }
        axios({
        method,
        url: url,
        data: JSON.stringify(payLoad),
        headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            const result = response.data?.split('-')
            if(result[0] === 'success'){
                if(action === 'Save')
                {
                    setWayBillNo(result[1]);
                }
                setSuccess(true);
                notify('success',`Successfully ${action}d`);   
            }
            else{
                notify('error',`${response.data}d`);  
            }
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
                notify('error', e.message);
            }
        })
        .finally(() => {
            setLoader(false);
        });
    }

    useEffect(() =>{
        if(success){
            if(action == 'Save'){
                handlePrint();
            }
            setAction('Print');
        }
    },[success])
    
    return (
        <>
        {loader ? <Loader class="zi-popup" /> : ''}
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer w-40">
                <div className="titleCloseBtn">
                    <button 
                    onClick={() => {
                        openPopup(false);
                    }}>
                        X
                    </button>
                </div>
                <div id="important_thing1" className="invoice-box" ref={componentRef}>
                    <table className=''>
                        <tr className="border-b">
                            <td>
                                <div id="brandingInvoice" className=''>
                                    <img src={Logo} alt="Company Logo" className="h-60" />
                                </div>
                            </td>
                            <td className="v-a-b">
                                <span className="fs-12">{getDate()}</span>
                                {' '}
                                {' '}
                                <span className="fs-12">{waybillNo}</span>
                            </td>
                        </tr>
                        <tr className="border-b-1">
                            <td className="">
                                <strong className="fs-12">From: </strong>
                                <div className={`${category == 'Export' ? 'fs-10' : 'fs-12 fw-bold'} lh-base`}>
                                    {shipperAddress?.firmName}
                                    <br/>
                                    {shipperAddress?.address}
                                    <br/>
                                    {shipperAddress?.addressLine}
                                    <br/>
                                    {shipperAddress?.city}-{shipperAddress?.pinCode}
                                    <br/>
                                    {shipperAddress?.country}
                                </div>                                    
                            </td>
                            <td className="">
                                <strong className="fs-12">Contacts: </strong>
                                <div className="fs-12 lh-base">
                                    <span>{shipperAddress?.contactNo}</span>
                                        <br/>
                                    <span>{shipperAddress?.emailId}</span>
                                </div>
                            </td>
                        </tr>
                        <tr className="border-b-1">
                            <td>
                                <strong className="fs-12">To: </strong>
                                <div className={`${category == 'Export' ? 'fs-12 fw-bold' : 'fs-10'} lh-base`}>
                                    {receiverAddress?.firmName}
                                    <br/>
                                    {receiverAddress?.address}
                                    <br/>
                                    {receiverAddress?.addressLine}
                                    <br/>
                                    {receiverAddress?.city}-{receiverAddress?.pinCode}
                                    <br/>
                                    {receiverAddress?.country}
                                </div>                                    
                            </td>
                            <td className="">
                                <strong className="fs-12">Contacts: </strong>
                                <div className="fs-12 lh-base">
                                    <span>{receiverAddress?.contactNo}</span>
                                        <br/>
                                    <span>{receiverAddress?.emailId}</span>
                                </div>
                            </td>
                        </tr>
                        <tr className="border-b-1 lh-10">
                            <td>
                                <strong className="fs-12">Product Details: </strong>
                                <div className="fs-12 lh-base">
                                    {product}
                                    <br/>
                                    {productDetail}
                                    
                                </div>                                    
                            </td>
                        </tr>
                        <tr className="border-b-1 lh-10">
                            <td>
                                <strong className="fs-12">Cust Decl Shpt Wgt / Dim Wgt: </strong>
                                <div className="fs-12 lh-base">
                                <span>{`${weight} KG`}</span>
                                    
                                </div>                                    
                            </td>
                            <td className="">
                                <strong className="fs-12">Pieces </strong>
                                <div className="fs-12 lh-base">
                                    <span>{pkgCount}</span>
                                    
                                </div>    
                            </td>
                        </tr>
                        <tr className="border-b-1 lh-10">
                            <td colSpan="2">
                                <div id="container">
                                    <div id="left" className="fs-10">
                                        Category: <span>{category}</span>
                                    </div>
                                    <div id="" className="float-end fs-10">
                                        Package Type: <span>{packageType}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        {
                        getDimensions?.length > 0 ? 
                        <tr className="border-b-1 lh-10">
                            <td colSpan="2">
                                    
                                <div className="row">
                                    <div className="col-md-4">
                                        <strong className="fs-12">Product Dimentions: </strong>
                                    </div>
                                    <div className="col-md-8">
                                        <div id="left" className="fs-10 w-auto">Lenght</div>
                                        <div id="right" className="fs-10">Width</div>
                                        <div id="center" className="fs-10">Height</div>
                                    </div>
                                </div>
                                <div className="fs-12 lh-base">
                                    {getDimensions?.map((dim, idx) => {
                                        return (
                                            <div className="row">
                                                <span className="col-md-4">{`Box ${idx+1}`}</span>
                                                <div className="col-md-8">
                                                    <div id="left" className="fs-10 w-auto">{dim.length}</div>
                                                    <div id="right" className="fs-10">{dim.width}</div>
                                                    <div id="center" className="fs-10">{dim.height}</div>
                                                </div>
                                            </div>
                                        )
                                    })}                                    
                                </div>                                    
                            </td>
                        </tr> : ''
                        }
                        <tr className="border-b-1 lh-10">
                            <td>
                                <span className="fs-10">Content Description: </span>
                                {' '}
                                <span className="fs-10">{description}</span>
                            </td>
                        </tr>
                        <tr className="border-b-1 lh-10">
                            <td>
                                <span className="fs-10">Shipping Company: </span>
                                {' '}
                                <span className="fs-10">{courierCompany}</span>                                
                            </td>
                            {
                                actualBookingId && (
                                    <td className="">
                                        <strong className="fs-12">{`${courierCompany} Booking Number`}</strong>
                                        {' '}
                                        <div className="fs-12 lh-base">
                                            <span>{actualBookingId}</span>
                                        </div>    
                                    </td>
                                )
                            }
                        </tr>
                        <tr className="border-b-1 lh-10">
                            <td colSpan="2">
                                <div id="container">
                                    <div id="left" className="fs-10 w-auto">
                                        Flight Charges: <span>{flightCharges}</span>
                                    </div>
                                    <div id="right" className="fs-10">
                                        Total: <span>{total}</span>
                                    </div>
                                    <div id="center" className="fs-10">
                                        GST: <span>{gst}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className="border-b-1 lh-10">
                           <td>
                                <span className="fs-10">Contact Person: </span>
                                {' '}
                                <span className="fs-10">{contactPerson}</span>                                
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="footer">
                    <button 
                    onClick={() => openPopup(false)}>
                        Cancel
                    </button>
                    {
                        GetButtonType()
                        // print ?
                        // <button type="submit" onClick={handlePrint}>Print</button>
                        // :
                        // <button type="submit" onClick={handleSubmit}>Submit</button>
                    }
                    
                </div>
            </div>
        </div>
        <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
        
    )
}

export default Invoice;