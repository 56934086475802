import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { Context } from "../../context/MenuContext";

const WeOffersSection = () => {
    const [, , , , mainMenu, setMainMenu] = useContext(Context);

    return (
        <>
            <section className="ftco-section bg-light ftco-faqs">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 order-md-last">
                        <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-sm-0 sea-cargo">
                        </div>
                        <div className="d-flex mt-3">
                            <div className="img img-2 mr-md-2 w-100 cust_clear"></div>
                            <div className="img img-2 ml-md-2 w-100 internation-cargo"></div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="heading-section mb-5 mt-5 mt-lg-0">
                            <h3 className="mb-3">We Offers</h3>
                        </div>
                        <div id="accordion" className="myaccordion w-100" aria-multiselectable="true">
                            <Link to="/aboutUs" onClick={() => setMainMenu('aboutUs')}>
                                <div className="card">
                                    <div className="card-header p-0" id="headingOne">
                                        <h2 className="mb-0 w-100">
                                            <button href="#ProductDevelopment_1" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link bg-warning" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne">
                                                <p className="mb-0">Customs clearance</p>
                                                <i className="fa" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/aboutUs" onClick={() => setMainMenu('aboutUs')}>
                                <div className="card">
                                    <div className="card-header p-0" id="headingTwo" role="tab">
                                        <h2 className="mb-0 w-100">
                                        <button href="#ProductDevelopment_2" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link bg-warning" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseTwo">
                                            <p className="mb-0">Air and Ocean Freight Services</p>
                                            <i className="fa" aria-hidden="true"></i>
                                        </button>
                                    </h2>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/aboutUs" onClick={() => setMainMenu('aboutUs')}>
                                <div className="card">
                                    <div className="card-header p-0" id="headingThree" role="tab">
                                        <h2 className="mb-0 w-100">
                                        <button href="#ProductDevelopment_3" className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link bg-warning" data-parent="#accordion" data-toggle="collapse" aria-expanded="false" aria-controls="collapseThree">
                                            <p className="mb-0">International Courier Services</p>
                                            <i className="fa" aria-hidden="true"></i>
                                        </button>
                                    </h2>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="img img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-sm-0 cust_clear">
                        </div> */}
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default WeOffersSection;