import React, { useState } from 'react';

export const Context = React.createContext();

export const MenuContext = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState('dashboard');
  const [selectedSubMenu, setSelectedSubMenu] = useState('');
  const [mainMenu, setMainMenu] = useState('home');

  return (
    <Context.Provider value={[selectedMenu, setSelectedMenu, selectedSubMenu, setSelectedSubMenu, mainMenu, setMainMenu]}>
      {children}
    </Context.Provider>
  );
};
