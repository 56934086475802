import React, { useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import Logo from '../../../images/logo.png'
import {RsPaise} from '../../CommonJsFunc/CommonJsFunc';

const CreditPreviewModal = (props) => {
    const {invoiceDetails, setInvoiceDetails, setPreviewModal, action, handleSubmit } = props;

    const data = invoiceDetails?.creditNoteItems?.filter((a) => a.itemDetail !== '')
    const totalAmount = invoiceDetails?.creditNoteItems?.map((a) => parseFloat(a.total)).reduce((prev, curr) => prev + curr, 0);
    const totalRate = invoiceDetails?.creditNoteItems.map((a) => parseFloat(a.rate)).reduce((prev, curr) => prev + curr, 0);
    const tax = invoiceDetails?.creditNoteItems.map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0);

    const componentRef = useRef();

    const dateFormatter = (date) => {
        const yyyy = new Date(date).getFullYear();
        let mm = new Date(date).getMonth() + 1; // Months start at 0!
        let dd = new Date(date).getDate();
    
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
    
        return `${dd}-${mm}-${yyyy}`;
    };
    
    const generateInvoiceNumber = () => {
        axios
        .get('/api/Lookup/GenerateCNNumber')
        .then((response) => {
            setInvoiceDetails({
              ...invoiceDetails,
              cnNumber: response.data,
              issueDate: new Date()
            })
        })
        .catch((e) => {
        if (e.name !== 'AbortError') {
            console.log('fail:', e.message)
        }
        });
      }; 

       useEffect(() => {
         if(action === 'Save'){
            generateInvoiceNumber();
         }
      }, []);

    const GetButtonType = () => {
        switch (action) {
            case 'Save':
            case 'Update':
                return (<button type="submit" onClick={handleSubmit}>{action}</button>);
            case 'Print':
                return <button type="submit" onClick={handlePrint}>{action}</button>;
            default:
            // code block
        }
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return  (
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button onClick={() => {
                        setPreviewModal(false);
                    }}>
                        X
                    </button>
                </div>
                <div className="invoice-box"  ref={componentRef}>
                    <form>
                        <table className='bg-aliceblue'>
                            <tr className="top border-2">
                                <td colSpan="2" className='border-2'>
                                    <table>
                                        <tr>
                                            <td className=''>
                                                <div id="brandingInvoice" className=''>
                                                    <img src={Logo} alt="Company Logo" className="logo" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='m-l-15'>
                                                    <div id="brandingInvoice">
                                                        <h5 className="c-head">TGL EXPRESS INDIA PRIVATE LIMITED</h5>
                                                    </div>
                                                    <div className="invoice-subheading c-subhead">
                                                        <span className="">No.79, GROUND FLOOR, 2ND MAIN, M.S RAMAIYAH ENCLAVE</span><br />
                                                        <span className="m-4">8TH MILE, NAGASANDRA POST, BENGALURU - 560073</span><br />
                                                        <span className="m-4">GST No. 29AAJCT3309H1Z6, info@tglexpressindia.com</span>
                                                    </div>
                                                </div>    
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="t-a-c border-2">
                                    <strong>Credit Note</strong>                                    
                                </td>
                            </tr>
                            <tr>
                                <td className="border-2">
                                    <strong>CN NO.: {invoiceDetails.cnNumber} </strong> 
                                </td>
                                <td className="border-2">
                                    <strong>Inv. NO.: {invoiceDetails.invoiceNumber} </strong>                                                                        
                                </td>
                            </tr>
                            <tr>
                                <td className="border-2">
                                    <strong>Date Of Issue: </strong>{dateFormatter(invoiceDetails.issueDate)}                                    
                                </td>
                                <td className="border-2">
                                <strong>Date Of Invoice: </strong>{dateFormatter(invoiceDetails.invoiceDate)}                                    
                                </td>
                            </tr>
                            <tr>
                                <td className='border-2'>
                                    <div><strong className='text-decoration-underline'>Billing Address,</strong></div>
                                    <div className='m-4'>
                                        <div className='fs-5 p-10px'>
                                            <strong>M/s. {invoiceDetails.clientName}</strong><br />
                                        </div>
                                        <div className='fs-6 m-auto p-10px m-l-45'>
                                            {invoiceDetails.billingAddress}<br />
                                            {invoiceDetails.billingAddressLine}<br />
                                            {invoiceDetails.billingCity}<br />
                                            {invoiceDetails.billingStateName}<br />
                                        </div>
                                        {
                                           invoiceDetails.billingGSTIN &&
                                           <div className='p-10px'>
                                               <strong>GSTIN: </strong>{invoiceDetails.billingGSTIN}<br />
                                           </div>
                                        }
                                    </div>        
                                </td>
                                <td className='border-2'>
                                    <div><strong className='text-decoration-underline'>Shipping Address,</strong></div>
                                    <div className='m-4'>
                                        <div className='fs-5 p-10px'>
                                            <strong>M/s. {invoiceDetails.consignee}</strong><br />
                                        </div>
                                        <div className='fs-6 m-auto p-10px m-l-45'>
                                            {invoiceDetails.shippingAddress}<br />
                                            {invoiceDetails.shippingAddressLine}<br />
                                            {invoiceDetails.shippingCity}<br />
                                            {invoiceDetails.shippingCountryName}<br />
                                            
                                        </div>
                                        {
                                           invoiceDetails.shippingGSTIN &&
                                            <div className='p-10px'>
                                                <strong>GSTIN: </strong>{invoiceDetails.shippingGSTIN}<br />
                                            </div>
                                        }
                                    </div>        
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='border-2 '>
                                    <strong>Description of goods: </strong>{invoiceDetails.subject}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='border-2'>
                                    <table className=' t-a-c' style={{tableLayout: "fixed"}}>
                                            {
                                                invoiceDetails.billingState?.toString() === "16" ?
                                                (
                                                <>
                                                <thead className='bg-c-head'>
                                                    <tr className="">
                                                        <th rowSpan="2" className='border-2' width='5%'>S.No.</th>
                                                        <th rowSpan="2" className='border-2' width='10%'>SAC Code</th>
                                                        <th rowSpan="2" className='border-2' width='25%'>Particulers</th>
                                                        <th rowSpan="2" className='border-2' width='10%'>Amount</th>
                                                        <th colSpan="2" className='border-2' width='20%'>CGST</th>
                                                        <th colSpan="2" className='border-2' width='20%'>SGST</th>
                                                        <th rowSpan="2" className='border-2' width='10%'>Total (₹)</th>
                                                    </tr>
                                                    <tr className="">
                                                        <th className='border-2' width='25%'>Rate %</th>
                                                        <th className='border-2' width='25%'>Amount</th>
                                                        <th className='border-2' width='25%'>Rate %</th>
                                                        <th className='border-2' width='25%'>Amount</th>
                                                    </tr>
                                                    </thead>    
                                                </>
                                                ) :(
                                                <>
                                                    <tr className="bg-c-head">
                                                        <th className='border-2' width='5%'>S.No.</th>
                                                        <th className='border-2' width='10%'>HSN Code</th>
                                                        <th className='border-2' width='45%'>Particulers</th>
                                                        <th className='border-2' width='10%'>Amount</th>
                                                        <th className='border-2' width='10%'>IGST</th>
                                                        <th className='border-2' width='10%'>IGST Amount</th>
                                                        <th className='border-2' width='10%'>Total (₹)</th>
                                                    </tr>
                                                </>
                                                )
                                            }
                                            {data.map((item, idx) => (invoiceDetails.billingState?.toString() === "16" ?
                                                (
                                                <tr className="border-2" key={idx}>
                                                        <td className="border-2">{idx + 1}</td>
                                                        <td className="border-2">{item.code}</td>
                                                        <td className="border-2 t-a-l">{item.itemName}</td>                                        
                                                        <td className="border-2">{item.rate}</td>
                                                        <td className="border-2">{item.gst / 2}%</td>
                                                        <td className="border-2">{(item.gstAmount / 2).toFixed(2)}</td>
                                                        <td className="border-2">{item.gst / 2}%</td>
                                                        <td className="border-2">{(item.gstAmount / 2).toFixed(2)}</td>
                                                        <td className="border-2">{item.total}</td>
                                                    </tr>
                                                ):(
                                                <tr className="border-2" key={idx}>
                                                        <td className="border-2">{idx + 1}</td>
                                                        <td className="border-2">{item.code}</td>
                                                        <td className="border-2 t-a-l">{item.itemName}</td>                                        
                                                        <td className="border-2">{item.rate}</td>
                                                        <td className="border-2">{item.gst}%</td>
                                                        <td className="border-2">{item.gstAmount}</td>
                                                        <td className="border-2">{item.total}</td>
                                                    </tr>
                                                )
                                                ))
                                            }
                                            {invoiceDetails.billingState?.toString() === "16" ?
                                            (                                            
                                            <tr>
                                                <td colspan="3" className="border-2 w-48">SUB TOTAL</td>
                                                <td className="border-2 w-10">{totalRate.toFixed(2)}</td>
                                                <td className='border-2'></td>
                                                <td className="border-2 w-16">{(tax / 2).toFixed(2)}</td>
                                                <td className='border-2'></td> 
                                                <td className="border-2 w-16">{(tax / 2).toFixed(2)}</td>
                                                <td className="border-2 w-10">{totalAmount.toFixed(2)}</td>
                                            </tr>
                                            ):
                                            (
                                            <tr>
                                                <td colspan="3" className="border-2">SUB TOTAL</td>
                                                <td className="border-2 w-10">{totalRate}</td>
                                                <td className='border-2'></td>
                                                <td className="border-2 w-20">{tax}</td>
                                                <td className="border-2 w-10">{totalAmount.toFixed(2)}</td>
                                            </tr>
                                            )                                        
                                        }
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td rowSpan={invoiceDetails.billingState?.toString() === "16" ? "7" : "5"} colSpan="" className="currancyText border-2">
                                    <div>
                                        <strong>In Words: </strong>{RsPaise(Math.round(totalAmount).toFixed(2))} Only.
                                    </div>                                    
                                </td>
                            </tr>
							{
							invoiceDetails.billingState?.toString() === "16" ?
							(
							<>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="">CGST @ 9%: {invoiceDetails.creditNoteItems.filter(a=>a.gst===18).length === 0 ? '' : (invoiceDetails.creditNoteItems.filter((a) => a.gst === 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)?.toFixed(2)}</td>
                                </tr>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="">SGST @ 9%: {invoiceDetails.creditNoteItems.filter(a=>a.gst===18).length === 0 ? '' : (invoiceDetails.creditNoteItems.filter((a) => a.gst === 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)?.toFixed(2)}</td>
                                </tr>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="">CGST @ 2.5%: {invoiceDetails.creditNoteItems.filter(a=>a.gst===5).length === 0 ? '' : (invoiceDetails.creditNoteItems.filter((a) => a.gst === 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)?.toFixed(2)}</td>
                                </tr>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="">SGST @ 2.5%: {invoiceDetails.creditNoteItems.filter(a=>a.gst===5).length === 0 ? '' : (invoiceDetails.creditNoteItems.filter((a) => a.gst === 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0) / 2)?.toFixed(2)}</td>
                                </tr>
							</>
							
							) :(
                            <>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="">IGST @ 18%: {invoiceDetails.creditNoteItems.filter(a=>a.gst===18).length === 0 ? '' : (invoiceDetails.creditNoteItems.filter((a) => a.gst === 18).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0))?.toFixed(2)}</td>
                                </tr>
                                <tr className="border-2 fw-bold text-lg-end">
                                    <td colSpan="">IGST @ 5%: {invoiceDetails.creditNoteItems.filter(a=>a.gst===5).length === 0 ? '' : (invoiceDetails.creditNoteItems.filter((a) => a.gst === 5).map((a) => parseFloat(a.gstAmount)).reduce((prev, curr) => prev + curr, 0))?.toFixed(2)}</td>
                                </tr>
                            </>
							)}
                            <tr className="border-2 fw-bold text-lg-end">
                                <td colSpan="2">Total Invoice Amount: {Math.round(totalAmount).toFixed(2)}</td>
                            </tr>
                            <tr className="border-2 fw-bold text-lg-end">
                                <td colSpan="2">Net Amount: {totalAmount === ' ₹NaN' ? 0 : Math.round(totalAmount).toFixed(2)}</td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="2" className='border-2'>
                                    <table>
                                        <tr>
                                            <td>
                                                <ul>
                                                    <li>Subject to Bangalore Jurisdiction.</li>
                                                    <li>Overdue intrest will be charged @25% p.a after 30 days.</li>
                                                    <li>Subject to your paying short collection of custom duty under section 28(i) of the CA 1962.</li>
                                                    <li>Cheque / DD should be made out of M/s TGL EXPRESS INDIA PRIVATE LIMITED.</li>
                                                </ul>
                                            </td>
                                           
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="2" className="border-2">
                                    <div className='row'>
                                        <div className="col-md-4">OUR BANK: State Bank of India</div>
                                        <div className="col-md-4">A/C : 43240724485</div>
                                        <div className="col-md-4">IFSC : SBIN0017736</div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="border-2">
                                <td colSpan="" className="border-2">
                                    PAN: AAJCT3309H
                                </td>
                                <td colSpan="" className="border-2 text-lg-center">
                                    <div>
                                        <strong>This is computer generated invoice, seal and sign not required.</strong>
                                    </div>
                                    
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div className="footer">
                    <button onClick={() => {
                        setPreviewModal(false);
                    }}>
                        Cancel
                    </button>
                    {GetButtonType()}
                </div>
            </div>
        </div>
    )
}

export default CreditPreviewModal;