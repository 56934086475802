import React, {useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaPrint, FaMinusCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Common/Loader';
import { dateFormatter } from '../../CommonJsFunc/CommonJsFunc';
import PreviewModal from "./PreviewModal";
import ConfirmationPopup from '../ConfirmationPopup';

import { Context } from '../../../context/MenuContext';

const DebitNotes = () => {
    
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    const [loader, setLoader] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [previewModal, setPreviewModal] = useState(false);
    const [debitNote, setDebitNote] = useState('');
    const [states, setStates] = useState(null);
    const [countries, setCountries] = useState(null);
    const [confirmModalPopup, setConfirmModalPopup] = useState(false);
    const [deletingInvoice, setDeletingInvoice] = useState('');

    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(Context);

    const getInvoicReports = () => {
        axios
        .get('/api/Lookup/GetDebitNotes')
        .then((response) => {
            const result = response.data;
            const data = result.map((item) => {
                return {
                    ...item,
                    totalAmount: parseFloat(item.debitNoteItems?.map((item) =>  parseFloat(item.totalAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                }
            }) 
            setInvoices(data);
            setLoader(false);
            getStates();
            getCountries();
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
            }
            setLoader(false);
        });
    } 

    const getCountries = () =>{
        axios
            .get('/api/Lookup/GetCountries')
            .then((response) => {
                setCountries(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }

    const getStates = () =>{
        axios
            .get('/api/Lookup/GetStates')
            .then((response) => {
                setStates(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }

    useEffect(() => {
        setLoader(true);
        getInvoicReports();
        setSelectedMenu('sales');
		setSelectedSubMenu('debitNote');
    }, []);

    const handlePreview =(dnNumber)=>{
        if(countries != null && states != null){
            const filterData = invoices.filter((e) => e.dnNumber === dnNumber)[0];
            filterData.billingStateName = states[filterData.billingState];
            filterData.shippingStateName = states[filterData.shippingState];
            filterData.shippingCountryName = countries[filterData.shippingCountry];
            setDebitNote(filterData);
            setPreviewModal(true);        
        }        
    }  

    const removeInvoice = (invoiceNumber) => {
        if(invoiceNumber !== ''){
          setDeletingInvoice(invoiceNumber);
          setConfirmModalPopup(true);
        }
    };

    const handleRemove = () => {
        setLoader(true);
        setConfirmModalPopup(false);
        
        axios.get(`/api/Lookup/Delete/${deletingInvoice}/DebitNote`)
            .then((response) => {
              if(response.data === "success"){
                notify('success', 'Successfully Invoice Removed');
                getInvoicReports();
              }
              else{
                  notify('error', "Something Went Wrong");
                  setLoader(false);
              }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                    setLoader(false);
                }
            });
    }

    return (
        <>
        <>
        {confirmModalPopup && <ConfirmationPopup invoiceNumber={deletingInvoice} setConfirmModalPopup={setConfirmModalPopup} handleRemove={handleRemove} />}
        {loader ? <Loader /> : '' }
        {previewModal ? <PreviewModal 
                            invoiceDetails={debitNote}
                            setInvoiceDetails={setDebitNote}
                            setPreviewModal={setPreviewModal}
                            action='Print'
                        /> : ''}
        <div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
							<div className='d-flex flex-column'>
								<h2 className='card-h2'>All Debit Notes</h2>
							</div>
						</div>
					</div>
					<div className='card-body'>
                    <div className='row pb-3'>
                            <div className='col-md-12 text-right'>
                                <Link to={`/admin/debitNoteForm/new`} className='btn btn-sm btn-primary-custom m-2'>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z"></path></svg>
                                    New Debit Note
                                </Link>
                            </div>
                        </div> 
                        <div className="row p-10px item-list respnsive-custom-table">
                            <table className='table'>
                                <thead className='sticky-header'>
                                    <tr className="heading">
                                        <th className='bg-body-secondary'>Sl.NO.</th>
                                        <th className='bg-body-secondary'>DN No</th>
                                        <th className='bg-body-secondary'>Client Name</th>
                                        <th className='bg-body-secondary'>Issue Date</th>
                                        <th className='bg-body-secondary'>Total Amount</th>
                                        <th className='bg-body-secondary'>Print</th>
                                        <th className='bg-body-secondary'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { invoices?.map((item, idx) => (
                                        <tr className="p-10px item" key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>
                                                <Link to={`/admin/debitNoteForm/${item.dnNumber}`} className='color-b cursor-p'>
                                                    {item.dnNumber}
                                                </Link>                                                
                                            </td>
                                            <td><strong>{item.clientName}</strong></td>
                                            <td><span>{dateFormatter(item.issueDate)}</span></td>
                                            <td><span>{Math.round(item.totalAmount).toFixed(2)}</span></td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-link m-0 p-0 text-edit"
                                                    title="Edit"
                                                    onClick={(e) => handlePreview(item.dnNumber)}
                                                >
                                                <FaPrint />
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-link m-0 p-0 color-r"
                                                    title=""
                                                    onClick={(e) => removeInvoice(item.dnNumber)}
                                                >
                                                    <FaMinusCircle />
                                                </button>
                                            </td>
                                    </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
					</div>
				</div>
				
			</div>
		</>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </>
    );
}

export default DebitNotes;