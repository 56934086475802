import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Weight from '../components/Rate/Weight';
import Dimension from '../components/Rate/Dimension'
import Charges from "../components/Rate/Charges";

import { Context } from '../context/RateContext';
import CombineCharge from "../components/Rate/CombineCharge";
import Loader from "../components/Common/Loader";

const Rate =()=>{
	const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};

    const [dimension, setDimension] = useState(false);
	const [charges, setCharges] = useState(null);
    const [loader, setLoader] = useState(false);
	const [margins, setMargins] = useState([]);

    const [measurement, setMeasurement] =  useContext(Context);

	const getDate = () => {
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		return dd + '-' + mm + '-' + yyyy;
	}
	function getRates(weight) {
        const { category, packageType, country } = measurement;
        setLoader(true);
        axios
          .get(`/api/Booking/GetRates/${category}/${packageType}/${country}/${weight}`)
          .then((response) => {
            setMeasurement({
                ...measurement,
                rateCollections: response.data,
				weight: weight
            })
            setDimension(true)
          })
          .catch((e) => {
            if (e.name !== 'AbortError') {
              console.error(e);
            }
            setMeasurement({
                ...measurement,
                rateCollections: []
            })
            setDimension(false)
			notify('error', e.message);
          })
          .finally(() => {
            setLoader(false);
          });
    }

	function getMargins() {
        axios
		.get('/api/Booking/GetMargins')
		.then((response) => {
			setMargins(response.data)
		})
		.catch((e) => {
			if (e.name !== 'AbortError') {
				console.error(e);
			}
			notify('error', e.message);
		});
    }

	function calculateMargin(baseRate) {
		const wt = parseFloat(measurement.weight);
		let preVal = 0;
		const byCategory = margins.filter((e) => e.category == measurement.category);

		for (const element of byCategory) {
			if (element.maxWeight === 'above' && element.category == measurement.category) { 
				return (parseFloat(element.percentage)/100)*parseFloat(baseRate); 
			}
			else if(wt > parseFloat(preVal) && wt <= parseFloat(element.maxWeight)  && element.category == measurement.category){
				return (parseFloat(element.percentage)/100)*parseFloat(baseRate)
			}
			preVal = element.maxWeight;
		}
	}
	useEffect(() => {
		if(measurement.weight)
		{
			setCharges(
				measurement?.rateCollections?.map((i) =>{
					const baseRate = Math.round(i.baseRate + calculateMargin(i.baseRate));
					const fsc = Math.round((i.fuelSurcharge/100)*baseRate);
					const gst = Math.round((18/100)*(baseRate + fsc))
					return { ...i, 
						baseRate: baseRate,
						fuelSurcharge: fsc,
						GST:gst,
					 };
				})
			)
		}
	},[measurement?.rateCollections])

	useEffect(() => {
		if(charges !== null)
		{
			setMeasurement((v) => {
				return {
					...v,
					charges: charges
				  };
			});
		}
	},[charges])

	useEffect(() => {
		getMargins();
	},[])

	const lowestRate = charges?.map((i) =>  {
		if(i.baseRate > 0 ){
		return (i.baseRate+i.fuelSurcharge+i.GST)
	}}).sort(function(a, b){return a - b})[0];

    return (
		<>
			{loader ? <Loader /> : ''}
			<div className="rate-card">
			<div className={`hero-wrap bg-img ${ dimension ? 'project': 'height'}`}>
				<div className="overlay"></div>
				<div className="container pt-80">
				<div className='custom-container rate'>				
					<div className={`card ${dimension ? 'mt-5 ' : ''}`}>
						<div className='card-header bg-orange'>
							<div className='card-title m-0'>
								<div className='d-flex flex-column'>
									<h2 className='card-h2'>GET RATE</h2>
								</div>
							</div>
						</div>
						<div className='card-body'>
							<Weight 
							apiCall={getRates} />
							{/* Dimension section */}
							{
								dimension ?
								<>
									<Dimension apiCall={getRates} /> 
									<div className="dimension-card h5 p-3">
										<span>Following is our Pricing as of on <strong>{getDate()}</strong></span>
									</div>
									<div className="dimension-card h5 p-3">
										<strong>Lowest Price :<strong className="color-b">{lowestRate}</strong></strong>
									</div>
									<CombineCharge data={charges} />
									{
										charges?.map((item) => ( 							
											<Charges data={item} />
										))
									}
									<div className="dimension-card h5 p-3 color-g">
										<span><strong>Please Note:</strong> Transportation Charges, Storage Charges, Customs Duty & Clearance and Delivery order charges at actuals.</span>
									</div>
								</>
								: ''
							}
						</div>
					</div>
				</div>
				</div>
				</div>
			</div>			
			<ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
		</>
	)

}

export default Rate;
