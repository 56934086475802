import React, { useState, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { Context } from '../../context/MenuContext';

const Submenu = ({ item }) => {
	const [subnav, setSubnav] = useState(false);
	const [selectedMenu, setSelectedMenu, selectedSubMenu, setSelectedSubMenu] = useContext(Context);

  	const showSubnav = () => {
		setSubnav(true);
	}
	return (
		<>
			<div className='sidebar-accordion-wrapper'>
				<Accordion className='sidebar-accordion'>
					<Accordion.Item eventKey={item.id} className={`accordion-menu ${item.subNav ? 'has-subnav' : 'no-subnav'}`} onClick={item.subNav && showSubnav}>
						<Accordion.Header className={`${selectedMenu === item.menu ? 'active' : ''}`}>
							<Link to={item.path} onClick={() => setSelectedMenu(item.menu)}>
								<div>
									<img src={item.icon} className='' alt='' />{" "}
									<label className='menu-label'>{item.title}</label>
								</div>
							</Link>
						</Accordion.Header>
						<Accordion.Body className='active'>
							<div className='submenu-list-links'>
								{subnav && item?.subNav?.map((item, index) => {
									return (
										<Link 
											to={item.path} key={index} 
											className={`submenu-links subMenu ${selectedSubMenu === item.subMenu ? 'active' : ''}`}
											onClick={() => { setSelectedSubMenu(item.subMenu); setSubnav(false) }}
											>
											<img src={item.icon} className='' alt='' />{" "}
											<label className='submenu-label'>{item.title}</label>
										</Link>
									);
								})}
							</div>
						</Accordion.Body>
					</Accordion.Item>
					
				</Accordion>
			</div>
		</>
	)
}

export default Submenu