import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Loader from "../Common/Loader";

import './contactus.css';

const Contact =()=>{
    const defaultContactUs = () => {
        return {
            name: '',
            mobile: '',
            email: '',
            subject: '',
            message: ''
        };
      };

    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    
    const [success, setSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const [messageDetail, setMessageDetail] = useState(defaultContactUs());

    const handleSubmit = () => {
        setLoader(true);
        var url = '/api/Booking/SubmitMessage';
        const method = 'post';
        axios({
        method,
        url: url,
        data: JSON.stringify(messageDetail),
        headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            const result = response.data?.split('-')
            if(result[0] === 'success'){
                setSuccess(true);
                notify('success',`Successfully Saved`);  
            }
            else{
                notify('error',`${response.data}d`);  
            }
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
                notify('error', e.message);
            }
        })
        .finally(() => {
            setLoader(false);
        });
    }

    const handleChange = (e) => {
        const { target } = e;
        const { name, value} = target;
        setMessageDetail({
          ...messageDetail,
          [name]: value,
        });
    };

    return (
        <>
        {loader ? <Loader class="zi-popup" /> : ''}
        <div className="contact-card">
            <div class="hero-wrap js-fullheight height bg-img contact">
                <div class="overlay"></div>
                <section class="contact" id="contact">
                    <div class="container">
                        <div class="heading text-center">
                            <h2>Contact
                                <span> Us </span></h2>
                                <br />
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="title">
                                    <h3>Contact detail</h3>
                                    <p>TGL EXPRESS INDIA PRIVATE LIMITED</p>
                                </div>
                                <div class="content">
                                    {/* <!-- Info-1 --> */}
                                    <div class="info">
                                        <i class="fa fa-mobile"></i>
                                        <h4 class="d-inline-block">PHONE :
                                            <br />
                                            <span>+(91)9663034113</span></h4>
                                    </div>
                                    {/* <!-- Info-2 --> */}
                                    <div class="info">
                                        <i class="fa fa-envelope"></i>
                                        <h4 class="d-inline-block">EMAIL :
                                            <br />
                                            <span><a href="mailto:info@tglexpressindia.com">info@tglexpressindia.com</a></span></h4>
                                    </div>
                                    {/* <!-- Info-3 --> */}
                                    <div class="info">
                                        <i class="fa fa-map-marker"></i>
                                        <h4 class="d-inline-block">ADDRESS :</h4><br />
                                        <h4><span>
                                                79, GROUND FLOOR, 2ND MAIN, MS RAMAIAH
                                                <br />
                                                ENCLAVE, 8TH MILE, NAGASANDRA POST,
                                                <br />
                                                BENGALURU - 560073
                                            </span>
                                            </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input type="text" name="name" onChange={handleChange} class="form-control" placeholder="Name" />
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="text" name="mobile" onChange={handleChange} class="form-control" placeholder="Contact Number" />
                                    </div>
                                    <div class="col-sm-6">
                                        <input type="email" name="email" onChange={handleChange} class="form-control" placeholder="Email" />
                                    </div>
                                    <div class="col-sm-12">
                                        <input type="text" name="subject" onChange={handleChange} class="form-control" placeholder="Subject" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" name="message" onChange={handleChange} rows="5" id="comment" placeholder="Message"></textarea>
                                </div>
                                <button class="btn btn-block" onClick={handleSubmit}>Send Now!</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>     
        </div>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        </>
    )
}

export default Contact;