import React from 'react'
import { Link } from 'react-router-dom'
// import { Link } from '@reach/router';

const Footer = () => {
	return (
		<div className='footer'>
			<div className='copyright-text'><span>2023©</span> TGL Express</div>
			<div className='footer-links'>
				<Link to='/' className='mx-2'>About</Link>
				<Link to='/' className='mx-2'>Contact</Link>
			</div>
		</div>
	)
}

export default Footer