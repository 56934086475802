const WelcomeCard =()=>{
    return (
        <div className="hero-wrap js-fullheight height bg-img">
        <div className="overlay"></div>
        <div className="container">
            <div className="row no-gutters slider-text js-fullheight align-items-center height" data-scrollax-parent="true">
            <div className="col-md-12 ftco-animate" data-scrollax=" properties: { translateY: '70%' }">
                <h1 className="mb-4 mt-240 text-center" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">Welcome to TGL Express </h1>
            </div>
            </div>
        </div>
        </div>
    )
}

export default WelcomeCard;