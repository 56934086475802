import { Link } from 'react-router-dom';
import React from "react";

const CombineCharge = (props) =>{
    const { data } = props;
    return(
        <>
        <div className="dimension-card">
            <table class="table table-bordered table-striped w-300">
                <thead>
                    <tr>
                        <th scope="col" className="bg-warning">Company</th>
                        <th scope="col" className="bg-warning">Charges</th>
                        <th scope="col" className="bg-warning"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((item) => ( 							
                            <tr>
                                <td>{item.company}</td>
                                <td>{item.baseRate + item.fuelSurcharge + item.GST}</td>
                                <td>
                                    <Link to={`/booking/${item.company}`} className='btn-primary-custom m-2'>
                                        Book
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <hr className="border-2 w-300" />
        </div>
        </>
    )
}

export default CombineCharge;