import React from "react";
import DatePicker from "react-datepicker";
import Input from '../../Common/Input';
import ApiTypeAhead from '../../Common/ApiTypeAhead';

const UpdateTDS =(props)=>{
    const {
        setModalOpen, 
        updateInvoices, 
        handleUpdateTDS, 
        handleChange,
        handleValidation, 
        setUpdateInvoices, 
        action,
        setAction
    } = props

    function getTdsRates(){
        const values = [];
        [...Array(10).keys()].forEach((o) => {
            values.push({label:`${o}%`, value: o})
          });
        return values;  
    }

    const onTaxChange = (e) => {
        const { name, value } = e.target;
        if(name === 'netAmount'){
            const tdsAmt = ((parseFloat(updateInvoices.netAmount) / 100) * parseFloat(updateInvoices.tds));
            const invoiceAmount = parseFloat(updateInvoices.gstAmount) + parseFloat(updateInvoices.netAmount)
            setUpdateInvoices({
                ...updateInvoices,
                tdsAmount : tdsAmt,
                finalAmount : tdsAmt  + parseFloat(updateInvoices.netAmount),
                balanceAmount : parseFloat(invoiceAmount)  - tdsAmt,
                invoiceAmount : invoiceAmount
            })

        }else{
            const tdsAmt = ((parseFloat(updateInvoices.netAmount) / 100) * parseFloat(value));
            setUpdateInvoices({
                ...updateInvoices,
                tds: parseInt(value),
                tdsAmount : tdsAmt,
                finalAmount : tdsAmt  + parseFloat(updateInvoices.netAmount),
                balanceAmount : parseFloat(updateInvoices.invoiceAmount)  - tdsAmt,
            })
        }        
    }
    return (
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer update-tds">
                <div className="titleCloseBtn">
                    <button onClick={() => {
                        setModalOpen(false);
                        setAction('');
                    }}>
                        X
                    </button>
                </div>
                <div className="invoice-box">
                    <form>
                        <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                Client Name: 
                            </div>
                            <div className="col-md-9">
                                <ApiTypeAhead
                                    name="clientName"
                                    title=''
                                    defaultId={updateInvoices.clientName}
                                    displayKey="clientName"
                                    query={updateInvoices.clientName}
                                    placeholder="Start typing to search"
                                    url="/api/Lookup/GetTDSClientName"
                                    onChange={handleChange}
                                    onBlur={handleValidation}
                                    required
                                    />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                Invoice Number: 
                            </div>
                            <div className="col-md-9">
                                <Input 
                                    name="invoiceNumber" 
                                    value={updateInvoices.invoiceNumber} 
                                    title="" 
                                    placeholder="Invoice Number" 
                                    onChange={handleChange}
                                />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                Invoice Date: 
                            </div>
                            <div className="col-md-9">
                                <DatePicker 
                                    name='tdsDate'
                                    title=''
                                    selected={updateInvoices.date ? new Date(updateInvoices.date) : new Date()} 
                                    onChange={(d) => 
                                        setUpdateInvoices({
                                            ...updateInvoices, 
                                            date : new Date(d).toLocaleDateString() 
                                            })
                                        }
                                    placeholderText='Pay Date'
                                    className='form-control' 
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                PAN: 
                            </div>
                            <div className="col-md-9">
                                <Input 
                                    name="pan" 
                                    value={updateInvoices.pan} 
                                    title="" 
                                    placeholder="PAN" 
                                    onChange={handleChange}
                                />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                Net Amount: 
                            </div>
                            <div className="col-md-9">
                                <Input 
                                    name="netAmount" 
                                    type='number'
                                    value={updateInvoices.netAmount} 
                                    title="" 
                                    placeholder="Net Amt" 
                                    onChange={handleChange}
                                    onBlur={(e) => onTaxChange(e)}
                                />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                GST Amount: 
                            </div>
                            <div className="col-md-9">
                                <Input 
                                    name="gstAmount" 
                                    type='number' 
                                    value={updateInvoices.gstAmount} 
                                    title="" 
                                    placeholder="GST Amt" 
                                    onChange={handleChange}
                                    onBlur={() => setUpdateInvoices({
                                        ...updateInvoices,
                                        invoiceAmount : parseFloat(updateInvoices.gstAmount) + parseFloat(updateInvoices.netAmount)
                                    })}
                                />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                Inv. Amount: 
                            </div>
                            <div className="col-md-9">
                                <span>{updateInvoices.invoiceAmount ? updateInvoices.invoiceAmount?.toFixed(2) : 0}</span>
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                TDS: 
                            </div>
                            <div className="col-md-9">
                                <select 
                                    value={updateInvoices.tds} 
                                    onChange={(e) => onTaxChange(e)}
                                    className="form-control" >
                                    {getTdsRates().map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                TDS Amount: 
                            </div>
                            <div className="col-md-9">
                                <span>{updateInvoices.tdsAmount ? updateInvoices.tdsAmount?.toFixed(2) : 0}</span>
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                Final Amount: 
                            </div>
                            <div className="col-md-9">
                                <span>{updateInvoices.finalAmount ? updateInvoices.finalAmount?.toFixed(2) : 0}</span>
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                Balance Amount: 
                            </div>
                            <div className="col-md-9">
                                <span>{updateInvoices.balanceAmount ? updateInvoices.balanceAmount?.toFixed(2) : 0}</span>
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="col-md-3 mt-2">
                                Payment Date: 
                            </div>
                            <div className="col-md-9">
                                <DatePicker 
                                    name='paymentDate'
                                    title=''
                                    selected={updateInvoices.paymentDate ? new Date(updateInvoices.paymentDate) : new Date()} 
                                    onChange={(d) => 
                                        setUpdateInvoices({
                                            ...updateInvoices, 
                                            paymentDate : new Date(d).toLocaleDateString() 
                                            })
                                        }
                                    placeholderText='Pay Date'
                                    className='form-control'
                                    dateFormat="dd/MM/yyyy" 
                                />
                            </div>
                       </div>
                    </form>
                </div>
                <div className="footer h-50">
                    <button onClick={() => {
                        setModalOpen(false);
                        setAction('');
                    }}>
                        Cancel
                    </button>
                    <button type="submit" onClick={handleUpdateTDS}>{action}</button>
                </div>
            </div>
        </div>
    )
}
export default UpdateTDS;