import React, { useState, useContext, useEffect } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "./screens/Dashboard";
import Invoice from './screens/Invoice';
import NewInvoice from './components/Invoice/InvoiceSaveUpadte/NewInvoice'
import Item from './components/Item';
import PaymentInvoice from './components/Invoice/PaymentInvoice/PaymentInvoice';
import PaymentInvoiceForm from './components/Invoice/PaymentInvoice/PaymentInvoiceForm';
import InvoiceTDS from './components/Invoice/InvoiceTDS/InvoiceTDS';
import InvoiceStatement from './components/Invoice/InvoiceStatement/Statement';
import DebitAndCreditNote from './screens/DebitAndCreditNote';
import CreditNote from './components/Invoice/CreditNote/CreditNotes';
import DebitNote from './components/Invoice/DebitNote/DebitNotes';
import DebitNoteForm from './components/Invoice/DebitNote/DebitNoteForm';
import CreditForm from './components/Invoice/CreditNote/Form';
import FreightInvoice from './screens/FreightInvoice';
import FreightInvoiceForm from './components/Invoice/FreightInvoice/FreightInvoiceForm';
import ChangePassword from './components/ChangePassword';
import RateFileUpload from './components/Rate/RateFileUpload';
import AddFuelSurcharge from './components/Rate/AddFuelSurcharge';
import Booking from './screens/Booking';
import BookingList from './components/BookParcel/BookingList';
import AddMargin from './components/Rate/AddMargin';
import AdminLayout from './screens/AdminLayout';
import MainLayout from './screens/MainLayout';
import Home from './screens/Home';
import AboutUs from './components/AboutUs';
import SignIn from './components/SignIn';
import Rate from './screens/Rate';
import RatePage from './screens/RatePage';
import ContactUs from './components/ContactUs';
import Services from './components/Services';
import BookingPage from './screens/BookingPage';

import { RateContext } from './context/RateContext';
import { MenuContext } from './context/MenuContext';
import { AuthProvider, AuthContext } from './context/AuthContext';


function App() {
    return (
        <>
            <AuthProvider>
                <BrowserRouter>
                    <Main />
                </BrowserRouter>
            </AuthProvider>
        </>
    );
}

const Main = () => {
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if(!user){
            navigate("/");
        }
    },[user])
  
    return (
        <MenuContext>
        <RateContext>
            <Routes>
                {/* Main layout routes */}
                <Route path="/" element={<MainLayout />}>
                    <Route index element={<Home />} />
                    <Route path="aboutUs" element={<AboutUs />} />
                    <Route path="getRates" element={<RatePage/>} />
                    <Route path="contactUs" element={<ContactUs/>} />
                    <Route path="services" element={<Services/>} />
                    <Route path="booking/:company?/:id?" element={<BookingPage/>} />
                </Route> 

                {/* Admin layout routes */}
                <Route path="/admin" element={<AdminLayout />}>
                {user ? (
                    <>                                                    
                        <Route path="home" element={<Dashboard />} />
                        <Route path="invoice" element={<Invoice />} />
                        <Route path="newinvoice/:id?" element={<NewInvoice />} />
                        <Route path="item" element={<Item />} />
                        <Route path="paymentInvoice" element={<PaymentInvoice />} />
                        <Route path="paymentInvoiceForm" element={<PaymentInvoiceForm />} />
                        <Route path="invoiceTDS" element={<InvoiceTDS />} />
                        <Route path="invoiceStatement" element={<InvoiceStatement />} />
                        <Route path="debitAndCreditNote" element={<DebitAndCreditNote />} />
                        <Route path="creditNote" element={<CreditNote />} />
                        <Route path="debitNote" element={<DebitNote />} />
                        <Route path="debitNoteForm/:id?" element={<DebitNoteForm />} />
                        <Route path="creditForm/:id?" element={<CreditForm />} />
                        <Route path="freightInvoice" element={<FreightInvoice />} />
                        <Route path="freightInvoiceForm/:id" element={<FreightInvoiceForm />} />
                        <Route path="changePassword" element={<ChangePassword />} />
                        <Route path="rateFileUpload" element={<RateFileUpload />} />
                        <Route path="addFuelSurcharge" element={<AddFuelSurcharge />} />
                        <Route path="addMargin" element={<AddMargin />} />
                        <Route path="bookingReport" element={<BookingList />} />
                        {/* <Route path="booking/:company?/:id?" element={<Booking/>} /> */}
                        {/* <Route path="getRates" element={<Rate/>} /> */}
                    </>
                    ):
                    <>
                        <Route index path="signIn" element={<SignIn />} />
                        {/* <Route path="getRates" element={<Rate/>} /> */}
                        {/* <Route path="booking/:company?/:id?" element={<Booking/>} /> */}
                    </>
                }
                
                </Route>
            </Routes>
        </RateContext>
    </MenuContext>
    );
  };

export default App;