import Header from "../Home/Header";
import Menu from "../Home/Menu";
import Footer from "../Home/Footer";
import ServicePage from "./Services";

const Services = () => {

    return (
        <>
            <Header />
            <Menu />
            <ServicePage />
            <Footer />
        </>
    );
}

export default Services;