import { useParams } from 'react-router-dom';
import Header from "../components/Home/Header";
import Menu from "../components/Home/Menu";
import Footer from "../components/Home/Footer";
import Booking from "./Booking";

const BookingPage = () => {
    let { company, id } = useParams();
    return (
        <>
            <Header />
            <Menu />
            <Booking company={company} id={id} />
            <Footer />
        </>
    );
}

export default BookingPage;