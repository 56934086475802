import React,{ useContext, useEffect} from 'react';
import { Link } from 'react-router-dom'
import FreightInvoices from '../components/Invoice/FreightInvoice/FreightInvoices';
import { Context } from '../context/MenuContext';

const FreightInvoice = () => {
	const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(Context);

	useEffect(() => {
		setSelectedMenu('sales');
		setSelectedSubMenu('freightInvoice');
    }, []);

	return (
		<>
			<div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
							<div className='d-flex flex-column'>
								<h2 className='card-h2'>All Freight Invoices</h2>
							</div>
						</div>
						<Link to='/admin/freightInvoiceForm/new' className='btn btn-sm btn-primary-custom'>
							<svg stroke="currentColor" fill="currentColor" strokeWidth="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z"></path></svg>
							New Invoice
						</Link>
					</div>
					<div className='card-body'>
						<FreightInvoices />
					</div>
				</div>
				
			</div>
		</>
	)
}

export default FreightInvoice