import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = (props) => {
  const { series, categories, title } = props;

  function kFormatter(num) {
    // return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num);
    
    let sign = '';
    if(Math.abs(num) > 9999999){
      sign = 'cr';
    }
    else if (Math.abs(num) > 99999){
      sign = 'l';
    }
    else if(Math.abs(num) > 999){
      sign = 'k';
    }
    switch(sign) {
      case 'cr':
        return `${Math.sign(num) * ((Math.abs(num) / 10000000).toFixed(1))} cr`;
      case 'l':
        return `${Math.sign(num) * ((Math.abs(num) / 100000).toFixed(1))} l`;
      case 'k':
        return `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))} k`;
      default:
        return Math.sign(num) * Math.abs(num);
    }
  }
  const options = {
    chart: {
      type: 'bar',
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: title,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238',
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
      labels: {
        formatter: (str) => {
          const n = 10;
          return str.length > n ? `${str.substr(0, n - 1)}...` : str;
        },
        rotateAlways: true,
        rotate: -25,
      },
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `${kFormatter(value)}`;
        },
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          // return `${kFormatter(val)}`;
          return 'hello';
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '8px',
      horizontalAlign: 'center',
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}`;
        },
      },
    },
  };
  return (
    <div>
      <Chart options={options} series={series} type="bar" height="300" />
    </div>
  );
};
export default BarChart;