import React from "react";
import Input from "../../Common/Input";
import Dropdown from '../../Common/Dropdown';

const AddressModal = (props) =>{

    const {
        setModalOpen, 
        invoiceDetails, 
        handleChange, 
        addressType, 
        setAddressType, 
        gstInNeed,
        country,
    } = props
    return (
        <div className="modalBackground" id='DivIdToPrint'>
            <div className="modalContainer w-40">
                <div className="">
                    <div className="row">
                        <div className="col-md-6 fs-5">
                            <span>Additional Address</span>
                        </div>
                        <div className="col-md-6 titleCloseBtn text-right">
                            <button onClick={() => { setModalOpen(false); setAddressType(''); }}>
                                X
                            </button>
                        </div>                    
                    </div>
                </div>
                <div className="invoice-box">
                    <form>
                        {
                            addressType === 'shipping' ?
                            <div className="row m-2">
                                <div className="">
                                    <Input 
                                        name="consignee"
                                        title="Client Name"
                                        required
                                        type="text" 
                                        placeholder="Enter Client Name"
                                        value={invoiceDetails.consignee}
                                        onChange={handleChange}
                                    />     
                                </div>
                            </div> : ''
                        }
                       <div className="row m-2">
                            <div className="">
                                <Input 
                                    name="Address" 
                                    value={invoiceDetails[addressType+'Address']} 
                                    title="Client Address" 
                                    placeholder="" 
                                    onChange={handleChange}
                                />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="">
                                <Input 
                                    name="AddressLine" 
                                    value={invoiceDetails[addressType+'AddressLine']} 
                                    title="Client Address Line" 
                                    placeholder="" 
                                    onChange={handleChange}
                                />
                            </div>
                       </div>
                       <div className="row m-2">
                            <div className="">
                                <Input 
                                    name="City" 
                                    value={invoiceDetails[addressType+'City']} 
                                    title="City" 
                                    placeholder="City" 
                                    onChange={handleChange}
                                />
                            </div>
                       </div>
                       {
                        country ? 
                        <div className="row m-2">
                            <div className="">
                                <Dropdown
                                    name="Country"
                                    title="Country"
                                    value={invoiceDetails[addressType+'Country']}
                                    placeholder="Select Country"
                                    required
                                    url="/api/Lookup/GetCountries"
                                    onChange={handleChange}
                                    // errorText={validation.stateId}
                                    // onBlur={handleValidation}
                                />
                            </div>
                        </div>
                        :
                        <div className="row m-2">
                            <div className="">
                                <Dropdown
                                    name="State"
                                    title="State"
                                    value={invoiceDetails[addressType+'State']}
                                    placeholder="Select State"
                                    required
                                    url="/api/Lookup/GetStates"
                                    onChange={handleChange}
                                    // errorText={validation.stateId}
                                    // onBlur={handleValidation}
                                />
                            </div>
                        </div>
                       }                       
                       {
                        gstInNeed ?
                            <div className="row m-2">
                                <div className="">
                                    <Input 
                                        name="GSTIN" 
                                        value={invoiceDetails[addressType+'GSTIN']} 
                                        title="GSTIN" 
                                        placeholder="GSTIN" 
                                        onChange={handleChange}
                                    />
                                </div>
                            </div> : ''
                       }
                    </form>
                </div>
                <div className="footer h-50">
                    <button onClick={() => { setModalOpen(false);  setAddressType(''); }}>
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        onClick={() => { setModalOpen(false);  setAddressType(''); }}
                    >Save</button>
                </div>
            </div>
        </div>
    );
}

export default AddressModal;