import React from 'react';
import DatePicker from "react-datepicker";

const Datepicker = (props) => {
    
    const {
        id, name, title, placeholdertext, classname
    } = props;
    
    return (
        <>
            {title !== '' || title === undefined ? 
            <label htmlFor={id}>
            {title}
            </label> : ''}
            <div className="">
            <DatePicker 
                    name={name}
                    title={title}
                    // selected={new Date()} 
                    // onChange={(d) => 
                    //     setInvoice({
                    //         ...invoice, 
                    //         shippedInvoiceDate : new Date(d) 
                    //         })
                    //     }
                    placeholderText={`Enter ${title}`}
                    className='form-control'
                    dateFormat="dd/MM/yyyy" 
                />
        </div>
    </> 
)
};

export default Datepicker;
