import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Context } from '../../../context/InvoiceContext';
import InvoicePreview from './InvoicePreview';
import Loader from '../../Common/Loader';

function InvoicePopUp({ setOpenModal, invoice }) {
  const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
  const [payLoad, setPayLoad] = useState(invoice);
  const [items, ,buttonType , setButtonType] = useContext(Context);
  const [loader, setLoader] = useState(false);

 const generateInvoiceNumber = () => {
  axios
  .get(`/api/Lookup/GenerateInvoiceNumber/${invoice.invoiceType}/${invoice.carrier}`)
  .then((response) => {
      setPayLoad({
        ...payLoad,
        invoiceNumber: response.data,
        invoiceDate: new Date()
      });
  })
  .catch((e) => {
  if (e.name !== 'AbortError') {
      console.log('fail:', e.message)
  }
  });
}; 
 useEffect(() => {
   if(buttonType === 'Save'){
      generateInvoiceNumber();
   }  
}, []);

 const Submit = () => {
  setLoader(true);
   var url = '/api/GenerateInvoice/SaveInvoice';
   if(buttonType === 'Update'){
    url = '/api/GenerateInvoice/UpdateInvoice';
   }
     const method = 'post';
    axios({
      method,
      url: url,
      data: JSON.stringify(payLoad),
      headers: { 'Content-Type': 'application/json' },
    })
    .then((response) => {
        if(response.data === "success"){
            setButtonType('Print');
            setLoader(false);
            notify('success',`Successfully ${buttonType}d`);
        }
        else{
          notify('error', "Something Went Wrong");
          setLoader(false);
        }
    })
    .catch((e) => {
      if (e.name !== 'AbortError') {
          console.log('fail:', e.message)
          setLoader(false);
          notify('error', e.message);
      }
    });
  }
     
  return (
    <>
      { loader ? <Loader />:
        <InvoicePreview setOpenModal={setOpenModal} invoice={payLoad} items={invoice.items} Submit={Submit} />
      }
      <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </>
    );
}

export default InvoicePopUp;
