import React, {useState} from 'react';

export const FieldList = [
    {
        fieldSets: [
            {
                title: 'Invoice Number',
                name: 'invoiceNumber',
                type: 'text'
            },
            {
                title: 'Client Name',
                name: 'clientName',
                type: 'text'
            },
            {
                title: 'Client Address',
                name: 'clientAddress',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'Client Address Line',
                name: 'clientAddressLine',
                type: 'text'
            },
            {
                title: 'Client GST No',
                name: 'clientGSTNo',
                type: 'text'
            },
            {
                title: 'PAN',
                name: 'pan',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'Place of Supply',
                name: 'placeofSupply',
                type: 'text'
            },
            {
                title: 'Invoice Date',
                name: 'invoiceDate',
                type: 'date'
            },
            {
                title: 'Due Date',
                name: 'dueDate',
                type: 'date'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'Invoice Currency',
                name: 'invoiceCurrency',
                type: 'text'
            },
            {
                title: 'Shipping Type',
                name: 'shippingType',
                type: 'text'
            },
            {
                title: 'Job Number',
                name: 'jobNumber',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'Reference No',
                name: 'referenceNo',
                type: 'text'
            },
            {
                title: 'Ack No',
                name: 'ackNo',
                type: 'text'
            },
            {
                title: 'Ack Date',
                name: 'ackDate',
                type: 'date'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'Incoterms',
                name: 'incoterms',
                type: 'text'
            },
            {
                title: 'Shipper',
                name: 'shipper',
                type: 'text'
            },
            {
                title: 'Consignee',
                name: 'consignee',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'Loading Port',
                name: 'loadingPort',
                type: 'text'
            },
            {
                title: 'Destination Port',
                name: 'destinationPort',
                type: 'text'
            },
            {
                title: 'FPOD',
                name: 'fpod',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'Flight No',
                name: 'flightNo',
                type: 'text'
            },
            {
                title: 'Flight Date',
                name: 'flightDate',
                type: 'date'
            },
            {
                title: 'Airline',
                name: 'airline',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'MAWB No',
                name: 'mawbNo',
                type: 'text'
            },
            {
                title: 'MAWB Date',
                name: 'mawbDate',
                type: 'date'
            },
            {
                title: 'HAWB No',
                name: 'hawbNo',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'HAWB Date',
                name: 'hawbDate',
                type: 'text'
            },
            {
                title: 'Cargo type',
                name: 'cargotype',
                type: 'text'
            },
            {
                title: 'Packages',
                name: 'packages',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'Gross Wt.',
                name: 'grossWt',
                type: 'text'
            },
            {
                title: 'Chargeable Wt.',
                name: 'chargeableWt',
                type: 'text'
            },
            {
                title: 'IGM No.',
                name: 'igmNo',
                type: 'text'
            }
        ]
    },
    {
        fieldSets: [
            {
                title: 'IGM Date',
                name: 'igmDate',
                type: 'date'
            },
            {
                title: 'BE No.',
                name: 'beNo',
                type: 'text'
            },
            {
                title: 'BE Date',
                name: 'beDate',
                type: 'date'
            }
        ]
    }
];

export const ItemList = [
    {
        title: 'Description',
        name: 'description',
        type: 'text',
    },
    {
        title: 'SAC',
        name: 'code',
        type: 'text'
    },
    {
        title: 'Currency',
        name: 'currency',
        type: 'text'
    },
    {
        title: 'Rate',
        name: 'rate',
        type: 'text'
    },
    {
        title: 'Qty',
        name: 'qty',
        type: 'text'
    },
    {
        title: 'Amount (Cur)',
        name: 'amtCurr',
        type: 'text'
    },
    {
        title: 'Ex. Rate',
        name: 'exRate',
        type: 'text'
    },
    {
        title: '*T',
        name: 't',
        type: 'text'
    },
    {
        title: 'Taxable (INR)',
        name: 'taxable',
        type: 'label'
    },
    {
        title: 'SGST',
        name: 'sgstRate',
        type: 'select',
    },
    {
        title: 'Amount',
        name: 'sgstAmt',
        type: 'label'
    },
    {
        title: 'CGST',
        name: 'cgstRate',
        type: 'select'
    },
    {
        title: 'Amount',
        name: 'cgstAmt',
        type: 'label'
    },
    {
        title: 'Total',
        name: 'total',
        type: 'label'
    }
]
