import React, { useContext, useEffect, useState } from 'react';
import { FieldList, ItemList } from './FieldList';
import PaymentInvoiceItem from '../Item/PaymentInvoiceItem';
import { Context } from '../../../context/InvoiceContext';
import FormController from './FormController';

const PaymentInvoiceForm = () => {

    const [paymentInvoice, setPaymentInvoice] =  useState([]);
    const [, , , , paymentInvoiceItem, setPaymentInvoiceItem] =  useContext(Context);
    function transFromJsonToClass(data){
        var defaultClassModel = {};
        for( var d in data){
            for( var i in data[d].fieldSets){
                defaultClassModel[data[d].fieldSets[i].name]=''; 
            }           
        }
        return defaultClassModel;
    }

    function transItemListJsonToClass(){
        var defaultClassModel = {};
        for( var item in ItemList){
            defaultClassModel[ItemList[item].name]='';            
        }
        return defaultClassModel;
    }
    
    useEffect(() =>{
       const data =  transFromJsonToClass(FieldList);
       setPaymentInvoice(data);
    },[])

    const handleChange = (e, idx) => {
        const { target } = e;
        const {
            name, type, value, checked,
        } = target; 
        setPaymentInvoice((ps) => {
            return {
                ...ps,
                [name]: value,
            };
        })
    }
	return (
		<>
        { paymentInvoice.length !== 0 && paymentInvoice ?
			(<div className='custom-container'>
				<div className='card-custom card-custom-bgi mt-5'>
					<div className=''>
						<div className='d-flex align-items-center'>
							<div className='symbol symbol-circle me-4'>
								<div className='symbol-label bg-transparent text-primary'>
									<svg stroke="#0c98fc" fill="#0c98fc" strokeWidth="0" viewBox="0 0 448 512" height="1.9em" width="1.9em" xmlns="http://www.w3.org/2000/svg"><path d="M448 75.2v361.7c0 24.3-19 43.2-43.2 43.2H43.2C19.3 480 0 461.4 0 436.8V75.2C0 51.1 18.8 32 43.2 32h361.7c24 0 43.1 18.8 43.1 43.2zm-37.3 361.6V75.2c0-3-2.6-5.8-5.8-5.8h-9.3L285.3 144 224 94.1 162.8 144 52.5 69.3h-9.3c-3.2 0-5.8 2.8-5.8 5.8v361.7c0 3 2.6 5.8 5.8 5.8h361.7c3.2.1 5.8-2.7 5.8-5.8zM150.2 186v37H76.7v-37h73.5zm0 74.4v37.3H76.7v-37.3h73.5zm11.1-147.3l54-43.7H96.8l64.5 43.7zm210 72.9v37h-196v-37h196zm0 74.4v37.3h-196v-37.3h196zm-84.6-147.3l64.5-43.7H232.8l53.9 43.7zM371.3 335v37.3h-99.4V335h99.4z"></path></svg>
								</div>
							</div>
							<div className='d-flex flex-column'>
								<h2 className='card-h2'>Generate Invoice</h2>
							</div>
						</div>
					</div>
                    <div className='card-custom-body'>
                        {
                            FieldList.map((item) => {
                                return (
                                    <div className='row'>
                                      {item.fieldSets.map((attr) => {
                                        attr.titleDisplay = false;
                                        attr.handleChange = handleChange;
                                        attr.values= paymentInvoice;
                                        return (<div className='col-md-4 mb-3'>
                                            <FormController attr={attr} title={true} handleChange={handleChange} />
                                        </div>)   
                                    })}
                                </div> 
                                )
                            })} 
                    <div className="row p-10px item-list invoice-box">
                        <PaymentInvoiceItem />
                    </div>
                    <div className="row p-10px mb-3 pt-4">
                <div className="col-md-6">
                    <button 
                        type="button" 
                        className="form-control btn btn-sm btn-primary-custom" 
                        onClick={() => setPaymentInvoiceItem(paymentInvoiceItem.concat([transItemListJsonToClass()]))}
                    >Add Item</button>
                </div>
                <div className="col-md-6">
                    <button 
                    type="button" 
                    className="form-control btn btn-sm btn-primary-custom" 
                    // onClick={handleSubmit}
                    >Invoice Preview</button>
                </div>
            </div>
                    </div>
				</div>
				
			</div>) : ''  }
		</>
	)
}

export default PaymentInvoiceForm 