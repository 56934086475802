import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from "../Common/Input";
import Loader from "../Common/Loader";
import RadioButtons from "../Common/RadioButtons";

import { Context } from '../../context/RateContext';
import { Context as MenuContext } from '../../context/MenuContext';

const AddMargin = () =>{
    const [measurement, setMeasurement] =  useContext(Context);
    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(MenuContext);

    const [percentage, setPercentage] = useState(0);
    const [maxWeight, setMaxWeight] = useState('');
    const [category, setCategory] = useState('Export');
    const [loader, setLoader] = useState(false);

    const [margins, setMargins] = useState(null);

    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};

    function getMargins() {
        axios
            .get('/api/Booking/GetMargins')
            .then((response) => {
                console.log(response.data);
                setMargins(response.data)
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.error(e);
                }
                notify('error', e.message);
            });
    }
    function submit() {
        setLoader(true);
        axios
        .get(`/api/Booking/SaveMargin/${category}/${maxWeight}/${percentage}`)
        .then((response) => {
            if(response.data === "success"){
                setLoader(false);
                notify('success',`Successfully updated`);
                getMargins();
            }
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
                notify('error', e.message);
            }
            setLoader(false);
        });
      }

    useEffect(() =>{
        getMargins();
        setSelectedMenu('rate');
		setSelectedSubMenu('addMargin');
    },[]);

    return (
        <>
        {loader ? <Loader /> : ''}
        <div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
							<div className='d-flex flex-column'>
								<h2 className='card-h2'>Add Margins</h2>
							</div>
						</div>
					</div>
					<div className='card-body'>
                        <div className="row p-10px mb-31">
                            <div className="col-md-4 mt-4">
                                Select Category
                            </div>
                            <div className="col-md-8">
                                <RadioButtons
                                    name="category"
                                    value={category}
                                    inline
                                    options={{ Export: 'EXPORT', Import: 'IMPORT' }}
                                    onChange={(e) => setCategory(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row p-10px mb-31">
                            <div className="col-md-4 mt-4">Max Weight (enter "above" for last margin percentage)</div>
                                <div className="col-md-4">
                                    <Input 
                                        name="maxWeight"
                                        required
                                        type="text" 
                                        placeholder="Enter Max Weight"
                                        value={maxWeight}
                                        onChange={(e) => setMaxWeight(e.target.value)} 
                                    />
                                </div>
                        </div>
                        <div className="row p-10px mb-31">
                            <div className="col-md-4 mt-4">Margin Percentage</div>
                            <div className="col-md-4">
                                <Input 
                                    name="fuelSurcharge"
                                    required
                                    type="number" 
                                    placeholder="Enter Margin Percentage"
                                    value={percentage}
                                    onChange={(e) => setPercentage(parseFloat(e.target.value))} 
                                />
                            </div>
                        </div>
                        <div className="row p-10px mb-3 pt-4">
                            <div className="col-md-12">
                                <div className="t-a-c">
                                    <button 
                                        type="button" 
                                        className="form-control btn btn-sm btn-primary-custom wt-100" 
                                        onClick={submit}
                                        >Save
                                    </button>
                                </div>
                                
                            </div>
                        </div>
					</div>
                    <div className="dimension-card">
                        <table class="table table-bordered table-striped w-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="bg-warning">Category</th>
                                    <th scope="col" className="bg-warning">Max Weight</th>
                                    <th scope="col" className="bg-warning">Percentage(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    margins !== null ?
                                        margins?.filter((e) => e.category == category)?.map((item) => {
                                        return (<tr>
                                                    <td>{item.category}</td>
                                                    <td>{item.maxWeight}</td>
                                                    <td>{item.percentage}</td>
                                                </tr>)
                                        }) 
                                        : ''
                                }
                            </tbody>
                        </table>
                    </div>
				</div>
				
			</div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    )
}
export default AddMargin;
