import React, { useState } from 'react';
import Submenu from './Submenu';
import { SidebarData } from '../Sidebar/MenuLists';


const Sidebar = () => {
	const [navbarOpen, setNavbarOpen] = useState(true);
	
	return (
		<>
			
			<div className={`sidebar sidebar-fixed sidebar-theme ${navbarOpen ? 'show-sidebar' : 'close-sidebar'}`}>
				<div className='sidebar-nav'>
					<div className='scrollbar-nav'>
						{SidebarData.map((item, index) => {
							return <Submenu item={item} key={index} />;
						})}						
					</div>
				</div>
				<button type='button' className='sidebar-toggle-menu' onClick={() => setNavbarOpen((prev) => !prev)}>
				{navbarOpen ? <>
							<svg stroke="#7e8299" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M328 112L184 256l144 144"></path></svg>
						</> : 
						<>
							<svg stroke="#7e8299" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
						</>}
				</button>
				
			</div>
			
		</>
	)
}

export default Sidebar