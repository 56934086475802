import React, { useState } from 'react';
import nextId from 'react-id-generator';

const Select = (props) => {
  const [id] = useState(nextId());
  const {
    name,
    title,
    value,
    placeholder,
    options,
    onChange,
    hideLabel,
    tabIndex,
    errorText,
    required,
    onBlur,
    disabled,
  } = props;

  let className = 'form-control';
  if (errorText && errorText.length > 0) {
    className += ' is-invalid';
  }

  const markup = Object.keys(options || {}).map((option) => {
    const label = options[option];
    return (
      <option key={option} value={option} label={label}>
        {label}
      </option>
    );
  });
  const labelClassName = hideLabel === true ? 'sr-only' : '';

  const opts = {
    className,
    id,
    name,
    value: value || '',
  };

  if (required === true) {
    opts.required = true;
  }

  if (disabled) {
    opts.disabled = disabled;
  }

  if (onChange && onChange instanceof Function) {
    opts.onChange = onChange;
  }

  if (onBlur && onBlur instanceof Function) {
    opts.onBlur = onBlur;
  }

  if (tabIndex) {
    opts.tabIndex = tabIndex;
  }

  return (
    <div className="form-group">
      {title ? (
        <label htmlFor={opts.id} className={labelClassName}>
          {title}
        </label>
      ) : (
        ''
      )}
      <select {...opts}>
        <option value="">{placeholder}</option>
        {markup}
      </select>
      {errorText && <div className="invalid-feedback">{errorText}</div>}
    </div>
  );
};
export default Select;
