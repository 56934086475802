import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from "axios";
import AddressPage from "../components/BookParcel/AddressPage"; 
import Invoice from "../components/BookParcel/Invoice";
import ProductDetail from "../components/BookParcel/ProductDetail";
import Loader from "../components/Common/Loader";
import Input from "../components/Common/Input";

import { Context } from '../context/RateContext';
import { navigate } from "@reach/router";

const Booking = ({company, id}) => {
    // let { company, id } = useParams();

    const [bookingDetail, setBookingDetail] = useState({});
    const [preview, setPreview] = useState(false);
    const [loader, setLoader] = useState(false);
    const [action, setAction] = useState('Save');

    const [validation, setValidation] = useState({});

    const [measurement, setMeasurement] =  useContext(Context);


    // function getRates(weight) {
    //     const { packageType, country } = measurement;
    //     setLoader(true);
    //     axios
    //       .get(`/api/Booking/GetRates/${packageType}/${country}/${weight}`)
    //       .then((response) => {
    //         setMeasurement({
    //             ...measurement,
    //             rateCollections: response.data,
	// 			weight: weight
    //         })
    //         // setDimension(true)
    //       })
    //       .catch((e) => {
    //         if (e.name !== 'AbortError') {
    //           console.error(e);
    //         }
    //         setMeasurement({
    //             ...measurement,
    //             rateCollections: []
    //         })
    //         setDimension(false)
    //       })
    //       .finally(() => {
    //         setLoader(false);
    //       });
    // }

    useEffect(() =>{
        checkUpadteOrSave();
        // weightReCalculate();
    },[])

    const reload = () => {
        if (id !== undefined) {
            setLoader(true);
          axios
            .get(`/api/Booking/GetBooking/${id}`)
            .then((response) => {
                setBookingDetail(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            })
            .finally(() =>{
                setLoader(false);
            });
        } 
    };

    const checkUpadteOrSave = () =>{
        if (id !== undefined){
            reload();
            setAction('Update');
        }
        else if (company !== undefined){
            if(measurement.weight === 0 && measurement.weight !== undefined)
                {
                    navigate('/');
                }
                else {
                    const charge = measurement?.charges.filter((i) => i.company === company);
                    setBookingDetail((val) => {
                        return {
                            ...val,
                            packageType: measurement.packageType,
                            category: measurement.category,
                            shipperAddress: { country: measurement.category == 'Export' ? "INDIA" : measurement.country.toUpperCase() },
                            receiverAddress: { country: measurement.category == 'Export' ? measurement.country.toUpperCase() : "INDIA" },
                            courierCompany: company,
                            weight: parseFloat(measurement.weight),
                            pkgCount: parseInt(measurement.pkgCount),
                            baseRate: parseFloat(charge[0].baseRate),
                            fuelSurcharge: parseFloat(charge[0].fuelSurcharge),
                            gst: parseFloat(charge[0].GST),
                            dimensions: measurement.dimensions,
                        }
                    });
                }
        }
    }
    const handleChange = (e, val) => {
        const { target } = e;
        const {
            name, value
        } = target; 
        if(val === ''){
            setBookingDetail((ps) => {
                return {
                    ...ps,
                    [name]: value,
                };
            })
        }
        else {
            setBookingDetail((ps) => {
                return {
                    ...ps,
                    [val+'Address']: { ...ps[val+'Address'], [name]: value },
                };
            })
            if (val === 'shipper' && (name === 'contactNo' || name === 'emailId')) {
                setValidation({
                    ...validation,
                    [name]: value ? '' : 'Required *',
                  });
            }
        }
    }
      
    const validate =()=>{
        const validation = {
            contactNo: bookingDetail?.shipperAddress?.contactNo ? '' : "Please Enter shipper contact",
            emailId: bookingDetail?.shipperAddress?.emailId ? '' : "Please Enter shipper EmailId",
        };
        let validErrorExist = false;
    
        Object.keys(validation).forEach((o) => {
          if (validation[o]) {
            validErrorExist = true;
          }
        });
    
        setValidation(validation);
        if(!validErrorExist){
            setPreview(true)
        }
    }  
    return (
		<>
			{loader ? <Loader /> : ''}
            {
                preview ? <Invoice 
                            bookingDetail={bookingDetail} 
                            openPopup={() => setPreview(false)} 
                            action={action}
                            setAction={setAction}
                        /> : ''
            }
            <div className="hero-wrap bg-img project">
                <div className="overlay"></div>
                <div className="container pt-80 booking">
                    <div className='custom-container rate'>				
                        <div className="card mt-5">
                            <div className='card-header bg-orange'>
                                <div className='card-title m-0'>
                                    <div className='d-flex flex-column'>
                                        <h2 className='card-h2'>Book Your Parcel</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                {
                                    action === 'Update' ?
                                    <>
                                    <section className="lh-0">
                                        <h4>Company Booking Link with <strong>{bookingDetail?.courierCompany}</strong></h4>
                                        <div className="row p-10px">
                                            <div className="col-md-4 mt-4">Company Booking Id</div>
                                            <div className="col-md-8">
                                                <Input 
                                                    name="actualBookingId"
                                                    required
                                                    type="text" 
                                                    placeholder="Enter Company Booking Id"
                                                    value={bookingDetail.actualBookingId}
                                                    onChange={(e) => handleChange(e, '')}
                                                />
                                            </div>
                                        </div>
                                    </section>
                                    <hr className="border-2" />
                                    </> : ''
                                }
                                <div className="row p-10px">
                                    <div className="col-md-4 mt-4"><strong>Category: </strong></div>
                                    <div className="col-md-2 mt-auto">
                                        <span className="">{bookingDetail.category}</span>
                                    </div>
                                    <div className="col-md-4 mt-4"><strong>Parcel Type: </strong></div>
                                    <div className="col-md-2 mt-auto">
                                        <span className="">{bookingDetail.packageType}</span>
                                    </div>
                                </div>
                                <hr className="border-2" />
                                <AddressPage 
                                    title="Shipping Address" 
                                    addressType="shipper" 
                                    address={bookingDetail.shipperAddress}
                                    validation={validation}
                                    handleChange={(e) => handleChange(e, 'shipper')}
                                    handleValidation={(e) => handleValidation(e)} 
                                />
                                <hr className="border-2" />
                                <AddressPage 
                                    title="Receiver Address"
                                    addressType="receiver"  
                                    address={bookingDetail.receiverAddress}
                                    handleChange={(e) => handleChange(e, 'receiver')} 
                                />
                                <hr className="border-2" />
                                <ProductDetail  
                                    bookingDetail={bookingDetail}
                                    handleChange={(e) => handleChange(e, '')}
                                />
                            
                                <div className="row p-10px mb-3 pt-4">
                                    <div className="col-md-12">
                                        <div className="t-a-c">
                                            <button 
                                                type="button" 
                                                className="form-control btn btn-sm btn-primary-custom wt-100" 
                                                onClick={validate}
                                                >
                                                    {action == 'Update' ? 'Update' : 'Book'}
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</>
	)
}
export default Booking;