import React, { useState, useContext, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaPrint, FaMinusCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Common/Loader";
import ConfirmPopup from "../Common/ConfirmPopup";
import Invoice from "./Invoice";

import { Context } from '../../context/MenuContext';

import { dateFormatter } from '../CommonJsFunc/CommonJsFunc';

const BookingList = () => {

    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};   
    const [loader, setLoader] = useState(false);
    const [bookingData, setBookingData] = useState([]);
    const [bookingDetail, setBookingDetail] = useState({});
    const [preview, setPreview] = useState(false);
    const [confirmModalPopup, setConfirmModalPopup] = useState(false);
    const [deletingBooking, setDeletingBooking] = useState('');

    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(Context);

    const getBookings = () => {
        setLoader(true);
        axios
        .get('/api/Booking/GetBookings')
        .then((response) => {
            const result = response.data;
            setBookingData(result);
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
            }
            notify('error', e.message);
        })
        .finally(() => {
            setLoader(false);
          });
    } 

    useEffect(() => {
        getBookings();
        setSelectedMenu('booking');
		setSelectedSubMenu('bookings');
    }, []);

    const handlePreview =(bookingId)=>{
        const filterData = bookingData.filter((e) => e.bookingId === bookingId)[0];
        setBookingDetail(filterData);
        setPreview(true);        
    } 

    const removeInvoice = (bookingId) => {
        if(bookingId !== ''){
          setDeletingBooking(bookingId);
          setConfirmModalPopup(true);
        }
    };

    const handleRemove = () => {
        setLoader(true);
        setConfirmModalPopup(false);
        
        axios.get(`/api/Booking/DeleteBooking/${deletingBooking}`)
            .then((response) => {
              if(response.data === "success"){
                notify('success', 'Successfully Booking Removed');
                getBookings();
              }
              else{
                  notify('error', "Something Went Wrong");
              }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                }
            })
            .finally(() => {
                setLoader(false);
            });
    }


    return (
        <>
            {confirmModalPopup && 
                <ConfirmPopup 
                    value={deletingBooking} 
                    setConfirmModalPopup={setConfirmModalPopup} 
                    handleRemove={handleRemove} 
                    />
            }
            {
                preview ? <Invoice 
                                bookingDetail={bookingDetail} 
                                openPopup={() => setPreview(false)} 
                                action='Print'
                            /> : ''
            }
          {
            loader ? <Loader/>
            :
            (<div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
							<div className='d-flex flex-column'>
								<h2 className='card-h2'>All Bookings</h2>
							</div>
						</div>
					</div>
					<div className='card-body'>
                        <div className="row p-10px item-list respnsive-custom-table">
                            <table className='table'>
                                <thead className='sticky-header'>
                                    <tr className="heading">
                                        <th className='bg-body-secondary'>Sl.NO.</th>
                                        <th className='bg-body-secondary'>Booking ID</th>
                                        <th className='bg-body-secondary'>Category</th>
                                        <th className='bg-body-secondary'>Boking Data</th>
                                        <th className='bg-body-secondary'>Weight</th>
                                        <th className='bg-body-secondary'>To</th>
                                        <th className='bg-body-secondary'>From</th>
                                        <th className='bg-body-secondary'>Detail</th>
                                        <th className='bg-body-secondary'>Update</th>
                                        <th className='bg-body-secondary'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { bookingData?.map((item, idx) => (
                                        <tr className="p-10px item" key={idx}>
                                            <td>{idx + 1}</td>
                                            <td><strong>{item.bookingId}</strong></td>
                                            <td><strong>{item.category}</strong></td>
                                            <td><span>{dateFormatter(item.bookingDate)}</span></td>
                                            <td><span>{item.weight} Kgs</span></td>
                                            <td><span>{item?.receiverAddress.country}</span></td>
                                            <td><span>{item?.shipperAddress.country}</span></td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-link m-0 p-0 text-edit"
                                                    title="Edit"
                                                    onClick={(_e) => handlePreview(item.bookingId)}
                                                >
                                                <FaPrint />
                                                </button>
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/booking/undefined/${item.bookingId}`}
                                                    >
                                                    <FaEdit />
                                                </Link>                                              
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-link m-0 p-0 color-r"
                                                    title=""
                                                    onClick={(_e) => removeInvoice(item.bookingId)}
                                                >
                                                    <FaMinusCircle />
                                                </button>
                                            </td>
                                    </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
					</div>
				</div>
				
			</div> )
        }
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </>
    );
}
export default BookingList;