import React, { useState } from 'react';

export const Context = React.createContext();
const defaultItems = () => {
  return {
    itemId: 0,
    itemName: '',
    code: '',
    rate: 0,
    gst: 0,
    gstAmount: 0,
    total: 0,
    invoiceNumber: ''
  }
}
export const InvoiceContext = ({ children }) => {
  const [itemCount, setItemCount] = useState(1);
  const [items, setItems] = useState([defaultItems()]);
  const [buttonType, setButtonType] = useState('save');

  const [paymentInvoiceItem, setPaymentInvoiceItem] =  useState([]);

  return (
    <Context.Provider value={[items, setItems, buttonType, setButtonType, paymentInvoiceItem, setPaymentInvoiceItem]}>
      {children}
    </Context.Provider>
  );
};
