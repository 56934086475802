import React, { useState, useContext } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';



const UserLogin = () => {
	const [show, setShow] = useState(false);

	const { user, logout } = useContext(AuthContext);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<>
			<div className='user-icon' onClick={handleShow}>
				<svg stroke="#898989" fill="none" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="1.8em" width="1.8em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"></path></svg>
			</div>

			<Offcanvas show={show} onHide={handleClose} placement="end" className='notification-offcanvas'>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Account Details</Offcanvas.Title>
				</Offcanvas.Header>
				<hr className='m-0' />
				<Offcanvas.Body className='px-0'>
					<div className='account-details'>
						<div className='account-flex'>
							<Link to='/admin/home' className='account-list'>
								<div className='account-media'>
									<span>
										<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-10 0V7a5 5 0 0 1 5-5z"></path></g></svg>
									</span>
									<div className="d-flex flex-column flex-nowrap ml-1_5">
										<div className="account-list-title text-dark">Personal Details</div>
										<div className="account-list-label">Name, Email, Phone Number</div>
									</div>
								</div>
								<div className='account-links'>
									<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
								</div>
							</Link>
							<Link to='/admin/changePassword' className='account-list' onClick={handleClose}>
								<div className='account-media'>
									<span>
										<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-10 0V7a5 5 0 0 1 5-5z"></path></g></svg>
									</span>
									<div className="d-flex flex-column flex-nowrap ml-1_5">
										<div className="account-list-title text-dark">Change Password</div>
										<div className="account-list-label">Change Your Password</div>
									</div>
								</div>
								<div className='account-links'>
									<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
								</div>
							</Link>
							<Link to='/admin/home' className='account-list'>
								<div className='account-media'>
									<span>
										<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-10 0V7a5 5 0 0 1 5-5z"></path></g></svg>
									</span>
									<div className="d-flex flex-column flex-nowrap ml-1_5">
										<div className="account-list-title text-dark">My Account</div>
										<div className="account-list-label">Invoices, Bills, Estimates</div>
									</div>
								</div>
								<div className='account-links'>
									<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
								</div>
							</Link>
						</div>
						<div className="mar-spacer bg-theme"></div>
						<div className='account-flex'>
							<Link to='/admin/home' className='account-list'>
								<div className='account-media'>
									<span>
										<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.8508 14.1684C18.8504 15.308 18.4498 16.4134 17.7155 17.3014C16.9811 18.1895 15.9569 18.807 14.8124 19.0517L14.2658 17.4567C14.7664 17.3766 15.2425 17.1894 15.6597 16.9087C16.0769 16.6279 16.4249 16.2607 16.6787 15.8334H14.5665C14.112 15.8334 13.6762 15.6578 13.3548 15.3453C13.0334 15.0327 12.8528 14.6088 12.8528 14.1667V10.8334C12.8528 10.3914 13.0334 9.96746 13.3548 9.6549C13.6762 9.34234 14.112 9.16675 14.5665 9.16675H17.0839C16.8748 7.55583 16.0685 6.07448 14.8162 5.00064C13.564 3.92681 11.9518 3.33431 10.2823 3.33431C8.61279 3.33431 7.00065 3.92681 5.74838 5.00064C4.49611 6.07448 3.68978 7.55583 3.48068 9.16675H5.99809C6.45259 9.16675 6.88847 9.34234 7.20985 9.6549C7.53123 9.96746 7.71178 10.3914 7.71178 10.8334V14.1667C7.71178 14.6088 7.53123 15.0327 7.20985 15.3453C6.88847 15.6578 6.45259 15.8334 5.99809 15.8334H3.42756C2.97306 15.8334 2.53717 15.6578 2.21579 15.3453C1.89442 15.0327 1.71387 14.6088 1.71387 14.1667V10.0001C1.71387 5.39758 5.54996 1.66675 10.2823 1.66675C15.0147 1.66675 18.8508 5.39758 18.8508 10.0001V14.1684Z" fill="#7e8299"></path></svg>
									</span>
									<div className="d-flex flex-column flex-nowrap ml-1_5">
										<div className="account-list-title text-dark">Help and Support</div>
										<div className="account-list-label">Manage coupons for additional discounts</div>
									</div>
								</div>
								<div className='account-links'>
									<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.6em" width="1.6em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M184 112l144 144-144 144"></path></svg>
								</div>
							</Link>
						</div>
					</div>
				</Offcanvas.Body>
				<div className='p-3 d-flex w-100'>
					<button type='button' className='form-control btn btn-logout w-100' onClick={logout}>Logout</button>
				</div>
			</Offcanvas>
		</>
	)
}

export default UserLogin