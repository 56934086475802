import React, {useState} from 'react';
import Input from '../../Common/Input';
import Datepicker from '../../Common/Datepicker';



const FormController = (props) => {
  const {attr, title, handleChange} = props;
    const inputField =() =>{
    switch (attr.type) {
      case 'text':
        return (
            <Input 
                name={attr.name}
                title={title ? attr.title : ''}
                required
                type="text" 
                placeholder={`Enter ${attr.title}`}   
                value={attr.values[attr.name]}
                onChange={handleChange}
            />
        );
      case 'date':
        return (
             <Datepicker
                name={attr.name}
                title={title ? attr.title : ''}
                selected={new Date()} 
                placeholderText={`Enter ${attr.title}`}
                className='form-control'
                dateFormat="dd/MM/yyyy"   
             />
        );
      case 'success':
        return (
            <Input 
                name={attr.name}
                title={attr.title}
                required
                type="text" 
                placeholder={`Enter ${attr.title}`}   
                value={attr.id}
                // onChange={(e) => handleChange(e)}
                // onBlur={handleValidation}
                // errorText={validation.containerNo}
            />
        );
      default:
        return null;
    }
  }

    return (
      <>
      {inputField()}
      </>
    )
  }


  export default FormController;