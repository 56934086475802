import React from "react";

const ServicePage =()=>{

    return (
        <>
        <div>
            <div class="hero-wrap js-fullheight height bg-img">
                <div class="overlay"></div>
                <div class="container">
                    <div class="row no-gutters slider-text js-fullheight align-items-center" data-scrollax-parent="true">
                        <div class="col-md-12 ftco-animate mt-5 pt-md-5" data-scrollax=" properties: { translateY: '70%' }">
                            <div class="row">
                                <div class="">
                                    <p class="fs-5 mission pt-5" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">Our valued clients include large scale manufacturing industries, Medical equipment firms,educational institutes and readymade garment industries.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ftco-animate" data-scrollax=" properties: { translateY: '70%' }">
                            <div class="row">
                                <div class="">
                                    <p class="fs-5 mission" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">Commodities include raw materials, chemicals, tools, machinery, CAPEX equipment, consumables etc.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        </>
        
    )
}

export default ServicePage;