import React from 'react';
import delivery from '../../images/f_delivery.png';
import profit from '../../images/profit.png';
import document from '../../images/document.png';
import box from '../../images/box.png';

const Facilities = React.forwardRef((props, ref) =>{
    return (
        <>
        <section class="ftco-section services-section mt-6" ref={ref}  id="Services">
            <div class="container">
            <div class="heading-section text-center pt-80">
                <h2>Our Services</h2>
                    <br />
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-6 col-lg-3 ftco-animate">
                    <div class="project">
                        <div class="items">
                            <img src={delivery}  alt="TGL Template" className="img-fluid" />
                        </div>
                        <div class="text text-center ml-50">
                            <h3><span>Import Consignment</span></h3>
                        </div>
                </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-3 ftco-animate">
                    <div class="project">
                        <div class="items">
                            <img src={delivery} alt="TGL Template" className="img-fluid" />
                        </div>
                        <div class="text text-center ml-50">
                            <h3><span>Export  Consignment</span></h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-lg-3 ftco-animate">
                    <div class="project">
                        <div class="items">
                            <img src={box} alt="TGL Template" className="img-fluid" />
                        </div>
                        <div class="text text-center ml-50">
                            <h3><span>Courier Services</span></h3>
                        </div>
                    </div>
                </div>
            <div class="col-md-6 col-sm-6 col-lg-3 ftco-animate">
                <div class="project">
                    <div class="items">
                        <img src={document} className="img-fluid" alt="TGL Template" />
                    </div>
                    <div class="text text-center ml-50">
                        <h3><span>Customs clearance</span></h3>
                    </div>
                </div>
            </div>
            </div>
            </div>
            </section>
        </>
    )
})

export default Facilities;