import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CsvDownloader from 'react-csv-downloader';
import DatePicker from "react-datepicker";
import Loader from '../../Common/Loader'
import UpdateStatement from './UpdateStatement';
import { FYFormat } from '../../CommonJsFunc/CommonJsFunc';

import { Context } from '../../../context/MenuContext';


const InvoiceStatement = () =>{
    const classList = [{key:'PENDING',value:'bg-danger'},{key:'RECEIVED',value:'bg-success'}];
    
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};

    const [loader, setLoader] = useState(false);
    const [invoiceStatement, setInvoiceStatement] = useState([]);
    const [invoiceStatementData, setInvoiceStatementData] = useState([]);
    const [updateInvoices, setUpdateInvoices] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [month, setMonth] = useState('');
    const [monthlyCost, setMonthlyCost] = useState({});

    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(Context);

    const dateFormatter = (date) => {
        const yyyy = new Date(date).getFullYear();
        let mm = new Date(date).getMonth() + 1; // Months start at 0!
        let dd = new Date(date).getDate();
    
        if (dd < 10) dd = `0${dd}`;
        if (mm < 10) mm = `0${mm}`;
    
        return `${dd}.${mm}.${yyyy}`;
    };
    const getInvoicReports = () => {
        axios
        .get('/api/Lookup/GetInvoiceReports')
        .then((response) => {
            setInvoiceStatement(response.data);
            setInvoiceStatementData(response.data);
            setMonth('');
            setLoader(false);
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                console.log('fail:', e.message)
            }
            setLoader(false);
        });
    } 
    useEffect(() => {
        setLoader(true);
        getInvoicReports();
        setSelectedMenu('sales');
		setSelectedSubMenu('invoiceStatement');
    }, []);
    
    useEffect(() => {
        if(invoiceStatementData.length > 0){
            const data = invoiceStatementData.map((item) => {
                            return {
                                ...item,
                                totalAmount: parseFloat(item.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                            }
                        })  
            setInvoiceStatement(data);            
        }       
      }, [invoiceStatementData]); 


      useEffect(() => {
        if(month !== '' && month !== null){
            const monthlyStatement = invoiceStatementData.filter((x) => 
                                        new Date(x.invoiceDate).getMonth() === month.getMonth() 
                                        && new Date(x.invoiceDate).getFullYear() === month.getFullYear())
                                        .map((item) => {
                                            return {
                                                ...item,
                                                totalAmount: parseFloat(item.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                                            }
                                        }) ;
              setMonthlyCost({
                monthlyInvoiceAmount: parseFloat(monthlyStatement?.map((item) =>  parseFloat(item.totalAmount)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
            })
              setInvoiceStatement(monthlyStatement);
        }
        else{
            setInvoiceStatement(invoiceStatementData);
        }        
      }, [month]); 
      
    const handleUpdateStatement = () => {
        setLoader(true);
        const method = 'post';
        axios({
          method,
          url: `/api/GenerateInvoice/UpdateStatement`,
          data: JSON.stringify(updateInvoices),
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            setInvoiceStatement(response.data);
            setModalOpen(false);
            getInvoicReports();  
            notify('success','Successfully Updated');
        })
        .catch((e) => {
          if (e.name !== 'AbortError') {
              console.log('fail:', e.message)
              setLoader(false);
              setModalOpen(false);
              notify('error', e.message);
          }
        });
    } 
    const handleChange = (e) => {
        const { target } = e;
        const { name, value} = target;
        setUpdateInvoices({
                    ...updateInvoices,
                    [name] : name === 'balance' ? parseFloat(value) : value
                  });
        // if(name === 'remarks'){
        //     setUpdateInvoices({
        //         ...updateInvoices,
        //         remarks: value,
        //         balance: '',
        //       });
        // } else if (name === 'balance'){
        //     setUpdateInvoices({
        //         ...updateInvoices,
        //         remarks: '',
        //         balance: value,
        //       });
        // }
       
    };
    const handleEdit = (invoiceNumber) => {
        const data = invoiceStatement.filter((a)=>a.invoiceNumber === invoiceNumber);
        setModalOpen(true); 
        setUpdateInvoices(data[0]);        
      };
    
    const yearFormat =()=> {
        const selectedMonth = new Date(month);
        if(month === '' || month === null) {
            // return new Date().getFullYear() +"-"+(parseInt(new Date().getFullYear()) + 1).toString().substring(2,4);        
            return FYFormat();
        }
        else{
            // return selectedMonth?.getFullYear() +"-"+(parseInt(selectedMonth?.getFullYear()) + 1).toString().substring(2,4);        
            return FYFormat(selectedMonth);
        }
    }

    const monthFormat =()=> {
        if(month === '' || month === null) {
            return 'Year';                    
        }
        else{
            return month?.toLocaleString('default', { month: 'long' });        
        }
    }

    const rowColor = (item) => {
        if(item.remarks !== ''){
            return classList.filter((e) => e.key === item.remarks)[0]?.value;
        }
        // else if (item.balance !== ''){
        //     return classList.filter((e) => e.key === item.balance)[0]?.value;
        // }
    }
    const CSVData = () => {
        const csvData = invoiceStatement.map((x) => (
            {
                InvoiceNo: x.invoiceNumber,
                ClientName: x.clientName,
                TotalAmount: parseFloat(x.items?.map((item) =>  parseFloat(item.total)).reduce((prev, curr) => prev + curr, 0)).toFixed(2),
                InvoiceDate: dateFormatter(x.invoiceDate),
                Remarks: x.remarks,
                Balance: x.balance,
                PaymentDate: x.paymentDate ? dateFormatter(x.paymentDate) : '-',
            }
          ));

          if(month !== '' && month !== null) {
            csvData.push(
                {
                    InvoiceNo: `Total ${month?.toLocaleString('default', { month: 'long' })} month Amount`,
                    TotalAmount: monthlyCost.monthlyInvoiceAmount,
                  }
            )  
          }
        return csvData;
      };  
    return (
        <>
         {(!loader && modalOpen) && <UpdateStatement 
                            setModalOpen={setModalOpen} 
                            updateInvoices={updateInvoices}
                            handleUpdateStatement={handleUpdateStatement} 
                            handleChange={handleChange}
                            setUpdateInvoices={setUpdateInvoices}
                        />}
              {loader ? <Loader /> :
              (
            <div className='custom-container'>				
				<div className='card mt-5'>
					<div className='card-header'>
						<div className='card-title m-0'>
                            <div className='d-flex flex-column'>
                                <h2 className='card-h2'>Invoices Statement </h2>
                            </div>
						</div>
					</div>
                    <div className='card-body'>
                        <div className='row pb-3'>
                            <div className='col-md-4 fs-5 fw-medium pt-2'>
                                {`Statement - ${monthFormat()} ${yearFormat()}`}
                            </div>
                            <div className='col-md-4'>
                                <DatePicker 
                                    name="month"
                                    title='Select Month for Filter'
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    selected={month} 
                                    onChange={(d) => setMonth(d) }
                                    placeholderText='Please Select Monthly Statement'
                                    className='form-control'
                                />
                            </div>
                            <div className='col-md-4 text-right'>
                                    <button type="button" className="btn btn-sm btn-primary">
                                        <CsvDownloader
                                            className="ml-3"
                                            filename={`InvoiceStatement_${monthFormat()}_${yearFormat()}`}
                                            extension=".csv"
                                            datas={CSVData}
                                            >
                                            <span className="svg-icon svg-light">
                                            <FontAwesomeIcon icon={faDownload} />
                                            </span>
                                            {' '}
                                            Download CSV
                                        </CsvDownloader>
                                    </button>
                            </div>
                        </div>                           
                        <div className="row p-10px item-list respnsive-custom-table">
                            <table className='table'>
                                <thead className='sticky-header'>
                                    <tr className="heading">
                                        <th className='bg-body-secondary'>S.NO.</th>
                                        <th className='bg-body-secondary'>Client Name</th>
                                        <th className='bg-body-secondary'>Invoice Number</th>
                                        <th className='bg-body-secondary'>Date</th>
                                        <th className='bg-body-secondary'>Inv. Amount</th>
                                        <th className='bg-body-secondary'>Payment Date</th>
                                        <th className='bg-body-secondary'>Remarks</th>
                                        <th className='bg-body-secondary'>Balance</th>
                                        <th className='bg-body-secondary'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { invoiceStatement?.map((item, idx) => (
                                        <tr className="p-10px item" key={idx}>
                                            <td className={rowColor(item)}>{idx + 1}</td>
                                            <td className={rowColor(item)}><strong>{item.clientName}</strong></td>
                                            <td className={rowColor(item)}><strong>{item.invoiceNumber}</strong></td>
                                            <td className={rowColor(item)}><span>{dateFormatter(item.invoiceDate)}</span></td>
                                            <td className={rowColor(item)}><span>{item.totalAmount}</span></td>
                                            <td className={rowColor(item)}><span>{item.paymentDate ? dateFormatter(item.paymentDate) : '-'}</span></td>
                                            <td className={rowColor(item)}><span>{item.remarks}</span></td>
                                            <td className={rowColor(item)}><span>{item.balance}</span></td>
                                            <td className={rowColor(item)}>
                                                <button type="button" className="btn btn-link text-dark" onClick={()=> handleEdit(item.invoiceNumber)}>
                                                    <FontAwesomeIcon icon={faEdit} size="sm" />
                                                </button>
                                            </td>
                                    </tr>
                                    ))
                                }
                                </tbody>
                                <tfoot>
                                {
                                    month !== '' && month !== null ?
                                    <>
                                        <tr className='sticky-footer'>
                                            <td colSpan="4" className='bg-body-secondary t-a-c'>
                                                <strong>
                                                    {`Total ${month?.toLocaleString('default', { month: 'long' })} Amount`}
                                                </strong>
                                            </td>
                                            <td className='bg-body-secondary'><strong>{monthlyCost.monthlyInvoiceAmount}</strong></td>
                                            <td className='bg-body-secondary'></td>
                                            <td className='bg-body-secondary'></td>
                                            <td className='bg-body-secondary'></td>
                                        </tr>
                                    </> : ''
                                }
                                </tfoot>
                            </table>
                        </div>
					</div>
				</div>
				
			</div>)}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
    </>
    );
}

export default InvoiceStatement;