import React, { useState } from 'react';

export const Context = React.createContext();

export const RateContext = ({ children }) => {
  
    const defaultMeasure = () => {
        return {
            country: '',
            packageType: 'Package',
            category: 'Export',
            weight: 0,
            length: 0,
            width: 0,
            height: 0,
            rateCollections: [],
            pkgCount: 1,
            charges: [],
            dimensions: [], 
        }
    }
  const [measurement, setMeasurement] = useState(defaultMeasure());

  return (
    <Context.Provider value={[measurement, setMeasurement]}>
      {children}
    </Context.Provider>
  );
};
