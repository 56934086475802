import React, { useState, useEffect } from "react";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiTypeAhead from '../../Common/ApiTypeAhead';
import Input from "../../Common/Input";
import Loader from '../../Common/Loader';
import AddressModal from "../AddressModal";
import PaymentInvoiceItem from '../Item/PaymentInvoiceItem';
import PreviewModal from "./PreviewModal";

const NoteForm = () =>{
    let { id } = useParams();
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    const itemList = [
        {
            title: 'Item Details',
            name: 'itemDetail',
            type: 'text'
        },
        {
            title: 'Quantity',
            name: 'quantity',
            type: 'number'
        },
        {
            title: 'Rate',
            name: 'rate',
            type: 'number'
        },
        {
            title: 'Total Amount',
            name: 'totalAmount',
            type: 'label'
        },
        {
            title: 'Delete',
            name: 'delete',
            type: 'deleteButton'
        },
    ];
    const [itemColumns, setItemColumns] = useState(itemList);
    const [loader, setLoader] = useState(false);
    const [dnNumber, setDNNumber] = useState(id);
    const [debitNote, setDebitNote] = useState('');
    const [items, setItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [addressType, setAddressType] = useState('');
    const [action, setAction] = useState('Save');
    const [states, setStates] = useState(null);
    const [countryNeed, setCountryNeed] = useState(false);
    const [countries, setCountries] = useState(null);


    function transFromJsonToClass(){
        var defaultClassModel = {};
        for( var item in itemList){
            defaultClassModel[itemList[item].name]='';            
        }
        return defaultClassModel;
    }

    const reload = () => {
        if (dnNumber !== 'new') {
            setLoader(true);
            setAction('Update');
          axios
            .get(`/api/Lookup/GetDebitNote/${dnNumber}`)
            .then((response) => {
                setDebitNote(response.data);
                setItems(response?.data?.debitNoteItems)
                setLoader(false);
                getStates();
                getCountries();
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
                setLoader(false);
            });
        } 
      };
      
      const getCountries = () =>{
        axios
            .get('/api/Lookup/GetCountries')
            .then((response) => {
                setCountries(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }

      const getStates = () =>{
        axios
            .get('/api/Lookup/GetStates')
            .then((response) => {
                setStates(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }
      useEffect(() => {
        if(id !== 'new'){
            reload();
        }
        const jsonData =  transFromJsonToClass(itemList);
        setItems([jsonData]);
      }, []);

      useEffect(() => {
        if(states != null){
            setDebitNote({
                ...debitNote,
                billingStateName: states[debitNote.billingState],
                shippingStateName: states[debitNote.shippingState],
            });
        }
     }, [states]);

     useEffect(() => {
        if(countries != null){
            setDebitNote({
                ...debitNote,
                shippingCountryName: countries[debitNote.shippingCountry],
            });
            setCountryNeed(true);
        }
     }, [countries]);

      useEffect(() => {
        if(id === 'new' && dnNumber !== ''){
            reload();
        }
      }, [dnNumber]);

    const handleChange = (e) => {
        const { target } = e;
        const {
            name, value
        } = target; 

        if(addressType === 'billing' || addressType === 'shipping' )
        {
            if(name === 'State' || name === 'Country'){
                const label = target.options[value].label;
                setDebitNote((ps) => {
                    return {
                        ...ps,
                        [addressType+name]: parseValue(name, value),
                        [addressType+name+'Name']: label,
                    };
                })
            }
            else if(name === 'consignee'){
                setDebitNote((ps) => {
                    return {
                        ...ps,
                        [name]: value,
                    };
                })
            }
            else{
                setDebitNote((ps) => {
                    return {
                        ...ps,
                        [addressType+name]: parseValue(name, value),
                    };
                })
            }
        }else {
             setDebitNote((ps) => {
                return {
                    ...ps,
                    [name]: value,
                };
            })
        }
       

        if (name === "clientName") {
            setDebitNote({
                ...debitNote,
                [name]: value,
              });
        }
    }

    const parseValue = (name, value) => {
        switch (name) {
            case 'rate':
                return parseFloat(value === '' ? 0 : value);
            case 'quantity':
                return parseInt(value === '' ? 0 : value);
            default:
                return value;
        }
    }

    const handleItemChange = (e, idx) => {
        const { target } = e;
        const {
            name, type, value,
        } = target; 
        const old = items[idx];
        const updated = { ...old, [name]: parseValue(name, value) }
        const clone = [...items];
        clone[idx] = updated;
        setItems(clone);
    }

    const calculateAmount = (e, idx) => {
        if(items[idx].itemDetail !== '' || (items[idx].rate !== '' && items[idx].quantity !== '')) {
           
            const total = items[idx].rate * items[idx].quantity;
            const old = items[idx];
            const updated = { 
                ...old, 
                totalAmount: parseFloat(total.toFixed(2)),
            }
            const clone = [...items];
            clone[idx] = updated;
            const emptyItem = items.filter((e)=>e.itemDetail === '').length;
            if(e.target.name === 'itemDetail' && emptyItem === 0){    

                setItems(clone.concat(transFromJsonToClass(itemList)));
            }
            else{
                setItems(clone);
            }
        }
    }

    const handleValidation = (e) => {
        const { target } = e;
        const {
          name, required, value,
        } = target;

        if (name === "clientName") {
            setDebitNote({
                ...debitNote,
                [name]: value,
              });
        }

        if(name === 'dnNumber'){
            setDNNumber(value);
        }
    }

    const handleSubmit = () => {
        setLoader(true);
        setPreviewModal(false);
        var url = '/api/GenerateInvoice/SaveAndUpdateDebitNote';
        const method = 'post';
        const payLoad = { 
            ...debitNote, 
            debitNoteItems: items.filter((a) => a.itemDetail !== ''), 
            action: action,
            billingState: parseInt(debitNote.billingState),
            // shippingState: parseInt(debitNote.shippingState)
            shippingCountry: parseInt(debitNote.shippingCountry)
        }
        axios({
        method,
        url: url,
        data: JSON.stringify(payLoad),
        headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if(response.data.length > 0){
                setLoader(false);
                notify('success',`Successfully ${action}d`);
            }
        })
        .catch((e) => {
        if (e.name !== 'AbortError') {
            console.log('fail:', e.message)
            setLoader(false);
            notify('error', e.message);
        }
        });
      }
      
    const handlePreview =()=>{
        setDebitNote({
            ...debitNote,
            debitNoteItems: items.filter((a) => a.itemDetail !== ''),
          });
        setPreviewModal(true);
    }  

    const removeItem = (id) => {
        
        axios
            .get(`/api/Lookup/DeleteItem/${id}/DebitNoteItem`)
            .then((response) => {
                if(response.data === "success"){
                    notify('success', 'Successfully Removed');
                }
                else{
                    notify('error', "Something Went Wrong");
                }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                }
            });
    }
    const handleRemove = (itemId, idx) => {
        const data = items.filter((a)=> a.itemId !== itemId );
        setItems(data)
        if(itemId !== 0){
            removeItem(itemId);
        }
      };

    return (
        <>
        {loader ? <Loader /> : ''}
        {modalOpen ? <AddressModal 
                        setModalOpen={setModalOpen} 
                        invoiceDetails={debitNote} 
                        handleChange={handleChange} 
                        addressType={addressType} 
                        setAddressType={setAddressType} 
                        country={countryNeed} 
                    /> : ''}
        {previewModal ? <PreviewModal 
                            invoiceDetails={debitNote}
                            setInvoiceDetails={setDebitNote}
                            setPreviewModal={setPreviewModal}
                            action={action}
                            handleSubmit={handleSubmit}
                        /> : ''}
       <div className='custom-container'>				
            <div className='card mt-5'>
                <div className='card-header'>
                    <div className='card-title m-0'>
                        <div className='d-flex flex-column'>
                            <h2 className='card-h2'>{id === 'new' ? 'New' : 'Update'} Debit Note</h2>
                        </div>
                    </div>
                </div>
				<div className='card-custom-body'>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4">Client Name</div>
                        <div className="col-md-8">
                            {
                                debitNote !== '' || id === 'new' ? 
                                <ApiTypeAhead
                                    name="clientName"
                                    title=''
                                    defaultId={debitNote.clientName}
                                    displayKey="clientName"
                                    query={debitNote.clientName}
                                    placeholder="Start typing to search"
                                    url="/api/Lookup/GetClientName"
                                    onChange={handleChange}
                                    onBlur={handleValidation}
                                    required
                                />
                                 : <div>Loading....</div>
                            }
                        </div>
                    </div>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4"></div>
                        <div className='col-md-3' >
                            <span className="cursor-p m-1 text-primary" onClick={() => {setModalOpen(true); setAddressType('billing'); setCountryNeed(false);}}>
                                Billing Address
                            </span>
                        </div> 
                        <div className='col-md-3' >
                            <span className="cursor-p m-1 text-primary" onClick={() => {setModalOpen(true); setAddressType('shipping'); setCountryNeed(true);}}>
                                Shipping Address
                            </span>                            
                        </div>   
                    </div>
                    {
                        debitNote.billingAddress && (
                            <div className="row p-10px mt-4">
                                <div className="col-md-3 mt-4"></div>
                                <div className="col-md-3">
                                    <span>{debitNote.billingAddress}</span><br />
                                    <span>{debitNote.billingAddressLine}</span><br />
                                    <span>{debitNote.billingCity}</span><br />
                                    <span>{debitNote.billingStateName}</span><br />
                                </div>
                                <div className="col-md-3">
                                    <span>{debitNote.shippingAddress}</span><br />
                                    <span>{debitNote.shippingAddressLine}</span><br />
                                    <span>{debitNote.shippingCity}</span><br />
                                    <span>{countryNeed ? debitNote.shippingCountryName : debitNote.shippingStateName}</span><br />
                                </div>
                            </div>     
                        )
                    }
                    {/* <div className="row p-10px mt-4">
                        <div className="col-md-3 mt-4">Consignee</div>
                        <div className="col-md-8">
                                <Input 
                                    name="consignee"
                                    title=""
                                    required
                                    type="text" 
                                    placeholder="Enter Consignee"
                                    value={debitNote.consignee}
                                    onChange={handleChange}
                                />                      
                        </div>
                    </div> */}
                    <div className="row p-10px mt-4">
                        <div className="col-md-3 mt-4">Country of Origin</div>
                        <div className="col-md-4">
                            <Input 
                                name="countryOfOrigin"
                                title=""
                                required
                                type="text" 
                                placeholder="Country of Origin"
                                value={debitNote.countryOfOrigin}
                                onChange={handleChange}
                                // onBlur={handleValidation}
                            />
                        </div>
                        <div className="col-md-2 mt-10">Port of Discharge</div>
                        <div className="col-md-2">
                            <Input 
                                name="portOfDischarge"
                                title=""
                                required
                                type="text" 
                                placeholder="Port of Discharge"
                                value={debitNote.portOfDischarge}
                                onChange={handleChange}
                                // onBlur={handleValidation}
                            />
                        </div>
                    </div>
                    <div className="row p-10px">
                        <div className="col-md-3 mt-4">Airway Bill/BL No.</div>
                        <div className="col-md-4">
                            <Input 
                                name="airwayBillNo"
                                title=""
                                required
                                type="text" 
                                placeholder="Airway Bill / BL No."
                                value={debitNote.airwayBillNo}
                                onChange={handleChange}
                                // onBlur={handleValidation}
                            />
                        </div>
                        <div className="col-md-2 mt-10">BOE NO</div>
                        <div className="col-md-2">
                            <Input 
                                name="boeNo"
                                title=""
                                required
                                type="text" 
                                placeholder="BOE NO"
                                value={debitNote.boeNo}
                                onChange={handleChange}
                                // onBlur={handleValidation}
                            />
                        </div>
                    </div>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4">Subject</div>
                        <div className="col-md-4">
                                <Input 
                                    name="subject"
                                    title=""
                                    required
                                    type="text" 
                                    placeholder={`Reason For this Freight Invoice`}
                                    value={debitNote.subject}
                                    onChange={handleChange}
                                />                      
                        </div>
                        <div className="col-md-2 mt-10">BOE DT.</div>
                        <div className="col-md-2">
                                <DatePicker 
                                    name='boeDate'
                                    title=''
                                    selected={debitNote.boeDate ? new Date(debitNote.boeDate) : new Date()}  
                                    onChange={(d) => 
                                        setDebitNote({
                                            ...debitNote, 
                                            boeDate : new Date(d).toLocaleDateString() 
                                            })
                                        }
                                    placeholderText='BOE Date'
                                    className='form-control'
                                    dateFormat="dd/MM/yyyy" 
                                />
                        </div>
                    </div>
                    <div className="row p-10px item-list invoice-box mt-4">
                            <PaymentInvoiceItem 
                                data={itemColumns} 
                                onChange={handleItemChange} 
                                items={items}
                                onAddItem={(e) => setItems(items.concat(transFromJsonToClass(itemList)))}
                                onBlur={calculateAmount}
                                handleRemove={handleRemove}        
                            />
                        </div>
                        <div className="invalid-feedback"></div>
                        <div className="row p-10px mb-3 pt-4">
                            <div className="col-md-2">
                                <button 
                                    type="button" 
                                    className="form-control btn btn-sm btn-primary-custom" 
                                    onClick={handlePreview}
                                >
                                Save</button>
                            </div>
                            <div className="col-md-2">
                                <Link to={'/admin/debitNote'} className='btn btn-sm btn-primary-custom'>
                                    Cancel
                                </Link>
                            </div>
                        </div>
                        </div>
                    </div> 
			</div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>

    );
}

export default NoteForm;