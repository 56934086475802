import React, { useState, useEffect } from "react";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiTypeAhead from '../../Common/ApiTypeAhead';
import Input from "../../Common/Input";
import Loader from '../../Common/Loader';
import AddressModal from "../AddressModal";
import PaymentInvoiceItem from '../Item/PaymentInvoiceItem';
import CreditPreviewModal from "./CreditPreviewModal";

const Form = () =>{
    const notify = (res, msg) => {res === 'success' ? toast.success(msg) : toast.error(msg)};
    const itemList = [
        {
            title: 'Item Name',
            name: 'itemName',
            type: 'text'
        },
        {
            title: 'HSN Code',
            name: 'code',
            type: 'text'
        },
        {
            title: 'Rate',
            name: 'rate',
            type: 'number'
        },
        {
            title: 'GST',
            name: 'gst',
            type: 'select'
        },
        {
            title: 'GST Amount',
            name: 'gstAmount',
            type: 'label'
        },
        {
            title: 'Total',
            name: 'total',
            type: 'label'
        },
        {
            title: 'Delete',
            name: 'delete',
            type: 'deleteButton'
        },
    ];

    let { id } = useParams();

    const [itemColumns, setItemColumns] = useState(itemList);
    const [loader, setLoader] = useState(false);
    const [cnNumber, setCNNumber] = useState(id);
    const [creditNote, setCreditNote] = useState('');
    const [items, setItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [addressType, setAddressType] = useState('');
    const [countryNeed, setCountryNeed] = useState(false);
    const [action, setAction] = useState('Save');
    const [states, setStates] = useState(null);
    const [countries, setCountries] = useState(null);


    function transFromJsonToClass(){
        var defaultClassModel = {};
        for( var item in itemList){
            defaultClassModel[itemList[item].name]='';            
        }
        return defaultClassModel;
    }

    const getStates = () =>{
        axios
            .get('/api/Lookup/GetStates')
            .then((response) => {
                setStates(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }

    const getCountries = () =>{
        axios
            .get('/api/Lookup/GetCountries')
            .then((response) => {
                setCountries(response.data);
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
            });
    }

    const reload = () => {
        if (cnNumber !== 'new') {
            setLoader(true);
            setAction('Update');
          axios
            .get(`/api/Lookup/GetCreditNote/${cnNumber}`)
            .then((response) => {
                setCreditNote(response.data);
                setItems(response?.data?.creditNoteItems)
                setLoader(false);
                getStates();
                getCountries();
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                }
                setLoader(false);
            });
        } 
      };

      useEffect(() => {
        if(id !== 'new'){
            reload();
        }
        const jsonData =  transFromJsonToClass(itemList);
        setItems([jsonData]);
      }, []);

      useEffect(() => {
        if(states != null){
            setCreditNote({
                ...creditNote,
                billingStateName: states[creditNote.billingState],
                shippingStateName: states[creditNote.shippingState],
            });
        }
     }, [states]);

     useEffect(() => {
        if(countries != null){
            setCreditNote({
                ...creditNote,
                shippingCountryName: countries[creditNote.shippingCountry],
            });
            setCountryNeed(true);
        }
     }, [countries]);

      useEffect(() => {
        if(id === 'new' && cnNumber !== ''){
            reload();
        }
      }, [cnNumber]);

    const handleChange = (e) => {
        const { target } = e;
        const {
            name, value
        } = target; 
        
        if(addressType === 'billing' || addressType === 'shipping' )
        {
            if(name === 'State' || name === 'Country'){
                const label = target.options[value].label;
                setCreditNote((ps) => {
                    return {
                        ...ps,
                        [addressType+name]: parseValue(name, value),
                        [addressType+name+'Name']: label,
                    };
                })
            }
            else if(name === 'consignee'){
                setCreditNote((ps) => {
                    return {
                        ...ps,
                        [name]: value,
                    };
                })
            }
            else{
                setCreditNote((ps) => {
                    return {
                        ...ps,
                        [addressType+name]: parseValue(name, value),
                    };
                })
            }
           
        }else {
             setCreditNote((ps) => {
                return {
                    ...ps,
                    [name]: value,
                };
            })
        }
       

        if (name === "clientName") {
            setCreditNote({
                ...creditNote,
                [name]: value,
              });
        }
    }

    const parseValue = (name, value) => {
        switch (name) {
            case 'rate':
            case 'gstAmount':
            case 'total':                
                return parseFloat(value === '' ? 0 : value);
            case 'gst':
                return parseInt(value === '' ? 0 : value);
            default:
                return value;
        }
    }

    const handleItemChange = (e, idx) => {
        const { target } = e;
        const {
            name, type, value,
        } = target; 
        const old = items[idx];
        const updated = { ...old, [name]: parseValue(name, value) }
        const clone = [...items];
        clone[idx] = updated;
        setItems(clone);
    }

    const onTaxChange = (e, idx) => {
        const taxAmount = ((parseFloat(items[idx].rate)/100) * parseInt(e.target.value));
        const old = items[idx];
        const updated = { 
            ...old, 
            gst: parseInt(e.target.value), 
            gstAmount: parseFloat(taxAmount.toFixed(2)),
            total: parseFloat((parseFloat(items[idx].rate) + parseFloat(taxAmount)).toFixed(2) )
        }
        const clone = [...items];
        clone[idx] = updated;
        setItems(clone);
    }

    const calculateAmount = (e, idx) => {
        if(items[idx].itemName !== '' || (items[idx].rate !== '' && items[idx].gst !== '')) {
           
            const totalAmount = ((items[idx].rate / 100) * items[idx].gst) + items[idx].rate;
            const old = items[idx];
            const updated = { 
                ...old, 
                gstAmount: parseFloat(((items[idx].rate / 100) * items[idx].gst).toFixed(2)),
                total: parseFloat(totalAmount).toFixed(2),
            }
            const clone = [...items];
            clone[idx] = updated;
            const emptyItem = items.filter((e)=>e.itemName === '').length;
            if(e.target.name === 'itemName' && emptyItem === 0){    

                setItems(clone.concat(transFromJsonToClass(itemList)));
            }
            else{
                setItems(clone);
            }
        }
    }

    const handleValidation = (e) => {
        const { target } = e;
        const {
          name, required, value,
        } = target;

        if (name === "clientName") {
            setCreditNote({
                ...creditNote,
                [name]: value,
              });
        }

        if(name === 'cnNumber'){
            setCNNumber(value);
        }
    }

    const handleSubmit = () => {
        setLoader(true);
        setPreviewModal(false);
        var url = '/api/GenerateInvoice/SaveAndUpdatecreditNote';
        const method = 'post';
        const payLoad = { 
            ...creditNote, 
            creditNoteItems: items.filter((a) => a.itemName !== ''), 
            action: action,
            billingState: parseInt(creditNote.billingState),
            // shippingState: parseInt(creditNote.shippingState),
            shippingCountry: parseInt(creditNote.shippingCountry)
        }
        axios({
        method,
        url: url,
        data: JSON.stringify(payLoad),
        headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
            if(response.data.length > 0){
                setLoader(false);
                notify('success',`Successfully ${action}d`);
            }
        })
        .catch((e) => {
        if (e.name !== 'AbortError') {
            console.log('fail:', e.message)
            setLoader(false);
            notify('error', e.message);
        }
        });
      }
      
    const handlePreview =()=>{
        setCreditNote({
            ...creditNote,
            creditNoteItems: items.filter((a) => a.itemName !== ''),
            issueDate: new Date().toLocaleDateString(),
          });
        setPreviewModal(true);
    }  

    const removeItem = (id) => {
        
        axios
            .get(`/api/Lookup/DeleteItem/${id}/CreditNoteItem`)
            .then((response) => {
                if(response.data === "success"){
                    notify('success', 'Successfully Removed');
                }
                else{
                    notify('error', "Something Went Wrong");
                }
            })
            .catch((e) => {
                if (e.name !== 'AbortError') {
                    console.log('fail:', e.message)
                    notify('error', e.message);
                }
            });
    }
    const handleRemove = (itemId, idx) => {
        const data = items.filter((a)=> a.itemId !== itemId );
        setItems(data)
        if(itemId !== 0){
            removeItem(itemId);
        }
      };

    return (
        <>
        {loader ? <Loader /> : ''}
        {modalOpen ? <AddressModal 
                        setModalOpen={setModalOpen} 
                        invoiceDetails={creditNote} 
                        handleChange={handleChange} 
                        addressType={addressType} 
                        setAddressType={setAddressType}
                        gstInNeed={false}
                        country={countryNeed} 
                    /> : ''}
        {previewModal ? <CreditPreviewModal 
                            invoiceDetails={creditNote}
                            setInvoiceDetails={setCreditNote}
                            setPreviewModal={setPreviewModal}
                            action={action}
                            handleSubmit={handleSubmit}
                        /> : ''}
       <div className='custom-container'>				
            <div className='card mt-5'>
                <div className='card-header'>
                    <div className='card-title m-0'>
                        <div className='d-flex flex-column'>
                            <h2 className='card-h2'>{id === 'new' ? 'New' : 'Update'} Credit Note</h2>
                        </div>
                    </div>
                </div>
				<div className='card-custom-body'>
                    <div className="row p-10px">
                        <div className="col-md-3 mt-4">Invoice Number</div>
                        <div className="col-md-4">
                            <Input 
                                name="invoiceNumber"
                                title=""
                                required
                                type="text" 
                                placeholder="Enter Invoice Number"
                                value={creditNote.invoiceNumber}
                                onChange={handleChange}
                                onBlur={handleValidation}
                            />
                        </div>
                        <div className="col-md-2 mt-10">Invoice Date</div>
                        <div className="col-md-2">
                                <DatePicker 
                                    name='invoiceDate'
                                    title=''
                                    selected={creditNote.invoiceDate ? new Date(creditNote.invoiceDate) : new Date()}  
                                    onChange={(d) => 
                                        setCreditNote({
                                            ...creditNote, 
                                            invoiceDate : new Date(d).toLocaleDateString() 
                                            })
                                        }
                                    placeholderText='Pay Date'
                                    className='form-control' 
                                    dateFormat="dd/MM/yyyy"
                                />
                        </div>
                    </div>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4">Client Name</div>
                        <div className="col-md-8">
                            {
                                creditNote !== '' || id === 'new' ? 
                                <ApiTypeAhead
                                    name="clientName"
                                    title=''
                                    defaultId={creditNote.clientName}
                                    displayKey="clientName"
                                    query={creditNote.clientName}
                                    placeholder="Start typing to search"
                                    url="/api/Lookup/GetClientName"
                                    onChange={handleChange}
                                    handleValidation
                                    onBlur={handleValidation}
                                    required
                                />
                                 : <div>Loading....</div>
                            }
                        </div>
                    </div>
                    <div className="row p-10px mb-31">
                        <div className="col-md-3 mt-4"></div>
                        <div className='col-md-3' >
                            <span className="cursor-p m-1 text-primary" onClick={() => {setModalOpen(true); setAddressType('billing'); setCountryNeed(false);}}>
                                Billing Address
                            </span>
                        </div> 
                        <div className='col-md-3' >
                            <span className="cursor-p m-1 text-primary" onClick={() => {setModalOpen(true); setAddressType('shipping'); setCountryNeed(true);}}>
                                Shipping Address
                            </span>                            
                        </div>   
                    </div>
                    {
                        creditNote.billingAddress && (
                            <div className="row p-10px mt-4">
                                <div className="col-md-3 mt-4"></div>
                                <div className="col-md-3">
                                    <span>{creditNote.billingAddress}</span><br />
                                    <span>{creditNote.billingAddressLine}</span><br />
                                    <span>{creditNote.billingCity}</span><br />
                                    <span>{creditNote.billingStateName}</span><br />
                                </div>
                                <div className="col-md-3">
                                    <span>{creditNote.shippingAddress}</span><br />
                                    <span>{creditNote.shippingAddressLine}</span><br />
                                    <span>{creditNote.shippingCity}</span><br />
                                    <span>{countryNeed ? creditNote.shippingCountryName : creditNote.shippingStateName}</span><br />
                                </div>
                            </div>     
                        )
                    }
                    <div className="row p-10px mb-31 mt-4">
                        <div className="col-md-3 mt-4">Subject</div>
                        <div className="col-md-8">
                                <Input 
                                    name="subject"
                                    title=""
                                    required
                                    type="text" 
                                    placeholder={`Reason For this Credit Note`}
                                    value={creditNote.subject}
                                    onChange={handleChange}
                                />                      
                        </div>
                    </div>
                    <div className="row p-10px item-list invoice-box mt-4">
                            <PaymentInvoiceItem 
                                data={itemColumns} 
                                onChange={handleItemChange} 
                                items={items}
                                onAddItem={(e) => setItems(items.concat(transFromJsonToClass(itemList)))}
                                onBlur={calculateAmount}
                                onTaxChange={onTaxChange}
                                handleRemove={handleRemove}    
                            />
                        </div>
                        <div className="invalid-feedback"></div>
                        <div className="row p-10px mb-3 pt-4">
                            <div className="col-md-2">
                                <button 
                                    type="button" 
                                    className="form-control btn btn-sm btn-primary-custom" 
                                    onClick={handlePreview}
                                >
                                Save</button>
                            </div>
                            <div className="col-md-2">
                                <Link to={'/admin/creditNote'} className='btn btn-sm btn-primary-custom'>
                                    Cancel
                                </Link>
                            </div>
                        </div>
                        </div>
                    </div> 
			</div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>

    );
}

export default Form;