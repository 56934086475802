import React, { useState, useContext, useEffect } from "react";
import Dropdown from '../Common/Dropdown';
import RadioButtons from "../Common/RadioButtons";
import Input from "../Common/Input";

import { Context } from '../../context/RateContext';
import { Context as MenuContext } from '../../context/MenuContext';

const Weight = (props) =>{
    const { apiCall } = props;
    const [weight, setWeight] =  useState(0);
    const [measurement, setMeasurement] =  useContext(Context);
    const [, setSelectedMenu, ,setSelectedSubMenu] = useContext(MenuContext);

    const handleChange = (e) => {
        const { target } = e;
        const {
            name, value, type
        } = target; 
        setMeasurement((v) => {
            return {
                ...v,
                [name]: value
              };
        });
        if(name === 'weight'){
            setWeight(e.target.value);
        }
    }
    useEffect(() =>{
        setSelectedMenu('rate');
		setSelectedSubMenu('getRate');
    },[]);
    return (
        <>
        <div className="row p-10px">
            <div className="col-md-4 mt-20">Select Category</div>
            <div className="col-md-8">
                <RadioButtons
                    name="category"
                    value={measurement.category}
                    inline
                    options={{ Export: 'EXPORT', Import: 'IMPORT' }}
                    onChange={handleChange}
                    required
                />
            </div>
        </div>
        <div className="row p-10px mb-3">
            <div className="col-md-4 mt-2">Select Package Type</div>
            <div className="col-md-8">
                <RadioButtons
                    name="packageType"
                    value={measurement.packageType}
                    inline
                    options={{ Document: 'Document', Package: 'Package' }}
                    onChange={handleChange}
                    required
                />
            </div>
        </div>
        <div className="row p-10px mb-31">
            <div className="col-md-4 mt-2">Select Country</div>
            <div className="col-md-4">
                <Dropdown
                    name="country"
                    value={measurement.country}
                    placeholder="Select Country"
                    required
                    url="/api/Booking/GetRateCountries"
                    onChange={handleChange}
                    />
            </div>
        </div>
        <div className="row p-10px mb-31">
            <div className="col-md-4 mt-4">Approx Weight</div>
            <div className="col-md-4">
                <Input 
                    name="weight"
                    required
                    type="text" 
                    placeholder="Approx Weight in .Kgs"
                    value={weight}
                    onChange={handleChange}
                />
            </div>
        </div>
        <div className="row p-10px mb-3 pt-4">
            <div className="col-md-12">
                <div className="t-a-c">
                    <button 
                        type="button" 
                        className="form-control btn btn-sm btn-primary-custom wt-100" 
                        onClick={() => apiCall(measurement.weight)}
                        >Calculate
                    </button>
                </div>
                
            </div>
        </div>
        </>
    )
}
export default Weight;
