import Header from "../components/Home/Header";
import Menu from "../components/Home/Menu";
import Footer from "../components/Home/Footer";
import Rate from "./Rate";

const RatePage = () => {

    return (
        <>
       <Header />
        <Menu />
        <Rate />
        <Footer />
        </>
    );
}

export default RatePage;