import React from "react";

const Charges = (props) =>{
    const { data } = props;
    return(
        <>
        <div className="dimension-card">
            <div className='card-header'>
                <div className='card-title m-0'>
                    <div className='d-flex flex-column'>
                        <h2 className='card-h2'>{data.company}</h2>
                    </div>
                </div>
            </div>
            {
                data.baseRate > 0 ?
                (
                <table class="table table-bordered table-striped w-300">
                    <thead>
                        <tr>
                            <th scope="col" className="bg-warning">Courier Rate</th>
                            <th scope="col" className="bg-warning">Fuel Surcharge</th>
                            <th scope="col" className="bg-warning">GST 18%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{data.baseRate}</td>
                            <td>{data.fuelSurcharge}</td>
                            <td>{data.GST}</td>
                        </tr>
                        <tr>
                            <td><strong>Net Amount</strong></td>
                            <td colSpan="2">{data.baseRate + data.fuelSurcharge + data.GST}</td>
                        </tr>
                    </tbody>
                </table>
                ) :
                <div className='card-title m-0'>
                    <div className='d-flex flex-column'>
                        <h2 className='card-h2'>Data Not Found</h2>
                    </div>
                </div>
            }
            
            <hr className="border-2 w-300" />
        </div>
        </>
    )
}

export default Charges;